import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import Tippy from '@tippyjs/react';

import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { Author } from '@/interfaces/author';
import { Post } from '@/interfaces/post';

import AuthorsTooltipMenu from '../AuthorsTooltipMenu';

import AuthorsActionsMenu from './AuthorsActionsMenu';

interface Props {
  post: Post;
  users: Author[];
  guestAuthors: Author[];
  updatePost: (data: Post) => void;
  setShowCreateGuestAuthorModal: (value: boolean) => void;
}

const AuthorsMenu = ({ users, guestAuthors, post, updatePost, setShowCreateGuestAuthorModal }: Props) => {
  const [showActionMenus, setShowActionMenus] = useState(false);
  const [tippyMenusShown, setTippyMenusShown] = useState(false);
  const currentAuthors = users?.filter((user) => post.user_ids.includes(user.id));
  const currentGuestAuthors = guestAuthors?.filter((guestUser) => post.guest_author_ids.includes(guestUser.id));

  const onMouseEnter = () => {
    setShowActionMenus(true);
  };

  const onMouseLeave = () => {
    if (!tippyMenusShown) {
      setShowActionMenus(false);
    }
  };

  const onTippyShow = () => {
    setTippyMenusShown(true);
  };

  const onTippyHide = () => {
    setTippyMenusShown(false);
  };

  return (
    <div className="relative" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Transition
        show={showActionMenus}
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute -ml-10 z-50">
          <Tippy
            offset={[0, 8]}
            placement="bottom-start"
            popperOptions={{
              modifiers: [{ name: 'flip' }],
            }}
            trigger="click"
            interactive
            onShow={onTippyShow}
            onHide={onTippyHide}
            content={
              <div className="absolute z-50">
                <AuthorsActionsMenu
                  guestAuthors={guestAuthors}
                  users={users}
                  post={post}
                  updatePost={updatePost}
                  setShowCreateGuestAuthorModal={setShowCreateGuestAuthorModal}
                />
              </div>
            }
          >
            <div>
              <Button $leftSlot={<Icon name="DragMenu" />} $variant="tertiary" $size="small" $isIconButton />
            </div>
          </Tippy>
        </div>
      </Transition>
      <div className="relative cursor-pointer">
        <Tippy
          offset={[0, 8]}
          placement="top-start"
          popperOptions={{
            modifiers: [{ name: 'flip' }],
          }}
          onShow={onTippyShow}
          onHide={onTippyHide}
          trigger="click"
          interactive
          content={
            <div className="absolute z-50 mt-[-3rem]">
              <AuthorsTooltipMenu
                post={post}
                updatePost={updatePost}
                users={users}
                guestAuthors={guestAuthors}
                setShowCreateGuestAuthorModal={setShowCreateGuestAuthorModal}
              />
            </div>
          }
        >
          <ul className="flex w-full">
            {currentAuthors &&
              currentAuthors.length > 0 &&
              currentAuthors?.map((author) => (
                <li key={author.id}>
                  <div className="flex items-center justify-center overflow-hidden text-ellipsis border border-1 px-6 py-1 rounded-2xl text-sm font-semibold mr-2 text-violet-900 border-violet-300 bg-violet-100">
                    {author.first_name}
                  </div>
                </li>
              ))}
            {currentGuestAuthors &&
              currentGuestAuthors.length > 0 &&
              currentGuestAuthors?.map((guestAuthor) => (
                <li key={guestAuthor.id}>
                  <div className="flex items-center justify-center overflow-hidden text-ellipsis border border-1 px-6 py-1 rounded-2xl text-sm font-semibold mr-2 text-gray-900 border-gray-300 bg-gray-100">
                    {guestAuthor.name}
                  </div>
                </li>
              ))}
          </ul>
        </Tippy>
      </div>
    </div>
  );
};

export default AuthorsMenu;
