import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useMutation,useQuery } from "react-query";
import { LinkIcon } from "@heroicons/react/24/solid";

import ActionModal from "@/components/ActionModal";
import Card from "@/components/Card";
import { Checkbox, Input, Textarea } from "@/components/Form";
import { Typography } from "@/components/Typography";
import { useCurrentUser } from "@/context/current-user-context";
import { useCopyToClipboard } from "@/hooks";
import { Post } from "@/interfaces/post";
import { IUser } from "@/interfaces/user";
import api from "@/services/swarm";
import { Button } from "@/ui/Button";

import { LoadingSpinner } from "../LoadingSpinner";
import SubscriberSelect from "../SubscriberSelect";

interface Props {
  post: Post;
  onClose: () => void;
}

const PreviewDropdown = ({ post, onClose }: Props) => {
  const [resetLinkModalOpen, setResetLinkModalOpen] = useState(false);
  const [sendTestModalOpen, setSendTestModalOpen] = useState(false);
  const [draftUrl, setDraftUrl] = useState(post.draft_url);
  const copy = useCopyToClipboard();
  const { currentUser } = useCurrentUser();
  const [selectedEmails, setSelectedEmails] = useState([currentUser?.email]);
  const [emailOptions, setEmailOptions] = useState<string[]>([]);
  const [additionalEmails, setAdditionalEmails] = useState('');
  const [currentSubscriberId, setCurrentSubscriberId] = useState<string>();

  const submitTest = useMutation(() => api.post(`/posts/${post.id}/test_send`, {
    test_send: {
      selected_emails: selectedEmails,
      extra_addresses: additionalEmails,
      current_subscriber_id: currentSubscriberId,
    },
  }), {
    onSuccess: () => {
      setSendTestModalOpen(false);
      onClose();
      toast.success('Test email sent');
    },
    onError: (errPayload: any) => {
      toast.error(errPayload.response.data.message || 'There was a problem sending your test');
    },
  });

  const resetDraftLink = useMutation(() => api.patch(`/posts/${post.id}/reset_draft_link`).then((res) => res.data), {
    onSuccess: (data: any) => {
      setResetLinkModalOpen(false);
      setDraftUrl(data.draft_url);
      toast.success('Draft link reset');
    },
    onError: (errPayload: any) => {
      toast.error(errPayload.response.data.message || 'There was a problem resetting the draft link');
    },
  });

  const owners = useQuery<IUser[]>(['post-v2', 'previewDropdown', post.id, 'owners'], () => api.get(`/publications/${post.publication_id}/users`).then((resp) => resp.data), {
    cacheTime: 0,
  });

  useEffect(() => {
    if (owners.data !== undefined) {
      setEmailOptions(owners.data.map((user) => user.email));
    }
  }, [owners.data]);

  if (!currentUser) {
    return null;
  }

  return (
    <Card className='absolute right-0 mt-12 w-sidebar'>
      <ActionModal
        isOpen={resetLinkModalOpen}
        onClose={() => setResetLinkModalOpen(false)}
        headerText="Reset draft link"
        descriptionText="Are you sure you'd like to reset the draft link? People using the old link will no longer have access."
        actionText="Reset"
        onProceed={() => resetDraftLink.mutate()}
        resourceId={post.id}
        isWorking={resetDraftLink.isLoading}
        modalMessageType="info"
      >
        <p />
      </ActionModal>
      <ActionModal
        isOpen={sendTestModalOpen}
        onClose={() => setSendTestModalOpen(false)}
        isWorking={submitTest.isLoading}
        resourceId={post.id}
        onProceed={() => submitTest.mutate()}
        buttonType="primary"
        actionText="Send"
        headerText="Send test email"
        descriptionText=""
      >
        <div className="flex flex-col gap-6 mt-6">
          <div className="flex flex-col gap-2">
            <Typography token="font-medium/text/sm" colorWeight="900">
              Email Address
            </Typography>
            {
              emailOptions.map((email: string) => (
                <Checkbox
                  key={email}
                  labelText={email}
                  name={email}
                  checked={selectedEmails.includes(email)}
                  onChange={(checked) => {
                    if (checked) {
                      setSelectedEmails([...selectedEmails, email]);
                    } else {
                      setSelectedEmails(selectedEmails.filter((e) => e !== email));
                    }
                  }}
                />
              ))
            }
            <Textarea
              name="additional_emails"
              placeholderText="Enter additional emails, separated by commas"
              value={additionalEmails}
              onChange={(e) => setAdditionalEmails(e.target.value)}
              rows={4}
              helperText={
                <Typography token="font-light/text/xs" color="surface" colorWeight="900">
                  You can add up to 10 emails.
                </Typography>
              }
            />
          </div>
          <SubscriberSelect
            labelText="Simulated Subscriber"
            helperText="This only sends the test to the selected email addresses, personalized as if sent to the chosen subscriber. Great for testing personalization or referral programs."
            onSelectSubscriber={(subscriberId: string) => setCurrentSubscriberId(subscriberId)}
            onClearSubscriber={() => setCurrentSubscriberId('')}
          />
        </div>
      </ActionModal>
      {
        owners.isLoading ? <div className="h-full w-full flex justify-center items-center">
          <LoadingSpinner className="mr-3" />
          <p className="text-gray-500">Loading</p>
        </div> : <div className="flex flex-col gap-6">
          <div>
            <Typography token="font-medium/text/sm" colorWeight="900">
              Draft link
            </Typography>
            <Input
              name="draft_url"
              value={draftUrl}
              className='w-full py-2'
              onClick={() => copy({ text: draftUrl })}
              trailingButton={
                <Button
                  Icon={LinkIcon}
                  variant="info"
                  shade="light"
                  className="rounded-l-none border-feedback-info-200 border"
                  onClick={() => copy({ text: draftUrl })}
                >
                  Copy
                </Button>
              }
              readOnly
            />
            <Typography token="font-light/text/xs" color="surface" colorWeight="900">
              Anyone who visits this link will be able to see the latest version of the draft. <span
                role="button"
                tabIndex={0}
                className="cursor-pointer"
                onClick={() => setResetLinkModalOpen(true)}
                onKeyDown={() => setResetLinkModalOpen(true)}
              >
                <Typography
                  token="font-medium/text/xs"
                  color="secondary"
                  colorWeight="600"
                  >
                  Reset link</Typography>
              </span>
            </Typography>
          </div>
          <div className="flex flex-col gap-2">
            <Typography token="font-medium/text/sm" colorWeight="900">
              Send test email
            </Typography>
            {
              emailOptions.map((email: string) => (
                <Checkbox
                  key={email}
                  labelText={email}
                  name={email}
                  checked={selectedEmails.includes(email)}
                  onChange={(checked) => {
                    if (checked) {
                      setSelectedEmails([...selectedEmails, email]);
                    } else {
                      setSelectedEmails(selectedEmails.filter((e) => e !== email));
                    }
                  }}
                />
              ))
            }
            <Textarea
              name="additional_emails"
              placeholderText="Enter additional emails, separated by commas"
              value={additionalEmails}
              onChange={(e) => setAdditionalEmails(e.target.value)}
              rows={4}
              helperText={
                <Typography token="font-light/text/xs" color="surface" colorWeight="900">
                  You can add up to 10 emails. <span
                    role="button"
                    tabIndex={0}
                    className="cursor-pointer"
                    onClick={() => setSendTestModalOpen(true)}
                    onKeyDown={() => setSendTestModalOpen(true)}
                  >
                    <Typography
                      token="font-medium/text/xs"
                      color="secondary"
                      colorWeight="600"
                      >
                      Advanced settings</Typography>
                  </span>
                </Typography>
              }
            />
            <div>
              <Button block={false} onClick={() => submitTest.mutate()} variant="primary-inverse" size="sm" disabled={submitTest.isLoading}>
                Send test email
              </Button>
            </div>
          </div>
        </div>
      }
    </Card>
  )
};
export default PreviewDropdown;
