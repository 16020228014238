import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';

import { SiteVersion } from '@/interfaces/dream_builder/site_version';

import { useCurrentPublicationState } from '../../context/current-publication-context';
import api from '../../services/swarm';

interface Props {
  id: string;
  onSuccess?: () => void;
  publishOnUpdate?: boolean;
}

const useUpdateSiteVersion = ({ id, onSuccess, publishOnUpdate }: Props) => {
  const queryClient = useQueryClient();
  const [currentPublicationId] = useCurrentPublicationState();

  return useMutation(
    (siteVersion: Partial<SiteVersion>) =>
      api.patch(`/site_versions/${id}`, {
        publication_id: currentPublicationId,
        site_version: siteVersion,
        publish_on_update: publishOnUpdate,
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        if (onSuccess) onSuccess();
        queryClient.invalidateQueries(['publications', currentPublicationId, 'sites', 'null']);
      },
    }
  );
};

export default useUpdateSiteVersion;
