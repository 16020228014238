import React, { useState } from 'react';
import toast from 'react-hot-toast';

import { Button } from '@/ui/Button';

import { SimpleSelect } from '../../../../../../components/Form';
import { SkeletonLoader } from '../../../../../../components/SkeletonLoader';
import { useUpdateWebTemplate } from '../../../../../../hooks';
import { Publication } from '../../../../../../interfaces/publication';

interface Props {
  webTemplate: any;
  publications: Publication[];
  isLoadingPublications: boolean;
}

const AddPublication = ({ webTemplate, publications, isLoadingPublications }: Props) => {
  const webTemplateMutation = useUpdateWebTemplate();

  const [selectedPubId, setSelectedPubId] = useState<string | null>(null);

  const handleAddPublicationIds = () => {
    if (selectedPubId) {
      webTemplateMutation.mutate({ multi_pub_ids: [...webTemplate?.multi_pub_ids, selectedPubId] });
      setSelectedPubId(null);
      toast.success('Saved!');
    }
  };

  const setPublicationOptions = () => {
    return publications?.map((publication: Publication) => {
      return {
        label: publication.name,
        value: publication.id,
      };
    });
  };

  return (
    <SkeletonLoader
      isLoading={isLoadingPublications}
      skeletons={
        <div className="flex items-center justify-end">
          <div className="bg-gray-200 h-8 w-full rounded" />
        </div>
      }
      as={React.Fragment}
    >
      <div className="space-y-2">
        <SimpleSelect
          name="multi-pubs"
          labelText="Select a publication"
          value={selectedPubId || ''}
          options={setPublicationOptions()}
          onSelect={(_name: string, value: string) => {
            setSelectedPubId(value);
          }}
        />
        {selectedPubId && (
          <div className="space-x-2">
            <Button variant="primary-inverse" onClick={() => setSelectedPubId(null)}>
              Remove
            </Button>
            <Button onClick={handleAddPublicationIds}>Add</Button>
          </div>
        )}
      </div>
    </SkeletonLoader>
  );
};

export default AddPublication;
