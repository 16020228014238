import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';

import { ButtonProp, CommonProps, HrefProp, ToProp } from '@/interfaces/nav_item';

import NavItem from './NavItem';

type Props = CommonProps & (ToProp | HrefProp | ButtonProp);

const BASE_STYLES = 'flex space-x-1.5 items-center justify-between rounded -mt-0.5 w-full pt-0.5';
const COLOR_MAP: any = Object.freeze({
  secondary: 'bg-action-secondary-50 text-action-secondary-500 hover:bg-action-secondary-50',
  tertiary: 'bg-action-tertiary-50 text-action-tertiary-500, hover:bg-action-tertiary-50',
});

const getVariant = (planType: string, isActive: boolean, notIncludedInTrial: boolean) => {
  if (!isActive) return 'primary';
  if (planType === 'trial' && notIncludedInTrial) return 'secondary';
  if (planType === 'trial') return 'tertiary';
  if (planType === 'upgrade') return 'secondary';
  if (isActive) return 'secondary';
  return 'primary';
};

const NavItemLink = ({
  title,
  Icon,
  to,
  href,
  planType,
  onClick,
  notIncludedInTrial = false,
  additionalIsActiveCheck = false,
  includedInGrow = false,
  badge,
  id,
  classNames,
}: Props) => {
  const { pathname } = useLocation();
  const startsWith = to && pathname.startsWith(to) && to !== '/';
  const isActive = pathname === to || startsWith || additionalIsActiveCheck;

  const variant = useMemo(() => {
    return getVariant(planType || '', isActive, notIncludedInTrial);
  }, [planType, isActive, notIncludedInTrial]);

  if (onClick) {
    return (
      <button
        type="button"
        className={cx(BASE_STYLES, isActive && COLOR_MAP[variant], !isActive && 'hover:bg-surface-100', classNames)}
        onClick={onClick}
        id={id}
      >
        <NavItem
          Icon={Icon}
          title={title}
          planType={planType}
          isActive={isActive}
          notIncludedInTrial={notIncludedInTrial}
          includedInGrow={includedInGrow}
        />
        {badge && <div className="ml-2">{badge}</div>}
      </button>
    );
  }

  if (href) {
    return (
      <a
        href={href}
        className={cx(BASE_STYLES, isActive && COLOR_MAP[variant], !isActive && 'hover:bg-surface-100')}
        target="_blank"
        rel="noreferrer"
      >
        <NavItem
          Icon={Icon}
          title={title}
          planType={planType}
          isActive={isActive}
          isExternal
          notIncludedInTrial={notIncludedInTrial}
          includedInGrow={includedInGrow}
        />
        {badge && <div className="ml-2">{badge}</div>}
      </a>
    );
  }

  return (
    <Link
      to={to || ''}
      className={cx(BASE_STYLES, isActive && COLOR_MAP[variant], !isActive && 'hover:bg-surface-100')}
    >
      <NavItem
        Icon={Icon}
        title={title}
        planType={planType}
        isActive={isActive}
        notIncludedInTrial={notIncludedInTrial}
        includedInGrow={includedInGrow}
      />
      {badge && <div className="ml-2">{badge}</div>}
    </Link>
  );
};

export default NavItemLink;
