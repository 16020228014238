import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Link as LinkIcon } from "@phosphor-icons/react";

import { useWebsiteContext } from "@/context/website-context";
import { useCurrentPublication } from "@/hooks";

import { ImageInput } from "../../_components/UI/ImageInput";
import { Input } from "../../_components/UI/Input"
import { Text } from "../../_components/UI/Text";
import { Textarea } from "../../_components/UI/Textarea";

import SettingsItem from "./SettingsItem";
import { XIcon } from "./XIcon";


export function XSettings({
  onUpdate,
}: {
  onUpdate: (key: string, value: string | undefined) => void;
}) {
  const { site } = useWebsiteContext();
  const currentPublication = useCurrentPublication();

  const [xTitle, setXTitle] = useState<string>(site?.draft_site_version?.metadata?.x_title || '');
  const [xDescription, setXDescription] = useState<string>(site?.draft_site_version?.metadata?.x_description || '');
  const [xUsername, setXUsername] = useState<string>(site?.draft_site_version?.metadata?.x_username || '');
  const xImageUrl = site?.draft_site_version?.metadata?.x_image_url;

  useEffect(() => {
    setXTitle(site?.draft_site_version?.metadata?.x_title || '');
    setXDescription(site?.draft_site_version?.metadata?.x_description || '');
    setXUsername(site?.draft_site_version?.metadata?.x_username || '');
  }, [site]);

  const defaultTitle = site?.draft_site_version?.metadata?.meta_title || currentPublication?.data?.name || '';
  const defaultDescription = site?.draft_site_version?.metadata?.meta_description || `A website for ${currentPublication?.data?.name}`;
  const defaultUsername = '@username';


  return (
    <SettingsItem
      title="Twitter (X) Card"
      description="Customize how your site looks like when shared on Twitter (X)."
    >
      <div className="flex flex-col lg:flex-row gap-6 w-full pt-4">
        <div className="flex flex-1 flex-col gap-6">

          <ImageInput
            labelText="Image"
            value={site?.draft_site_version?.metadata?.x_image_url || null}
            onSave={() => {
              // TODO: Update with image upload
              onUpdate('x_image_url', "https://beehiiv-marketing-images.s3.amazonaws.com/Redesign2023/Homepage/png/homePageOG.jpeg");
            }}
            width={100}
            height={100}
            onRemove={() => {
              onUpdate('x_image_url', undefined);
            }}
          />

          <Input
            type="text"
            labelText="Title"
            id="x_title"
            placeholder={defaultTitle}
            value={xTitle}
            onChange={(e) => setXTitle(e.target.value)}
            onBlur={() => {
              onUpdate('x_title', xTitle);
            }}
          />

          <Textarea
            id="x_description"
            labelText="Description"
            placeholder={defaultDescription}
            value={xDescription}
            onChange={(e) => setXDescription(e.target.value)}
            onBlur={() => {
              onUpdate('x_description', xDescription);
            }}
          />

          <Input
            type="text"
            id="x_username"
            labelText="Username"
            placeholder={defaultUsername}
            value={xUsername}
            onChange={(e) => setXUsername(e.target.value)}
            onBlur={() => {
              onUpdate('x_username', xUsername);
            }}
          />
        </div>
        <div className="flex flex-1 flex-col gap-3">
          <div className="is-size-7 with-text-color mb-2">Twitter (X) Preview</div>
          <div className="bg-wb-primary rounded-xl px-5 py-4 shadow-md flex flex-col gap-4 mb-2">

            <div className="flex items-center gap-2">
              <div className="bg-wb-surface rounded-full w-8 h-8" />
              <div className="flex flex-col flex-1">
                <Text size="xs" weight="bold">Name</Text>
                <Text size="2xs" variant="secondary" weight="regular">{xUsername || defaultUsername}</Text>
              </div>
              <XIcon />
            </div>

            <div className="flex flex-col gap-2">
              <div className="w-full h-3 bg-wb-secondary rounded-full" />
              <div className="w-3/4 h-3 bg-wb-secondary rounded-full" />
            </div>

            <div className="flex flex-col bg-wb-background rounded-2xl border border-wb-border overflow-hidden">
              {xImageUrl && <img src={xImageUrl} className="w-full h-[180px] object-cover" alt="X Card Preview" />}
              <div className="w-full p-3 flex flex-col" >
                <Text size="sm">{xTitle || defaultTitle}</Text>
                <Text size="sm" variant="secondary">{xDescription || defaultDescription}</Text>
                <div className="flex items-center gap-1">
                  <LinkIcon size={14} weight="bold" className="text-wb-secondary" />
                  <Text size="sm" variant="secondary">{currentPublication?.data?.hostname}</Text>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <Text size="sm" weight="bold">How do I refresh this card?</Text>
            <Text size="sm" variant="secondary">
              Twitter(X) crawls your site to update Twitter Cards on a weekly basis. It might take a while for changes to be reflected.{' '}
              <Link
                to="https://developer.x.com/en/docs/twitter-for-websites/cards/guides/troubleshooting-cards#refreshing"
                target="_blank"
                rel="noopener noreferrer"
                className="text-wb-accent"
              >
                Click here
              </Link> for more information.
            </Text>
          </div>
        </div>
      </div>
    </SettingsItem>
  )
}
