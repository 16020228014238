import cx from 'classnames';
import moment from 'moment-mini';

import Badge from '@/components/Badge';
import Card from '@/components/Card';
import { getColor } from '@/components/DesignTokens';
import { PublicationLogo } from '@/components/PublicationLogo';
import SplitButton from '@/components/SplitButton';
import { Typography } from '@/components/Typography';
import { ReferralQuality } from '@/interfaces/boosts/grow/application_metrics';
import { BoostPublication } from '@/interfaces/boosts/grow/boost_publication';

import BoostApplicationLevelIndicator from './BoostApplicationLevelIndicator';

interface Props {
  publication: BoostPublication;
  createdAt: Date | string;
  onClickReview: () => void;
  showReferralQuality?: boolean;
  referralQuality?: ReferralQuality;
  onAccept: () => void;
  onReject: (rejectReason: string) => void;
  isAccepting: boolean;
  isRejecting: boolean;
  isAccepted: boolean;
  isRejected: boolean;
}

const ApplicationCard = ({
  createdAt,
  publication,
  onClickReview,
  onAccept,
  onReject,
  isAccepting,
  isRejecting,
  isAccepted,
  isRejected,
  referralQuality = null,
  showReferralQuality = false,
}: Props) => {
  const { logo_url: logoUrl, name, url, hostname, tags } = publication;
  return (
    <Card className="w-full" noPadding>
      <div className="px-4">
        <div className="flex items-start gap-x-2 py-4">
          <PublicationLogo publication={{ logo_url: logoUrl, name }} size="xl" />
          <div className="flex flex-col gap-y-1">
            <Typography weight="semibold" colorWeight="900">
              {name}
            </Typography>
            <a href={url} target="_blank" rel="noreferrer">
              <Typography size="sm" colorWeight="500" color="tertiary">
                {hostname}
              </Typography>
            </a>
          </div>
        </div>
        <div className={cx('flex gap-x-2', showReferralQuality ? 'py-2' : 'py-4')}>
          {tags.length > 0 ? tags.map((tag) => <Badge type="information">{tag.name}</Badge>) : null}
        </div>
        {showReferralQuality && (
          <div className="pt-4 pb-6">
            <BoostApplicationLevelIndicator referralQuality={referralQuality} />
          </div>
        )}
      </div>
      <div className={cx('flex px-4 py-5 justify-between items-center', getColor('surface/50', 'background'))}>
        <Typography size="sm" colorWeight="500">
          <time dateTime={typeof createdAt === 'string' ? createdAt : createdAt.toDateString()}>
            {moment(createdAt).fromNow()}
          </time>
        </Typography>

        {!isAccepted && !isRejected ? (
          <SplitButton
            size="xs"
            disabled={isAccepting || isRejecting}
            buttons={[
              {
                label: 'Review',
                onClick: onClickReview,
              },
              {
                label: 'Accept',
                onClick: onAccept,
                loading: isAccepting,
              },
              {
                label: 'Reject',
                onClick: onReject,
                loading: isRejecting,
              },
            ]}
          />
        ) : null}
        {isAccepted ? <Badge type="success">Accepted</Badge> : null}
        {isRejected ? <Badge type="information">Rejected</Badge> : null}
      </div>
    </Card>
  );
};

export default ApplicationCard;
