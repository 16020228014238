import { PropsWithChildren } from 'react';

import { Button, ButtonProps } from './Button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from './Dialog';
import { Text } from './Text';

type Props = PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  isLoadingText?: string;
  title: string;
  description?: string;
  ctaText?: string;
  ctaVariant?: ButtonProps['variant'];
  onConfirm: () => void;
}>;

const Modal = ({
  isOpen,
  onClose,
  children,
  isLoadingText,
  isLoading,
  ctaText,
  ctaVariant,
  onConfirm,
  title,
  description,
}: Props) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <Text size="xl" weight="semibold" variant="primary" as="h4">
              {title}
            </Text>
          </DialogTitle>
          {description && (
            <DialogDescription>
              <Text size="sm" weight="semibold" variant="secondary" as="p">
                {description}
              </Text>
            </DialogDescription>
          )}
        </DialogHeader>
        {children}
        <DialogFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>

          <Button onClick={onConfirm} variant={ctaVariant}>{isLoading ? isLoadingText : ctaText}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
