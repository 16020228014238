import React from 'react';

import { useCurrentUser } from '../../../../../context/current-user-context';
import { SkeletonLoader } from '../../../../SkeletonLoader';

const UserDropdown = () => {
  const { currentUser } = useCurrentUser();

  return (
    <div className="relative flex items-center justify-start sm:justify-center">
      <SkeletonLoader
        isLoading={!currentUser}
        skeletons={
          <div>
            <div className="rounded-full h-8 w-8 bg-gray-200" />
          </div>
        }
      >
        {currentUser && (
          <div className="flex space-x-2 justify-center">
            <div className="h-6 w-6">
              <img className="h-6 w-6 rounded-full object-cover" src={currentUser?.profile_picture.thumb.url} alt="" />
            </div>
            <div className="flex flex-col">
              <h5 className="text-xs font-bold text-gray-900">{`${currentUser?.first_name} ${currentUser?.last_name}`}</h5>
              <span className="text-xs font-regular text-gray-500 truncate w-36">{currentUser?.email}</span>
            </div>
          </div>
        )}
      </SkeletonLoader>
    </div>
  );
};

export default UserDropdown;
