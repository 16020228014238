import React, { FC, useState } from 'react';

import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import { ZendeskTicket, ZendeskTicketPriority, ZendeskTicketReason } from '../../interfaces/zendesk/ticket';
import { FileSelect, Input, SimpleSelect, Textarea } from '../Form';

interface Props {
  onSubmit: (data: ZendeskTicket) => Promise<void>;
}

const ZendeskTicketForm: FC<Props> = ({ onSubmit }) => {
  const [saving, setSaving] = useState(false);
  const [reason, setReason] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [attachment, setAttachment] = useState<File | null>(null);
  const [priority, setPriority] = useState('normal');

  const reasonOptions = [
    { label: 'Bug or software issue', value: 'bug' },
    { label: 'Creating a post', value: 'post_creation' },
    { label: "Can't access my account", value: 'account_access' },
    { label: 'Billing', value: 'billing' },
    { label: 'Editor', value: 'editor' },
    { label: 'Upgrading account', value: 'upgrading' },
    { label: 'Migrating from a different platform', value: 'migrating' },
    { label: 'Feature request', value: 'feature_request' },
    { label: 'Something else', value: 'something_else' },
    { label: 'Delete my account', value: 'account_deletion' },
  ];

  const priorityOptions = [
    { label: 'Normal', value: 'normal' },
    { label: 'High', value: 'high' },
  ];

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSaving(true);

    onSubmit({
      reason: reason as ZendeskTicketReason,
      subject,
      body,
      attachment,
      priority: priority as ZendeskTicketPriority,
    })
      .then(() => {
        analytics.track('Ticket Created');
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleSelectReason = (_name: string, value: string) => {
    setReason(value);
  };

  const handleAttachmentChange = (selectedFile: File | null) => {
    setAttachment(selectedFile);
  };

  const handleSelectPriority = (_name: string, value: string) => {
    setPriority(value);
  };

  const isDisabled = () => !reason && !subject && !priority && !body;

  return (
    <form className="space-y-4" onSubmit={handleSubmit}>
      <div>
        <SimpleSelect
          name="reason"
          labelText="Reason for reaching out"
          value={reason}
          onSelect={handleSelectReason}
          options={reasonOptions}
        />
      </div>
      <div>
        <Input
          name="subject"
          labelText="Subject"
          type="text"
          value={subject}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSubject(e.target.value)}
          required
        />
      </div>
      <div>
        <Textarea
          name="body"
          rows={8}
          labelText="Description"
          helperText="Please enter the details of your request"
          value={body}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setBody(e.target.value)}
          required
        />
      </div>
      <div>
        <FileSelect
          name="file"
          labelText="Attachment"
          onFileSelect={handleAttachmentChange}
          helperText="Optional: attach a screenshot or screen recording"
          file={attachment}
          accept=".jpg,.jpeg,.gif,.png,.mov,.mp4,.webp"
        />
      </div>
      <div>
        <SimpleSelect
          name="priority"
          labelText="Priority"
          value={priority}
          options={priorityOptions}
          onSelect={handleSelectPriority}
        />
      </div>

      <div className="pt-8">
        <Button type="submit" disabled={isDisabled()} loading={saving}>
          Create support ticket
        </Button>
      </div>
    </form>
  );
};

export default ZendeskTicketForm;
