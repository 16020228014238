import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { SkeletonLoader } from '@/components/SkeletonLoader';
import { WebsiteProvider } from '@/context/website-context';

import { Layout } from '../../_components/Layout';
import { Main } from '../../_components/Main';
import { Breadcrumbs } from '../../_components/Main/Breadcrumbs';
import { TopBar } from '../../_components/Main/TopBar';
import { NavMenuItem } from '../../_components/SideNav/NavMenuItem';
import { NavSection } from '../../_components/SideNav/NavSection';
import { NavSectionTitle } from '../../_components/SideNav/NavSectionTitle';

const SETTINGS_PAGE_DETAILS: any = Object.freeze({
  settings: {
    title: 'General',
    description: 'Configure your websites settings in depth, become GDPR compliant, manage comments, and more',
  },
  seo: {
    title: 'Search Engine Optimization (SEO)',
    description: 'Set how your site looks like on search engines and when shared',
  },
  comments: {
    title: 'Comments',
    description:
      'Allow users to engage in a comments section for a post. You can disable this feature on a post-by-post basis',
  },
  code_injection: {
    title: 'Code Injection & Customization',
    description: 'Add custom fonts, colors, and custom code to your site',
  },
  pixels: {
    title: 'Pixels',
    description: 'Manage your tracking pixels used for ads and analytics',
  },
  gdpr: {
    title: 'GDPR, CCPA, & Compliance',
    description: 'Manage your website to meet the requirements for GDPR & CCPA compliance',
  },
});

const LoaderLine = () => (
  <div className="cursor-pointer p-4 overflow-hidden bg-neutral-50 dark:bg-neutral-900 border border-solid border-wb-primary shadow-wb-md rounded-xl h-4 relative animate" />
);

const SettingsLayout = () => {
  const { pathname } = useLocation();
  const pathCheck = pathname.split('/').pop() || 'settings';

  const pageDetails = SETTINGS_PAGE_DETAILS[pathCheck];
  return (
    <Layout
      backPath="/website_builder_v2"
      sidenavChildren={
        <NavSection>
          <NavSectionTitle title="Site-Wide Settings" />
          <NavMenuItem title="General" to="/website_builder_v2/settings" />
          <NavMenuItem title="Search Engine Optimization" to="/website_builder_v2/settings/seo" />
          <NavMenuItem title="Comments" to="/website_builder_v2/settings/comments" />
          <NavMenuItem title="Pixels" to="/website_builder_v2/settings/pixels" />
          <NavMenuItem title="GDPR, CCPA, & Compliance" to="/website_builder_v2/settings/gdpr" />
        </NavSection>
      }
    >
      <Main className="w-full max-w-[1200px] mx-auto !gap-4 pb-8">
        <Breadcrumbs />
        <TopBar title={pageDetails.title} description={pageDetails.description} hasDivider />
        <SkeletonLoader
          isLoading={false}
          skeletons={
            <div className="flex flex-col space-y-4">
              <LoaderLine />
              <LoaderLine />
              <LoaderLine />
              <LoaderLine />
              <LoaderLine />
              <LoaderLine />
              <LoaderLine />
              <LoaderLine />
            </div>
          }
          as={React.Fragment}
        >
          <Outlet />
        </SkeletonLoader>
      </Main>
    </Layout>
  );
};

const WebsiteSettingsLayout = () => {
  return (
    <WebsiteProvider>
      <SettingsLayout />
    </WebsiteProvider>
  );
};

export default WebsiteSettingsLayout;
