import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';

import Card from '@/components/Card';
import IconButton from '@/components/IconHelpers/IconButton';
import { LineDivider } from '@/components/LineDivider';
import LoadingBox from '@/components/LoadingBox';
import { Typography } from '@/components/Typography';
import useClickmapPostPreview from '@/hooks/useClickmapPostPreview';
import { ClickMapPostClick } from '@/interfaces/post';
import { Iframe } from '@/ui/Iframe';
import pluralize from '@/utils/pluralize';

import PostClicksContainer from '../PostClicksContainer';
import PostStatsContainer from '../PostStatsContainer';

import ClicksSummary from './ClicksSummary';
import { getClicksPercentage } from './getClicksPercentage';
import LinksSummary from './LinksSummary';
import LoadingMessage from './Loading';

interface RouteParams {
  postId: string;
}

const formatter = new Intl.NumberFormat('en');

const ClickMap = () => {
  const { postId } = useParams() as unknown as RouteParams;
  const { data, isLoading: isLoadingPreview, isError } = useClickmapPostPreview(postId);
  const { html } = data || {};

  const navigate = useNavigate();

  const [clickedLink, setClickedLink] = useState<ClickMapPostClick | null>(null);

  const handleClickLink = (click: ClickMapPostClick) => {
    if (click.link_id === clickedLink?.link_id) {
      setClickedLink(null);
      return;
    }
    setClickedLink(click);
  };

  return (
    <div className="w-full h-full">
      <Card noPadding className="w-full h-full">
        <div className="flex w-full h-[54px] py-3 px-4 items-center">
          <div className="flex items-center w-1/3 gap-x-2">
            <IconButton onClick={() => navigate(`/posts/${postId}/analytics`)}>
              <ArrowLeftIcon />
            </IconButton>
            <Typography token="font-medium/text/sm">Click Map</Typography>
          </div>
        </div>

        <PostStatsContainer postId={postId}>
          {(postStats, isLoadingPostStats) => {
            if (isLoadingPostStats) {
              return <LoadingMessage />;
            }

            const totalEmailClicks = postStats.total_email_clicked;

            return (
              <div className="flex flex-col xl:flex-row h-[calc(100%_-_54px)]">
                <div className="w-full flex flex-col xl:w-1/3 xl:overflow-y-scroll">
                  <div className="flex flex-col gap-y-4 px-4 py-6">
                    <Typography token="font-medium/text/base">Clicks Summary</Typography>
                    <ClicksSummary postId={postId} postStats={postStats} />
                  </div>
                  <LineDivider />
                  <div className="flex flex-col gap-y-4 px-4 py-6">
                    <Typography token="font-medium/text/base">Links Summary</Typography>
                    <LinksSummary postId={postId} totalEmailClicks={totalEmailClicks} onClickLink={handleClickLink} />
                  </div>
                </div>
                <div className="w-full h-full p-6 bg-surface-100 xl:overflow-y-scroll">
                  <LoadingBox isLoading={isLoadingPreview || !html} isError={isError}>
                    <PostClicksContainer postId={postId} platform="email" allResults groupBy="link_id">
                      {(clicks, _, isLoadingPostClicks) => {
                        if (isLoadingPostClicks) {
                          return null;
                        }

                        return (
                          <Iframe
                            html={html}
                            className="w-full h-full"
                            shouldResizeHeight
                            clickedLink={
                              clickedLink
                                ? {
                                    linkId: clickedLink?.link_id,
                                    urlOffsetIndex: clickedLink?.url_offset_index,
                                  }
                                : undefined
                            }
                            highlightURLs={
                              clicks.length > 0 && !isLoadingPostClicks
                                ? (clicks as ClickMapPostClick[]).map((click) => ({
                                    url: click.url,
                                    linkId: click.link_id,
                                    urlOffsetIndex: click.url_offset_index,
                                    value: click.total_unique_email_clicked,
                                    label: 'total clicks',
                                    element: (
                                      <div className="relative flex flex-row gap-x-1 bg-surface-200/90 rounded-md py-1 px-2 border border-surface-300 shadow-sm">
                                        <Typography token="font-medium/text/xs">
                                          {`${formatter.format(click.total_unique_email_clicked)} ${pluralize(
                                            'click',
                                            click.total_unique_email_clicked,
                                            undefined,
                                            true
                                          )} (${getClicksPercentage(click, totalEmailClicks).toFixed(2)}%)`}
                                        </Typography>
                                      </div>
                                    ),
                                  }))
                                : []
                            }
                          />
                        );
                      }}
                    </PostClicksContainer>
                  </LoadingBox>
                </div>
              </div>
            );
          }}
        </PostStatsContainer>
      </Card>
    </div>
  );
};

export default ClickMap;
