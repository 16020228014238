// eslint-disable-next-line
import Confetti from 'react-confetti';
import { Helmet } from 'react-helmet';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { useCurrentUser } from '@/context/current-user-context';
import { useCurrentPublication } from '@/hooks';
import analytics from '@/utils/analytics';
import { useStripeBillingSession } from '@/hooks/useBilling';
import { BillingActions } from '@/interfaces/billing';
import { Layout, LeftPane, RightPane } from '../_components/Layout';
import UpgradeOption from './_components/UpgradeOption';
import { capitalize } from '@/utils';
import TrialOption from './_components/TrialOption';
import OnboardingStepForm from '../_components/OnboardingStepForm';
import useUpdateOnboarding from '../_hooks/useUpdateOnboarding';
import useCreateTrial from '../_hooks/useCreateTrial';

const OnboardingTrialFeatures = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const { data: currentPublication } = useCurrentPublication();
  const updateOnboarding = useUpdateOnboarding();
  const createTrial = useCreateTrial();

  const stripeUpgradeSession = useStripeBillingSession({
    organizationId: currentPublication?.organization_id,
    action: BillingActions.UPGRADE,
  });

  const [isRequesting, setIsRequesting] = useState(false);
  const selectedPlan: 'scale' | 'max' | 'launch' =
    (localStorage.getItem('selectedPlan') as 'scale' | 'max' | 'launch') || 'scale';
  const [selectedOption, setSelectedOption] = useState('upgrade');
  const planOptions = {
    scale: ['Ad Network', 'Premium Subscriptions', 'Boosts'],
    max: ['NewsletterXP Course', 'Up to 10 Publications', 'Priority Support'],
    launch: [],
  };

  const handleUpgrade = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsRequesting(true);

    updateOnboarding
      .mutateAsync({ status: 'complete' })
      .then(() => {
        if (currentUser?.id) {
          analytics.identify(currentUser?.id, {
            email: currentUser?.email,
            state: 'trialing',
            current_plan: 'launch',
          });
        }

        analytics.track('Completed Onboarding', { email: currentUser?.email });
        analytics.track('Clicked Upgrade', { email: currentUser?.email });
      })
      .then(() => createTrial.mutateAsync({ selectedPlan }))
      .finally(() => stripeUpgradeSession.mutateAsync({}));
  };

  const handleTrial = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsRequesting(true);

    updateOnboarding
      .mutateAsync({ status: 'complete' })
      .then(() => {
        if (currentUser?.id) {
          analytics.identify(currentUser?.id, {
            email: currentUser?.email,
            state: 'trialing',
            current_plan: 'launch',
          });
        }
        analytics.track('Completed Onboarding', { email: currentUser?.email });
        analytics.track('Trial Started', {
          state: 'trialing',
          current_plan: 'launch',
          trial_plan: selectedPlan,
        });
      })
      .then(() => createTrial.mutateAsync({ selectedPlan }))
      .finally(() => queryClient.invalidateQueries(['publications', currentPublication?.id]));
  };

  return (
    <>
      <Helmet>
        <title>Start Free Trial - beehiiv</title>
      </Helmet>

      <Layout>
        <LeftPane>
          <OnboardingStepForm
            title="You're almost in, just one more step to go:"
            subtitle="How would you like to get started?"
            className="max-w-5xl"
            onSubmit={(e) => (selectedOption === 'upgrade' ? handleUpgrade(e) : handleTrial(e))}
            onBack={() => navigate('/onboarding/business_questionnaire')}
            isProcessing={isRequesting}
            centered
          >
            <div className="w-full items-start space-y-6 flex flex-col md:flex-row md:space-x-10 md:space-y-0">
              <UpgradeOption
                planName={capitalize(selectedPlan)}
                selected={selectedOption === 'upgrade'}
                onClick={() => setSelectedOption('upgrade')}
                upgradeOptions={planOptions[selectedPlan]}
              />
              <TrialOption
                planName={capitalize(selectedPlan)}
                selected={selectedOption === 'trial'}
                onClick={() => setSelectedOption('trial')}
                excludedOptions={planOptions[selectedPlan]}
              />
            </div>
          </OnboardingStepForm>
        </LeftPane>
        <RightPane />
      </Layout>
    </>
  );
};

export default OnboardingTrialFeatures;
