import { useState } from 'react';

import Text from '@/components/Text';
import Styled from '@/components/TiptapEditor/components/DragHandleButton/styled';
import { useThreadComposer } from '@/components/TiptapEditor/components/panels/ThreadComposer/Context';
import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { Panel, PanelDivider, PanelFooter, PanelSection } from '@/components/TiptapEditor/components/ui/Panel';
import { ContentTag } from '@/interfaces/content_tag';
import { Post } from '@/interfaces/post';

import AddContentTagMenu from './AddContentTagMenu';

interface Props {
  contentTags?: ContentTag[];
  post: Post;
  updatePost: (data: Post) => void;
  smallVersion?: boolean;
  setShowCreateContentTagModal: (value: boolean) => void;
  refetchContentTags(): void;
}

const ContentTagsActionsMenu = ({
  post,
  updatePost,
  contentTags,
  smallVersion,
  setShowCreateContentTagModal,
  refetchContentTags,
}: Props) => {
  const [showAddContentTagMenu, setShowAddContentTagMenu] = useState(false);

  const { openComposer } = useThreadComposer();

  const handleOpenComposer = () => {
    openComposer();
  };

  if (showAddContentTagMenu && contentTags) {
    return (
      <AddContentTagMenu
        onBack={() => setShowAddContentTagMenu(false)}
        contentTags={contentTags}
        post={post}
        updatePost={updatePost}
        setShowCreateContentTagModal={setShowCreateContentTagModal}
        refetchContentTags={refetchContentTags}
      />
    );
  }

  return (
    <Styled.ContentWrapper>
      <Panel tabIndex={-1}>
        <PanelSection>
          <Styled.MenuButtonWrapper>
            {!smallVersion && (
              <>
                <Button
                  $variant="quaternary"
                  $size="small"
                  onClick={handleOpenComposer}
                  $fullWidth
                  $leftSlot={<Icon name="Comment" />}
                  $muted
                >
                  Comment
                </Button>
                <PanelDivider />
              </>
            )}
            <Text size="xs">Content tags are only displayed on the post card currently</Text>
            <Button
              $variant="quaternary"
              $size="small"
              onClick={() => {
                setShowAddContentTagMenu(true);
              }}
              $fullWidth
              $leftSlot={<Icon name="Tag" className="mr-2" />}
              $rightSlot={<Icon name="ChevronRight" />}
            >
              Add content tags
            </Button>
            {!smallVersion && (
              <PanelFooter>
                <Button
                  $variant="quaternary"
                  $size="small"
                  onClick={() => {
                    updatePost({ ...post, content_tag_ids: [] });
                  }}
                  $fullWidth
                  $leftSlot={<Icon name="Trash" />}
                  $muted
                >
                  Delete
                </Button>
              </PanelFooter>
            )}
          </Styled.MenuButtonWrapper>
        </PanelSection>
      </Panel>
    </Styled.ContentWrapper>
  );
};

export default ContentTagsActionsMenu;
