import { useEffect, useState } from "react";

import { AccordionCard } from "@/components/Accordion/variants/AccordionCard";
import Banner from "@/components/Banner";
import { Input, Switch } from "@/components/Form";
import { Typography, TypographyStack } from "@/components/Typography";
import { useCreateSplitTest, useDeleteSplitTest, useSplitTest } from "@/hooks";
import { PostStatus } from "@/interfaces/post";
import { Card } from "@/ui/Card";

import { useEditorContext } from "../EditorContext";

import SplitTestForm, { DEFAULTS } from "./SplitTestForm";


const Email = (): JSX.Element => {
  const { formData, onChange } = useEditorContext();
  const splitTestObject = useSplitTest(formData?.id || "").data;
  const [audienceSizeWarningDisplayed, setAudienceSizeWarningDisplayed] = useState(false);
  const [splitTesting, setSplitTesting] = useState(formData?.split_test || false);
  const [splitTestOptions, setSplitTestOptions] = useState(splitTestObject?.split_test_options || []);

  useEffect(() => {
    const calcOptions = () => {
      const optionCount = splitTestOptions.length;

      return optionCount < DEFAULTS.MIN_VARIANTS ? DEFAULTS.MIN_VARIANTS : optionCount;
    };

    if ((splitTestObject?.population_size || 0) / calcOptions() < DEFAULTS.MIN_SAMPLE_SIZE_PER_VARIANT) {
      setAudienceSizeWarningDisplayed(true);
    }
  }, [splitTestOptions, splitTestObject]);

  const handleDeleteSuccess = () => {
    console.log("Split test deleted");
  };

  const createSplitTestMutation = useCreateSplitTest(
    formData?.id || "",
    () => {},
    () => {}
  );

  const deleteSplitTestMutation = useDeleteSplitTest(formData?.id || "", handleDeleteSuccess, () => {});

  const handleSplitTestToggle = (checked: boolean) => {
    setSplitTesting(checked);

    if (checked) {
      createSplitTestMutation.mutate();
    } else {
      deleteSplitTestMutation.mutate();
    }
  };

  const handleSetParentSplitTestOptions = (options: any) => {
    setSplitTestOptions(options);
  }

  if (!formData) {
    return <div/>;
  }

  return (
    <div className="px-2 md:px-20 py-10 flex flex-col gap-6">
      <Card className="w-full md:w-2/3 mx-auto flex flex-col gap-6">
        <Typography token="font-medium/text/lg">
          Sending details
        </Typography>
        {
          splitTesting && audienceSizeWarningDisplayed && (
            <Banner
              title="Selected audience may be too small for the number of options"
              variant="warning"
              isScreenWide={false}
            />
          )
        }
        <div className="flex flex-col gap-1">
          <div className="flex flex-row justify-between">
            <Typography token="font-medium/text/sm" className="py-2">Subject line*</Typography>
            <div className="flex gap-2">
              <Typography className="py-2" token="font-medium/text/sm">A/B test</Typography>
              <Switch
                checked={splitTesting}
                onChange={(name, e) => handleSplitTestToggle(e)}
                variant="primary"
                name="ab-test"
                showIcons={false}
              />
            </div>
          </div>
          <SplitTestForm post={formData} splitTesting={splitTesting} splitTestObject={splitTestObject} audienceSizeWarningDisplayed={audienceSizeWarningDisplayed} setParentSplitTestOptions={handleSetParentSplitTestOptions} setSplitTesting={setSplitTesting}>
            <div className="flex flex-row space-x-2">
              <div className="h-8 w-8 flex flex-col justify-center items-center text-gray-500 border border-surface-200 rounded bg-surface-100">
                <Typography size="xs" colorWeight='900' color='primary'>
                  A
                </Typography>
                <div/>
              </div>
              <Input
                name="subject_line"
                value={formData.email_subject_line || ''}
                onChange={(e) => onChange({ email_subject_line: e.target.value })}
                required
                className="w-full"
                disabled={formData.locked_fields.email_subject_line || formData.status !== PostStatus.DRAFT}
                helperText={
                  formData.locked_fields.email_subject_line
                    ? 'You can no longer change the subject line'
                    : 'This will be same as post title unless modified'
                }
              />
            </div>
          </SplitTestForm>
          {!splitTesting && <Input
            name="email-subject-line"
            value={formData.email_subject_line || ''}
            helperText="This will be same as post title unless modified"
            onChange={(e) => onChange({ email_subject_line: e.target.value })}
          />}
        </div>
        <div>
          <Typography token="font-medium/text/sm" className="py-2">Preview text</Typography>
          <Input
            name="preview-text"
            value={formData.email_preview_text || ''}
            className="py-2"
            onChange={(e) => onChange({ email_preview_text: e.target.value })}
            helperText="This will be same as post subtitle unless modified"
          />
        </div>
      </Card>
      <Card className="w-full md:w-2/3 mx-auto flex flex-col gap-6">
        <TypographyStack>
          <Typography token="font-medium/text/lg">
            Email header buttons
          </Typography>
          <Typography token="font-normal/text/sm">
            These buttons in the email let readers share, like, and comment on this post
          </Typography>
        </TypographyStack>
        <Switch
          name="email-header-socials"
          checked={formData?.email_header_social_share}
          onChange={(name, e) => onChange({ email_header_social_share: e })}
          prefixLabelText="Socials"
          variant="primary"
          showIcons={false}
        />
        <Switch
          name="email-header-engagement"
          checked={formData?.email_header_engagement_buttons}
          onChange={(name, e) => onChange({ email_header_engagement_buttons: e })}
          prefixLabelText="Engagement buttons"
          variant="primary"
          showIcons={false}
        />
      </Card>
      <div className="w-full md:w-2/3 mx-auto">
        <AccordionCard
          title="Advanced settings"
          subText=""
          topBorder={false}
          titleSize="lg"
        >
          <div className="flex flex-col gap-6">
            <Banner
              title="Only edit this if you want “Read Online” clicks in your newsletter to go to a non-beehiiv website"
              variant="information"
              isScreenWide={false}
            />
            <Input
              name="read-online-url"
              value={formData.custom_live_url || formData.url}
              labelText="Read Online URL"
              onChange={(e) => onChange({ custom_live_url: e.target.value })}
              helperText="By default, the Read Online link will lead to web version of your post."
            />
          </div>
        </AccordionCard>
      </div>
    </div>
  );
}

export default Email;
