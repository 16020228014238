import { CustomFieldKind } from '../interfaces/custom_field';

export const labels = {
  [CustomFieldKind.STRING]: 'Text',
  [CustomFieldKind.INTEGER]: 'Number',
  [CustomFieldKind.BOOLEAN]: 'True / False',
  [CustomFieldKind.DATE]: 'Date',
  [CustomFieldKind.DATETIME]: 'Date & Time',
  [CustomFieldKind.LIST]: 'List (Multi-Select)',
};

export const selectOptions = Object.entries(labels).map(([value, label]) => ({ value, label }));
