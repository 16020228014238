import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { PencilIcon, PlusIcon, StarIcon, TrashIcon } from '@heroicons/react/24/outline';
import { StringParam, useQueryParam } from 'use-query-params';

import ActionModal from '@/components/ActionModal';
import Card from '@/components/Card';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import SearchInput from '@/components/SearchInput';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useSearchSubscriberTags } from '@/hooks/useSubscriberTags';
import { PublicationSubscriberTag } from '@/interfaces/subscriber_tag';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';

import EditTag from './_components/EditTag';
import NewTag from './_components/NewTag';
import SubscriberTagDropdown from './_components/SubscriberTagDropdown';

const Tags = () => {
  const [search, setSearch] = useQueryParam('search', StringParam);
  const [shouldResetSearch, setShouldResetSearch] = useState(false);
  const [deleteSubscriberTagId, setDeleteSubscriberTagId] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentPublicationId] = useCurrentPublicationState();

  const [isCreatingNewForm, setIsCreatingNewForm] = useState<boolean>(false);
  const [editTagId, setEditTagId] = useState('');
  const [editingTag, setEditingTag] = useState<PublicationSubscriberTag | null>(null);
  const subscriberTagsQuery = useSearchSubscriberTags({ search: search || '' });
  const { data, refetch, isLoading, isFetching, error } = subscriberTagsQuery;

  const subscriberTags = data?.pages.flatMap((page) => page.subscriber_tags) || [];

  const isNoResults = !isLoading && subscriberTags.length === 0;

  // After triggering search reset, switch boolean back to false
  useEffect(() => {
    if (shouldResetSearch) {
      setShouldResetSearch(false);
    }
  }, [shouldResetSearch, setShouldResetSearch]);

  useEffect(() => {
    if (error) {
      toast.error(`${error}`);
    }
  }, [error]);

  // Reset search when no results
  const handleResetSearch = () => {
    setSearch(undefined);
    setShouldResetSearch(true);
  };

  const onEditSubscriberTagRequested = (tag: PublicationSubscriberTag) => {
    setEditingTag(tag);
    setEditTagId(tag.id);
  };

  const onDeleteSubscriberTagRequested = (subscriberTagId: string) => {
    setDeleteSubscriberTagId(subscriberTagId);
  };

  const onDeleteSubscriberTagModalClose = () => {
    setDeleteSubscriberTagId('');
  };

  const onDeleteSubscriberTag = (subscriberTagId: string) => {
    const params = {
      publication_id: currentPublicationId,
    };

    setIsDeleting(true);
    api
      .delete(`/publication_subscriber_tags/${subscriberTagId}`, { params })
      .then(() => {
        setDeleteSubscriberTagId('');
        refetch();
        toast.success('Tag deleted successfully');
      })
      .catch((errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const NoResults = () => {
    const noSearchResults = isNoResults && search;

    const text = noSearchResults
      ? `No results found for search, '${search}'`
      : `Once you create your first tag, it will show up here.`;

    return (
      <div className={`bg-gray-50 rounded h-64 flex items-center justify-center ${isLoading && 'animate-pulse'}`}>
        <div className="flex flex-col items-center gap-4">
          <p className="text-gray-500 text-center">{text}</p>
          {noSearchResults ? (
            <Button variant="primary-inverse" onClick={handleResetSearch}>
              <div className="flex">
                <ArrowPathIcon className="h-5 w-5 mr-2" />
                Reset Search
              </div>
            </Button>
          ) : (
            <Button variant="primary" onClick={() => setIsCreatingNewForm(true)}>
              <div className="flex">
                <StarIcon className="h-5 w-5 mr-2" />
                Create Tag
              </div>
            </Button>
          )}
        </div>
      </div>
    );
  };

  const handleOpenNewForm = () => {
    setIsCreatingNewForm(true);
  };

  const handleCloseNewForm = () => {
    setIsCreatingNewForm(false);
    refetch();
  };

  const handleCloseEditForm = () => {
    setEditingTag(null);
    setEditTagId('');
    refetch();
  };

  const deleteModal = () => {
    const tag = subscriberTags.find((t) => t.id === deleteSubscriberTagId);
    return (
      <ActionModal
        isOpen={deleteSubscriberTagId !== ''}
        onClose={onDeleteSubscriberTagModalClose}
        onProceed={onDeleteSubscriberTag}
        resourceId={deleteSubscriberTagId}
        isWorking={isDeleting}
        headerText="Delete Tag"
        actionText="Delete"
        buttonType="danger"
      >
        <div className="space-y-2">
          <p>
            Deleting this tag will remove it from {tag?.subscriber_count || 0} subscribers. Are you sure you&apos;d like
            to do that? This action is irreversible.
          </p>
        </div>
      </ActionModal>
    );
  };

  const noResults = data && subscriberTags.length === 0;

  return (
    <div>
      {deleteModal()}
      <EditTag tag={editingTag} tagId={editTagId} isOpen={!!editingTag} onClose={handleCloseEditForm} />
      <NewTag isOpen={isCreatingNewForm} onClose={handleCloseNewForm} />
      {!noResults && (
        <div className="flex flex-col md:flex-row mb-4 justify-end">
          <div className="pt-4 md:pt-0 relative flex space-x-1">
            <div>
              <SearchInput
                defaultValue={search || ''}
                shouldDebounce={false}
                shouldReset={shouldResetSearch}
                onClearSearch={handleResetSearch}
                onSearch={setSearch}
                placeholder="Search Tags"
              />
            </div>
            {(isLoading || isFetching) && (
              <div className="hidden md:block">
                <LoadingSpinner />
              </div>
            )}
            <Button variant="primary" onClick={handleOpenNewForm}>
              <div className="flex">
                <PlusIcon className="h-5 w-5 mr-2" />
                New Tag
              </div>
            </Button>
          </div>
        </div>
      )}

      <Card className="space-y-6">
        <div className="space-y-2">
          <div className="bg-gray-100 rounded-md p-2.5">
            <h5>Subscriber Tags</h5>
          </div>
          <p className="text-xs text-gray-600 mt-2 mb-4">Custom tags for your subscribers</p>
        </div>
        <div className="space-y-2">
          {data && subscriberTags.length === 0 ? (
            <NoResults />
          ) : (
            <div className="grid grid-flow-row gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
              {subscriberTags.map((subscriber_tag) => {
                const options = [
                  {
                    label: 'Edit',
                    onClick: () => onEditSubscriberTagRequested(subscriber_tag),
                    icon: <PencilIcon />,
                    key: `edit-${subscriber_tag.id}`,
                  },
                  {
                    label: 'Delete',
                    onClick: () => onDeleteSubscriberTagRequested(subscriber_tag.id),
                    icon: <TrashIcon />,
                    key: `delete-${subscriber_tag.id}`,
                  },
                ];
                return (
                  <Card
                    className="bg-white rounded-lg border border-gray-200 grid grid-cols-2 gap-x-1 pb-1"
                    key={subscriber_tag.id}
                  >
                    <div>
                      <span
                        className={`bg-${subscriber_tag.color}-100 text-${subscriber_tag.color}-800 border-${subscriber_tag.color}-200 px-2.5 py-0.5 text-xs rounded-md border shadow-xs inline-flex gap-x-1`}
                      >
                        {subscriber_tag.name}
                      </span>
                      <p className="text-xs text-gray-600 mt-2 mb-6">
                        {subscriber_tag.subscriber_count}{' '}
                        {subscriber_tag.subscriber_count === 1 ? 'subscriber' : 'subscribers'}
                      </p>
                    </div>
                    <div className="flex justify-end">
                      <SubscriberTagDropdown options={options} />
                    </div>
                  </Card>
                );
              })}
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default Tags;
