import { useEffect, useState } from 'react';

import { Input } from '../../_components/UI/Input';
import { Textarea } from '../../_components/UI/Textarea';

interface Props {
  className?: string;
  name: string;
  placeholder?: string;
  value: string;
  onSave: (val: string) => void;
  type?: 'input' | 'textarea';
  helperText?: string | React.ReactNode;
  labelText?: string;
}

// TODO REPLACE WITH TYPDREAM INPUT WHICH SUPPORTS THE DARK MODE
// OR We Can just add the classnames needed here...
const SettingInput = ({ name, value, onSave, placeholder, className, type, helperText, labelText }: Props) => {
  const [inputValue, setInputValue] = useState<string>(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  if (type === 'textarea') {
    return (
      <Textarea
        placeholder={placeholder}
        className={className}
        name={name}
        value={inputValue}
        labelText={labelText}
        onChange={(e) => setInputValue(e.target.value)}
        onBlur={() => onSave(inputValue)}
        helperText={helperText}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSave(inputValue);
          }
        }}
      />
    );
  }

  return (
    <Input
      className={className}
      name={name}
      labelText={labelText}
      placeholder={placeholder}
      value={inputValue}
      helperText={helperText}
      onChange={(e) => setInputValue(e.target.value)}
      onBlur={() => onSave(inputValue)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onSave(inputValue);
        }
      }}
    />
  );
};

export default SettingInput;
