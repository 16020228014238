import Badge from '@/components/Badge';

interface Props {
  isVisible: boolean;
  isRequired: boolean;
}

const SettingsBadge = ({ isVisible, isRequired }: Props) => {
  if (isVisible && isRequired) {
    return <Badge type="success">Visible & Required</Badge>;
  }

  if (isVisible) {
    return <Badge type="success">Visible</Badge>;
  }

  if (isRequired) {
    return <Badge type="info_blue">Required</Badge>;
  }

  return <Badge>Hidden</Badge>;
};

export default SettingsBadge;
