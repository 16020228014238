import { Dispatch, SetStateAction, useCallback, useEffect,useMemo, useRef,useState } from 'react';
import debounce from 'lodash.debounce';

import { PostEditorSteps } from '@/components/Layout/PostEditorLayout/constants';
import { Post } from '@/interfaces/post';
import api from '@/services/swarm';

import { EditorContext, EditorContextContent } from './EditorContext';
import Review from './Review';

interface Props {
  post: Post;
  setPostEditorStep: Dispatch<SetStateAction<PostEditorSteps>>
}

const ReviewView = ({post, setPostEditorStep}: Props) => {
  const [formData, setFormData] = useState<Post>(post);

  useEffect(() => {
    setFormData(post);
  }, [post]);

  const abortControllerRef = useRef<AbortController | null>(null);

  const save = useMemo(
    () =>
      debounce(async (data: Post, callback?: () => void) => {
        abortControllerRef.current = new AbortController();

        return api
          .patch(
            `/posts/${data.id}`,
            { post: data },
            {
              signal: abortControllerRef.current.signal,
            }
          )
          .then((resp) => {
            if (resp.status >= 200 && resp.status < 300) {

              if (typeof callback === 'function') {
                callback();
              }
            }

            if (resp.data.errors) {
              console.log(resp.data.errors);
            }
          })
          .catch((err) => {
            if (err.response?.data) {
              console.log(err.response.data);
            }
          })
          .finally(() => {
            abortControllerRef.current = null;
          });
      }, 450),
    []
  );

  const cancelSave = useCallback(() => {
    abortControllerRef.current?.abort();
  }, []);

  const onChange = useMemo(
    () =>
      (data: any, callback?: () => void) => {
        cancelSave();

        setFormData((prev) => {
          const newData = {
            ...prev,
            ...data,
          };

          save(newData, callback);

          return newData;
        });
      },
    [cancelSave, save]
  );

  const providerValue = useMemo<EditorContextContent>(() => {
    return {
      editor: null,
      setEditor: () => {},
      editorIsLoading: false,
      setEditorIsLoading: () => {},
      isSaving: false,
      setIsSaving: () => {},
      unsavedChanges: false,
      setUnsavedChanges: () => {},
      wordCount: 0,
      setWordCount: () => {},
      provider: undefined,
      setProvider: () => {},
      formData,
      users: [],
      setUsers: () => {},
      showSidebar: true,
      setShowSidebar: () => {},
      showSearchAndReplaceMenu: false,
      setShowSearchAndReplaceMenu: () => {},
      collaborationEnabled: false,
      showThreadsSidebar: false,
      setShowThreadsSidebar: () => {},
      loadingNodes: {},
      setLoadingNodes: () => {},
      activeThreadId: null,
      setActiveThreadId: () => {},
      selectThread: () => {},
      unselectThread: () => {},
      threads: null,
      createThread: () => {},
      highlightThread: () => {},
      removeHighlightThread: () => {},
      onChange,
      setPostEditorStep,
    };
  }, [
      formData,
      onChange,
      setPostEditorStep,
    ]
  );
  return (
    <EditorContext.Provider value={providerValue}>
      <Review />
    </EditorContext.Provider>
  )
}

export default ReviewView;
