import React, { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ArrowPathIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { StarIcon } from '@heroicons/react/24/outline';
import { StringParam, useQueryParam } from 'use-query-params';

import ActionModal from '@/components/ActionModal';
import Card from '@/components/Card';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import SearchInput from '@/components/SearchInput';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCustomFields } from '@/hooks';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';
import { labels as dataTypeLabels } from '@/utils/dataTypes';

const CustomFields: FC = () => {
  const [search, setSearch] = useQueryParam('search', StringParam);
  const [shouldResetSearch, setShouldResetSearch] = useState(false);
  const [deleteCustomFieldId, setDeleteCustomFieldId] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentPublicationId] = useCurrentPublicationState();
  const navigate = useNavigate();

  const customFieldsQuery = useCustomFields({ search: search || '' });

  const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, isFetching, error } =
    customFieldsQuery;

  const customFields = data?.pages.flatMap((page) => page.customFields) || [];
  const totalCustomFieldCount = data?.pages[0]?.pagination?.total;
  const showingCustomFieldsCount = customFields?.length || 0;

  const isNoResults = !isLoading && customFields.length === 0;
  // After triggering search reset, switch boolean back to false
  useEffect(() => {
    if (shouldResetSearch) {
      setShouldResetSearch(false);
    }
  }, [shouldResetSearch, setShouldResetSearch]);

  useEffect(() => {
    if (error) {
      toast.error(`${error}`);
    }
  }, [error]);

  // Reset search when no results
  const handleResetSearch = () => {
    setSearch(undefined);
    setShouldResetSearch(true);
  };

  const onDeleteCustomFieldRequested = (customFieldId: string) => {
    setDeleteCustomFieldId(customFieldId);
  };

  const onDeleteCustomFieldModalClose = () => {
    setDeleteCustomFieldId('');
  };

  const onDeleteCustomField = (customFieldId: string) => {
    const params = {
      publication_id: currentPublicationId,
    };

    setIsDeleting(true);
    api
      .delete(`/custom_fields/${customFieldId}`, { params })
      .then(() => {
        setDeleteCustomFieldId('');
        refetch();
        toast.success('Custom Field deleted successfully');
      })
      .catch((errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const NoResults = () => {
    const noSearchResults = isNoResults && search;

    const text = noSearchResults
      ? `No results found for search, '${search}'`
      : `Once you create your first custom field, it will show up here.`;

    return (
      <div className={`bg-gray-50 rounded h-64 flex items-center justify-center ${isLoading && 'animate-pulse'}`}>
        <div className="flex flex-col items-center gap-4">
          <p className="text-gray-500 text-center">{text}</p>
          {noSearchResults ? (
            <Button variant="primary-inverse" onClick={handleResetSearch}>
              <div className="flex">
                <ArrowPathIcon className="h-5 w-5 mr-2" />
                Reset Search
              </div>
            </Button>
          ) : (
            <Button variant="primary" onClick={() => navigate('/subscribers/custom_fields/new')}>
              <div className="flex">
                <StarIcon className="h-5 w-5 mr-2" />
                Create Custom Field
              </div>
            </Button>
          )}
        </div>
      </div>
    );
  };

  const noResults = data && customFields.length === 0;

  return (
    <div>
      <ActionModal
        isOpen={deleteCustomFieldId !== ''}
        onClose={onDeleteCustomFieldModalClose}
        onProceed={onDeleteCustomField}
        resourceId={deleteCustomFieldId}
        isWorking={isDeleting}
        headerText="Delete Custom Field"
        actionText="Delete"
        buttonType="danger"
      >
        <div className="space-y-2">
          <p>Any posts or emails using this custom field will be blank once it has been deleted.</p>
          <p>Are you sure you want to delete this custom field?</p>
        </div>
      </ActionModal>

      {!noResults && (
        <div className="flex flex-col md:flex-row mb-4 justify-end">
          <div className="relative flex space-x-1">
            <div className="w-full sm:w-fit">
              <SearchInput
                defaultValue={search || ''}
                shouldDebounce={false}
                shouldReset={shouldResetSearch}
                onClearSearch={handleResetSearch}
                onSearch={setSearch}
                placeholder="Search Custom Fields"
              />
            </div>
            {(isLoading || isFetching) && (
              <div className="hidden md:block">
                <LoadingSpinner />
              </div>
            )}
            <Button variant="primary" onClick={() => navigate('/subscribers/custom_fields/new')}>
              <div className="flex">
                <StarIcon className="h-5 w-5 mr-2" />
                Create Custom Field
              </div>
            </Button>
          </div>
        </div>
      )}
      <Card className="space-y-6">
        <div className="space-y-2">
          <div className="bg-gray-100 rounded-md p-2.5">
            <h5>Custom Fields</h5>
          </div>
          <p className="text-xs text-gray-600">Custom metadata about your audience</p>
        </div>

        <div className="space-y-2">
          {data && customFields.length === 0 ? (
            <NoResults />
          ) : (
            <ul className="divide-y rounded-md overflow-y-auto border border-gray-200">
              {customFields.map((custom_field) => {
                const options = [
                  {
                    label: 'Edit',
                    onClick: () => navigate(`/subscribers/custom_fields/${custom_field.id}/edit`),
                  },
                  {
                    label: 'Delete',
                    onClick: () => onDeleteCustomFieldRequested(custom_field.id),
                  },
                ];
                return (
                  <li
                    style={{ marginBottom: '-1px' }}
                    className="p-4 pb-0 border-b border-gray-200"
                    key={custom_field.id}
                  >
                    <div className="flex justify-between items-start mb-0">
                      <div className="w-2/3">
                        <p className="text-gray-800 font-semibold truncate">
                          <div className="hover:text-gray-900">{custom_field.display}</div>
                        </p>
                        <p className="flex text-gray-500 text-sm truncate mb-2 uppercase">
                          {dataTypeLabels[custom_field?.kind]}
                        </p>
                      </div>
                      <div>
                        <EllipsisDropdown options={options}>
                          <div className="bg-white rounded-full h-8 w-8 flex items-center justify-center text-gray-400 hover:text-gray-900 focus:outline-none">
                            <EllipsisVerticalIcon className="w-4 h-4" aria-hidden="true" />
                          </div>
                        </EllipsisDropdown>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}

          {hasNextPage && (
            <div className="text-center my-6">
              <div>
                <Button
                  variant="primary-inverse"
                  onClick={() => fetchNextPage()}
                  disabled={!hasNextPage || isFetchingNextPage}
                >
                  {isFetchingNextPage ? 'Loading more...' : 'Load more'}
                </Button>
              </div>
            </div>
          )}
          <div className="mb-1">
            <span className="text-xs font-semibold text-gray-600">{`Showing ${showingCustomFieldsCount} of ${totalCustomFieldCount} results`}</span>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CustomFields;
