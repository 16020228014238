import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import LargeItemRadioGroup, { LargeItemRadioOption } from '@/components/Form/LargeItemRadioGroup';
import { MutationErrorResponse } from '@/interfaces/onboarding';
import { PlanPrice } from '@/interfaces/plan_price';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';
import { capitalize } from '@/utils';
import analytics from '@/utils/analytics';
import { PLAN } from '@/utils/plans';

import Section from '../../../_components/Section';

import PlanCard from './PlanCard';

const StartTrial: React.FC<{ organizationId: string }> = ({ organizationId }) => {
  const [selectedPlan, setSelectedPlan] = useState<LargeItemRadioOption>({
    name: 'Scale',
    value: PLAN.SCALE,
  });
  const [trialPlan, setTrialPlan] = useState<PlanPrice>({
    id: 'scale',
    active_subscriptions: 1,
    max_subscriptions: 1000,
    organization_max_subscriptions: 1000,
    interval: 'month',
    plan_name: 'scale',
    has_next_tier: true,
    unit_amount: 0,
    legacy: false,
  } as PlanPrice);

  const startTrial = useMutation<any, MutationErrorResponse>(
    () =>
      api
        .post(`/organizations/${organizationId}/trial`, { selected_plan: trialPlan.id })
        .then((res) => ({ ...res.data, selected_plan: trialPlan })),
    {
      onSuccess: (data) => {
        analytics.track('Trial Started', {
          state: 'trialing',
          current_plan: 'launch',
          trial_plan: data.selected_plan,
        });
        window.location.href = '/';
      },
      onError: () => {
        toast.error('An error occurred while starting your trial.');
      },
    }
  );

  return (
    <Section
      title="Start a Free Trial"
      scrollToId="start-trial"
      className="max-w-3xl"
      titleAction={
        <div className="flex space-x-4">
          <Button type="button" variant="primary-inverse" onClick={() => startTrial.mutate()}>
            Start {capitalize(trialPlan.plan_name)} Plan
          </Button>
        </div>
      }
    >
      <div className="space-y-6">
        <LargeItemRadioGroup
          options={[
            {
              name: 'Scale',
              value: PLAN.SCALE,
            },
            {
              name: 'Max',
              value: PLAN.MAX,
            },
          ]}
          selected={selectedPlan}
          justifyItems="start"
          onSelected={(option) => {
            switch (option.value) {
              case PLAN.SCALE:
                setTrialPlan({
                  id: 'scale',
                  active_subscriptions: 1,
                  max_subscriptions: 1000,
                  organization_max_subscriptions: 1000,
                  interval: 'month',
                  plan_name: 'scale',
                  has_next_tier: true,
                  unit_amount: 0,
                  legacy: false,
                } as PlanPrice);
                break;
              case PLAN.MAX:
                setTrialPlan({
                  id: 'max',
                  active_subscriptions: 1,
                  max_subscriptions: 1000,
                  organization_max_subscriptions: 1000,
                  interval: 'month',
                  plan_name: 'max',
                  has_next_tier: true,
                  unit_amount: 0,
                  legacy: false,
                } as PlanPrice);
                break;
              default:
                break;
            }
            setSelectedPlan(option);
          }}
        />
        <PlanCard planPrice={trialPlan} />
      </div>
    </Section>
  );
};

export default StartTrial;
