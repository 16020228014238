import { useState } from 'react';

import { Typography, TypographyStack } from '@/components/Typography';
import useAffiliateLink from '@/hooks/boosts/useAffiliateLink';
import { BoostInvite } from '@/interfaces/boosts/monetize/boost_invite';
import { Button } from '@/ui/Button';

import AcceptBoostInviteModal from '../../../Shared/AcceptBoostInviteModal';
import DeclineBoostInviteModal from '../../../Shared/DeclineBoostInviteModal';

interface Props {
  boostInvite: BoostInvite;
  onAccept: () => void;
  onDecline: () => void;
}

export default function ReviewBoostInviteForm({ boostInvite, onAccept, onDecline }: Props) {
  const { affiliateLink } = useAffiliateLink(boostInvite.boost_offer.boosted_publication.url);

  const [showAcceptForm, setShouldShowAcceptForm] = useState(false);
  const [showDeclineForm, setShouldDeclineAcceptForm] = useState(false);

  const handleClickAccept = () => setShouldShowAcceptForm(true);

  const handleClickDecline = () => setShouldDeclineAcceptForm(true);

  const handleCloseAcceptInviteModal = () => {
    onAccept();
    setShouldShowAcceptForm(false);
  };

  const handleCloseDeclineInviteModal = () => {
    onDecline();
    setShouldDeclineAcceptForm(false);
  };

  return (
    <>
      <AcceptBoostInviteModal
        directLinkUrl={affiliateLink}
        boostInviteId={boostInvite.id}
        isOpen={showAcceptForm}
        onClose={handleCloseAcceptInviteModal}
      />
      <DeclineBoostInviteModal
        boostInviteId={boostInvite.id}
        isOpen={showDeclineForm}
        onClose={handleCloseDeclineInviteModal}
      />
      <div className="flex flex-auto flex-col justify-between p-6 space-y-4">
        <TypographyStack>
          <Typography token="font-medium/text/sm">Reason for invite</Typography>
          <Typography token="font-normal/text/sm" colorWeight="500">
            {boostInvite.invite_reason}
          </Typography>
        </TypographyStack>

        <div className="flex gap-x-2 justify-end">
          <Button variant="primary-inverse" onClick={handleClickDecline}>
            Decline Invite
          </Button>
          <Button variant="primary" onClick={handleClickAccept}>
            Accept Invite
          </Button>
        </div>
      </div>
    </>
  );
}
