import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import Tippy from '@tippyjs/react';
import moment from 'moment-mini';

import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { Post } from '@/interfaces/post';

import DateActionsMenu from '../DisplayDateActionsMenu';

interface Props {
  post: Post;
  updateDisplayDate: (date: Date | null) => void;
}

const DisplayDate = ({ post, updateDisplayDate }: Props) => {
  const [showDateActionMenus, setShowDateActionMenus] = useState(false);
  const [dateTippyMenusShown, setDateTippyMenusShown] = useState(false);

  const onDateTippyShow = () => {
    setDateTippyMenusShown(true);
  };

  const onDateTippyHide = () => {
    setDateTippyMenusShown(false);
  };

  const onDateMouseEnter = () => {
    setShowDateActionMenus(true);
  };

  const onDateMouseLeave = () => {
    if (!dateTippyMenusShown) {
      setShowDateActionMenus(false);
    }
  };

  return (
    <div className="relative" onMouseEnter={onDateMouseEnter} onMouseLeave={onDateMouseLeave}>
      <Transition
        show={showDateActionMenus}
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute -ml-10 z-50">
          <Tippy
            offset={[0, 8]}
            placement="bottom-start"
            popperOptions={{
              modifiers: [{ name: 'flip' }],
            }}
            onShow={onDateTippyShow}
            onHide={onDateTippyHide}
            trigger="click"
            interactive
            content={
              <div className="absolute z-50">
                <DateActionsMenu post={post} updateDisplayDate={updateDisplayDate} />
              </div>
            }
          >
            <div>
              <Button $leftSlot={<Icon name="DragMenu" />} $variant="tertiary" $size="small" $isIconButton />
            </div>
          </Tippy>
        </div>
      </Transition>
      <div className="text-gray-500 mt-4">
        <span className="relative inline-block text-gray-500 mr-1 top-[.1rem]">
          <Icon name="Calendar" />
        </span>
        <span className="ml-1 font-medium inline-block">
          {moment(post.override_scheduled_at).format('MMMM DD YYYY')}
        </span>
      </div>
    </div>
  );
};

export default DisplayDate;
