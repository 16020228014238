import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import moment from "moment-mini";

import ActionModal from "@/components/ActionModal";
import { PostStatus } from "@/interfaces/post";
import api from "@/services/swarm";
import { Button } from "@/ui/Button";
import analytics from "@/utils/analytics";

import { useEditorContext } from "../EditorContext";

import ABTestSettings from "./ABTestSettings";
import Header from "./Header";
import PublishSettings from "./PublishSettings";
import ScheduleSettings from "./ScheduleSettings";

const Review = () => {
  const navigate = useNavigate();
  const { formData: post, setPostEditorStep, onChange } = useEditorContext()
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [scheduleSettingsOpen, setScheduleSettingsOpen] = useState(false);
  const [showUnpublishModal, setShowUnpublishModal] = useState(false);

  useEffect(() => {
    if (post?.scheduled_at) setSelectedTime(post.scheduled_at)
  }, [post])

  if (!post) return null
  if (!setPostEditorStep) return null

  const handlePublish = () => {
    return api
      .patch(`/posts/${post.id}/transition`, { status: 'confirmed' })
      .then(() => {
        if (selectedTime && moment(selectedTime).isAfter(moment())) {
          analytics.track('Scheduled Post');
          toast.success('Post successfully scheduled');
          navigate(`/posts/${post.id}?scheduled=true`);
        } else {
          analytics.track('Sent Post');
          toast.success('Post successfully published');
          navigate(`/posts/${post.id}?launch=true`);
        }
      });
  }

  const openUnpublishModal = () => {
    setShowUnpublishModal(true);
  }

  const renderPublishButton = () => {
    if (post.status === PostStatus.DRAFT && selectedTime) {
      const buttonText = moment(selectedTime).isBefore(moment()) ? 'Publish Now' : `Publish on ${moment(selectedTime).format("MMMM Do YYYY, h:mma")}`;
      return <div className="w-2/3 mx-auto">
        <div className="flex flex-row justify-end">
          <Button
            size="lg"
            variant="primary"
            onClick={handlePublish}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    }
    if (post.status === PostStatus.SCHEDULED) {
      return <div className="w-2/3 mx-auto">
      <div className="flex flex-row justify-end">
        <Button
          size="lg"
          variant="primary-inverse"
          className="!text-feedback-danger-600"
          onClick={openUnpublishModal}
        >
          Unschedule Post
        </Button>
      </div>
    </div>
    }
    return null;
  }

  return <div className="px-2 md:px-20 py-10 flex flex-col gap-6">
      <Header post={post} />
      <PublishSettings post={post} setPostEditorStep={setPostEditorStep}/>
      <ABTestSettings post={post} setPostEditorStep={setPostEditorStep}/>
      <ScheduleSettings
        post={post}
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
        scheduleSettingsOpen={scheduleSettingsOpen}
        setScheduleSettingsOpen={setScheduleSettingsOpen}
        onChange={onChange}
      />
      {
        selectedTime &&
        !scheduleSettingsOpen &&
        renderPublishButton()
      }
      <ActionModal
        modalMessageType="danger"
        buttonType="danger"
        buttonShade="dark"
        actionText="Unschedule"
        isOpen={showUnpublishModal}
        onClose={() => setShowUnpublishModal(false)}
        onProceed={() => {
          api
            .patch(`/posts/${post.id}/transition`, { status: PostStatus.DRAFT })
            .then(() => {
              analytics.track('Unscheduled Post');
              toast.success('Post successfully unscheduled');
              navigate(`/posts/${post.id}`)
            });
        }}
        descriptionText="Unscheduling will revert this post to a draft state. It won’t be sent via email or accessible on web. You’ll need to publish or reschedule it when ready."
        headerText="Unschedule Post"
        isWorking={false}
        disabled={false}
        resourceId="123"
      >
        <p />
      </ActionModal>
    </div>

}

export default Review;
