import React, { createContext, useCallback, useEffect, useState } from 'react';

import { IUser } from '../interfaces/user';
import User from '../models/user';
import api from '../services/swarm';

interface IUserContext {
  currentUser?: User;
  isLoading: boolean;
  reloadUser: () => void;
}

const CurrentUserContext = createContext<IUserContext | undefined>(undefined);

CurrentUserContext.displayName = 'CurrentUserContext';

const CurrentUserProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<User>();

  function fetchCurrentUser(): Promise<IUser> {
    return api.get('/current_user').then((res) => res.data);
  }

  const reloadUser = useCallback(() => {
    fetchCurrentUser().then((user) => {
      setCurrentUser(new User(user));
    });
  }, []);

  useEffect(() => {
    reloadUser();
  }, [reloadUser]);

  return (
    <CurrentUserContext.Provider
      value={{
        reloadUser,
        currentUser,
        isLoading: !currentUser,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

function useCurrentUser() {
  const context = React.useContext(CurrentUserContext);
  if (context === undefined) {
    throw new Error(`useCurrentUser must be used within a CurrentUserProvider`);
  }
  return context;
}

export { CurrentUserContext, CurrentUserProvider, useCurrentUser };
