/* eslint-disable */
import { ElementType } from 'react';
import { useParams } from 'react-router-dom';
import {
  ArrowTopRightOnSquareIcon,
  CalendarDaysIcon,
  ClipboardIcon,
  CurrencyDollarIcon,
  CursorArrowRaysIcon,
  EyeIcon,
  TagIcon,
} from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import { useCopyToClipboard, useInfiniteScroll } from '@/hooks';
import useCampaignOpportunities from '@/hooks/useAdNetwork/advertiser_access/useCampaignOpportunities';
import useCampaignReport from '@/hooks/useAdNetwork/advertiser_access/useCampaignReport';
import { AdNetworkCampaignReport } from '@/interfaces/ad_network/advertiser_access/campaign_report';
import { LoadingSpinner } from '@/components/LoadingSpinner';

interface Props {
  campaignReport: AdNetworkCampaignReport;
}

const formatter = new Intl.NumberFormat('en-US');

const KPICardBase = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="p-6 rounded-lg bg-white flex items-center border-2 border-gray-900  duration-300">
      <div className="space-y-3 w-full">{children}</div>
    </div>
  );
};

const KPICard = ({ value, label, Icon }: { value: string; label: string; Icon: ElementType }) => {
  return (
    <KPICardBase>
      <div className="flex">
        <div className="bg-primary-50 rounded-full flex p-4 text-primary-500">
          <Icon className="h-6 w-6" />
        </div>
      </div>
      <p className="text-feedback-info-600 font-black text-4xl truncate">{value}</p>
      <p className="font-bold truncate">{label}</p>
    </KPICardBase>
  );
};

const Heading = ({ children, as }: { children: string; as: 'h1' | 'h2' }) => {
  const Component = as;
  return <Component className="uppercase font-black text-4xl">{children}</Component>;
};

const Campaign = ({ campaignReport }: Props) => {
  const copy = useCopyToClipboard();

  const {
    data: data,
    isSuccess: isSuccess,
    fetchNextPage: fetchNextPage,
    hasNextPage: hasNextPage,
    isFetching: isFetching,
    isLoading: isLoading,
    isFetchingNextPage: isFetchingNextPage,
  } = useCampaignOpportunities({ campaignId: campaignReport.campaign.id, advertiserId: campaignReport.advertiser.id });

  const opportunities = data?.pages.flatMap((page) => page.opportunities);

  const infiniteScrollOptions = {
    fetchNextPage,
    hasNextPage: !!hasNextPage,
    isFetching,
    isLoading,
    isFetchingNextPage,
  }

  const { ref: lastElementRef } = useInfiniteScroll(infiniteScrollOptions);

  return (
    <div className="divide-y-2 divide-gray-900">
      <section className="py-12 px-6 space-y-6">
        <div className="space-y-6 max-w-6xl mx-auto flex justify-between items-center">
          <Heading as="h1">Ad Network Reporting</Heading>
          <div className="flex">
            <img
              src={campaignReport.advertiser.logo_url}
              className="border object-cover h-24 rounded"
              alt={campaignReport.advertiser.name}
            />
          </div>
        </div>
      </section>
      <section className="py-12 px-6">
        <div className="space-y-6 max-w-6xl mx-auto">
          <div>
            <Heading as="h2">Campaign Details</Heading>
            <p>A quick look at the campaign&apos;s key details and goals.</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <KPICardBase>
              <p>{campaignReport.advertiser.name}</p>
            </KPICardBase>
            <KPICardBase>
              <p className="font-bold">Campaign Name:</p>
              <p>{campaignReport.campaign.name}</p>
            </KPICardBase>
            <KPICardBase>
              <p className="font-bold">Campaign Dates:</p>
              <p>
                {moment(campaignReport.campaign.window_start_date).format('LL')} -{' '}
                {!campaignReport.campaign.window_end_date
                  ? 'TBD'
                  : moment(campaignReport.campaign.window_end_date).format('LL')}
              </p>
            </KPICardBase>
            <KPICard value={campaignReport.campaign.total_spend} label="Campaign Budget" Icon={TagIcon} />
            <KPICard value={campaignReport.campaign.cost_per_click} label="CPC" Icon={CurrencyDollarIcon} />
            <KPICard
              value={formatter.format(campaignReport.campaign.clicks_goal)}
              label="Guaranteed Unique Clicks"
              Icon={CursorArrowRaysIcon}
            />
          </div>
        </div>
      </section>
      <section className="py-12 px-6 bg-pink-200">
        <div className="space-y-6 max-w-6xl mx-auto">
          <div>
            <Heading as="h2">Performance Overview</Heading>
            <p>Current delivery data, updated in real time.</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="col-span-3 md:col-span-1">
              <KPICard
                value={formatter.format(campaignReport.performance.total_impressions)}
                label="Impressions"
                Icon={EyeIcon}
              />
            </div>
            <div className="col-span-3 md:col-span-1">
              <KPICard
                value={`${campaignReport.performance.average_click_through_rate}%`}
                label="Average CTR"
                Icon={CurrencyDollarIcon}
              />
            </div>
            <div className="col-span-3 md:col-span-1">
              <KPICard
                value={
                  campaignReport.campaign.window_end_date === null
                    ? 'TBD'
                    : formatter.format(
                        Math.max(moment(campaignReport.campaign.window_end_date).diff(moment(), 'days'), 0)
                      )
                }
                label="Days Remaining"
                Icon={CalendarDaysIcon}
              />
            </div>
            <div className="col-span-3">
              <KPICardBase>
                <div className="grid grid-cols-1 md:grid-cols-4 md:divide-x-2 divide-y-2 md:divide-y-0">
                  <div className="space-y-3 col-span-3 md:pr-4 pb-4 md:pb-0">
                    <div className="flex">
                      <div className="bg-primary-50 rounded-full flex p-4 text-primary-500">
                        <CursorArrowRaysIcon className="h-6 w-6" />
                      </div>
                    </div>
                    <p className="text-feedback-info-600 font-black text-4xl">
                      {formatter.format(campaignReport.performance.completed_clicks)}/
                      {formatter.format(campaignReport.campaign.clicks_goal)}
                    </p>
                    <div className="h-3 bg-feedback-info-100 rounded-full">
                      <div
                        className="bg-feedback-info-500 rounded-full h-full"
                        style={{
                          width: `${Math.min(
                            (campaignReport.performance.completed_clicks / campaignReport.campaign.clicks_goal) * 100,
                            100
                          )}%`,
                          minWidth: '0.75rem',
                        }}
                      >
                        &nbsp;
                      </div>
                    </div>
                    <p className="font-bold">Payable (unique) clicks</p>
                  </div>
                  <div className="space-y-3 col-span-1 md:pl-4 pt-4 md:pt-0 flex flex-col justify-center w-full ">
                    <div>
                      <p className="inline-flex justify-between w-full font-bold">
                        <span>Total Clicks</span>
                        <span>{formatter.format(campaignReport.performance.total_clicks)}</span>
                      </p>
                    </div>
                    <div>
                      <p className="inline-flex justify-between w-full font-bold">
                        <span>Unique Clicks</span>
                        <span>{formatter.format(campaignReport.performance.completed_clicks)}</span>
                      </p>
                    </div>
                    <div>
                      <p className="inline-flex justify-between w-full font-bold">
                        <span>Remaining Clicks</span>
                        <span>{formatter.format(campaignReport.performance.remaining_clicks)}</span>
                      </p>
                    </div>
                    <div>
                      <p className="inline-flex justify-between w-full font-bold">
                        <span>Avg. CTR</span>
                        <span>{campaignReport.performance.average_click_through_rate}%</span>
                      </p>
                    </div>
                  </div>
                </div>
              </KPICardBase>
            </div>
          </div>
        </div>
      </section>
      <section className="py-12 px-6">
        <div className="space-y-6 max-w-6xl mx-auto">
          <div>
            <Heading as="h2">Delivery Breakdown</Heading>
            <p>Deep dive into each placement.</p>
          </div>
          <div className="flex gap-6 overflow-auto">
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th className="text-lg font-bold whitespace-nowrap pr-6" align="left">
                    Publication
                  </th>
                  <th className="text-lg font-bold whitespace-nowrap pl-6" align="left">
                    Date
                  </th>
                  <th className="text-lg font-bold whitespace-nowrap pl-6" align="left">
                    Advertisement
                  </th>
                  <th className="text-lg font-bold whitespace-nowrap pl-6" align="right">
                    Impressions
                  </th>
                  <th className="text-lg font-bold whitespace-nowrap pl-6" align="right">
                    Total Clicks
                  </th>
                  <th className="text-lg font-bold whitespace-nowrap pl-6" align="right">
                    Unique Clicks
                  </th>
                  <th className="text-lg font-bold whitespace-nowrap pl-6" align="right">
                    CTR
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y-2">
                {opportunities?.map((opportunity) => (
                  <tr
                    key={opportunity.id}
                    ref={opportunities[opportunities.length - 1].id === opportunity.id ? lastElementRef : null}
                  >
                    <td
                      className="py-6 pr-6 truncate group cursor-pointer"
                      align="left"
                      onClick={() =>
                        copy({
                          text: opportunity.publication_id,
                          onSuccessText: `Copied ${opportunity.publication_id} to clipboard`,
                          onErrorText: 'Failed to copy to clipboard',
                        })
                      }
                    >
                      <p className="inline-flex space-x-2 items-center">
                        <span className="font-mono">{opportunity.publication_id}</span>
                        <span>
                          <ClipboardIcon className="h-4 w-4 text-gray-400 opacity-0 group-hover:opacity-100 duration-300" />
                        </span>
                      </p>
                    </td>
                    <td className="py-6 pl-6 truncate" align="left">
                      {moment(opportunity.date).format('LL')}
                    </td>
                    <td className="py-6 pl-6 truncate" align="left">
                      <a
                        href={opportunity.advertisement.url}
                        target="_blank"
                        rel="noreferrer"
                        className="text-feedback-info-500 hover:underline inline-flex items-center space-x-1"
                      >
                        <span>{opportunity.advertisement.name}</span>
                        <ArrowTopRightOnSquareIcon className="h-4 w-4 inline" />
                      </a>
                    </td>
                    <td className="py-6 pl-6 truncate" align="right">
                      {formatter.format(opportunity.total_impressions)}
                    </td>
                    <td className="py-6 pl-6 truncate" align="right">
                      {formatter.format(opportunity.total_eligible_clicked)}
                    </td>
                    <td className="py-6 pl-6 truncate" align="right">
                      {formatter.format(opportunity.total_unique_eligible_clicked)}
                    </td>
                    <td className="py-6 pl-6 truncate" align="right">
                      {opportunity.click_rate}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-center text-gray-400 text-sm">
            {(infiniteScrollOptions.isFetching || infiniteScrollOptions.isLoading || infiniteScrollOptions.isFetchingNextPage) ? (
              <>
                <LoadingSpinner className="mr-2" />
                <p>Loading reports...</p>
              </>
            ) : (
              <>
                {infiniteScrollOptions.hasNextPage && <p>Scroll down to load more reports</p>}
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default function Loader() {
  const { advertiser_id: advertiserId, campaign_id: campaignId } = useParams<{
    advertiser_id: string;
    campaign_id: string;
  }>() as { advertiser_id: string; campaign_id: string };

  const { data, isSuccess } = useCampaignReport({ campaignId, advertiserId });

  if (!isSuccess) return null;

  return (
    <Campaign
      campaignReport={data}
    />
  );
}
