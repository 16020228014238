/* eslint-disable */

import { FC, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import Modal from '@/components/Modal';
import cx from 'classnames';

// Interfaces
import { IData } from '@/interfaces/general';
import { Post } from '@/interfaces/post';
import { Platform, ScreenSize } from './types';
import Navbar from './Navbar';
import EmailInformation from './EmailInformation';
import BrowserBar from './BrowserBar';

interface TabProps {
  active: boolean;
}

const Tab = styled.div.attrs<TabProps>(({ active }) => ({
  className: `${
    active ? 'bg-white' : 'text-black hover:bg-gray-800'
  } w-11/12 py-4 my-2 ml-auto text-right pr-6 rounded-l font-medium cursor-pointer`,
}))<TabProps>``;

interface PreviewContainerProps {
  screenSize: ScreenSize;
}

const PreviewContainer = styled.iframe<PreviewContainerProps>`
  ${({ screenSize }) =>
    screenSize === 'mobile'
      ? 'width: 448px; height: 100%; margin: 0 auto;'
      : 'width: 100%; height: 100%;'}
`;

interface ScreenSizeButtonProps {
  active: boolean;
}

const ScreenSizeButton = styled.div.attrs<ScreenSizeButtonProps>(({ active }) => ({
  className: `px-4 py-2 cursor-pointer ${active ? 'text-primary-600 bg-gray-50' : 'text-gray-400 bg-gray-100'}`,
}))<ScreenSizeButtonProps>``;

interface Props {
  active: boolean;
  fetchPreview: (platform: string, advancedParams: IData, onFetch: (html: string) => void) => void;
  post: Post;
  tabs?: Platform[] | undefined;
  onClose: () => void;
  showSubscriberSelect: boolean;
}

const PreviewModalV2: FC<Props> = (props: Props) => {
  const { fetchPreview, post, active, onClose, tabs, showSubscriberSelect } = props;
  const previewContainer = useRef(null);

  const [activeTab, setActiveTab] = useState<Platform>(tabs && tabs.length > 0 ? tabs[0] : 'Web');

  const [emailPreview, setEmailPreview] = useState('');
  const [webPreview, setWebPreview] = useState('');

  const [screenSize, setScreenSize] = useState<ScreenSize>('desktop');
  const [currentSubscriberId, setCurrentSubscriberId] = useState('');
  const [currentSubscriberEmail, setCurrentSubscriberEmail] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (active && activeTab === 'Web') {
      if (window && window.hasOwnProperty('twttr')) {
        // @ts-ignore
        window.twttr.widgets.load(previewContainer.current);
      }
    }

    const functions: { [key: string]: (html: string) => void } = {
      email: (html: string) => setEmailPreview(html),
      web: (html: string) => setWebPreview(html),
    };

    const advancedParams = {
      current_subscriber_id: currentSubscriberId,
    };

    fetchPreview(activeTab.toLowerCase(), advancedParams, functions[activeTab.toLowerCase()]);
  }, [active, activeTab, fetchPreview, currentSubscriberId]);

  const handleClose = () => {
    setCurrentSubscriberId('');
    onClose();
  };

  const handleScreenSizeChange = (newSize: ScreenSize) => {
    if (screenSize !== newSize) {
      setScreenSize(newSize);
    }
  };

  const handleCurrentSubscriberSelect = (subscriberId: string, subscriberEmail: string) => {
    setCurrentSubscriberEmail(subscriberEmail);
    setCurrentSubscriberId(subscriberId);
  };

  const handleCurrentSubscriberClear = () => {
    setCurrentSubscriberEmail(undefined);
    setCurrentSubscriberId('');
  };

  const currentPreview = activeTab === 'Email' ? emailPreview : webPreview;

  return (
    <Modal isOpen={active} onClose={handleClose} includeCloseButton={false}>
      <div className="flex flex-col border rounded-lg h-[95vh] w-[95vw]" ref={previewContainer}>
        <Navbar
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          screenSize={screenSize}
          handleScreenSizeChange={handleScreenSizeChange}
          showSubscriberSelect={showSubscriberSelect}
          handleCurrentSubscriberSelect={handleCurrentSubscriberSelect}
          handleCurrentSubscriberClear={handleCurrentSubscriberClear}
          handleClose={handleClose}
        />
        <div className="h-full w-full rounded-r-md overflow-auto">
          <div className={cx(
            screenSize === 'desktop' ? 'max-w-7xl' : 'max-w-md',
            'h-[800px] m-auto flex flex-col shadow-xl rounded-xl border-b border-surface-200'
          )}>
            <BrowserBar screenSize={screenSize} />
            {
              activeTab === 'Email' && <EmailInformation post={post} screenSize={screenSize} to={currentSubscriberEmail}/>
            }
            <PreviewContainer srcDoc={currentPreview} screenSize={screenSize} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

PreviewModalV2.defaultProps = {
  tabs: ['Email'],
  showSubscriberSelect: false,
};

export default PreviewModalV2;
