import { useNavigate, useSearchParams } from 'react-router-dom';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

import { BoostsTopLineMetrics } from '@/components/BoostsTopLineMetrics';
import PageHeading from '@/components/Layout/PageLayout/PageHeading';
import { useSettings } from '@/context/settings-context';
import { useRequireSetting } from '@/hooks';
import useStripeConnectAccount, { StripeAccountStatus } from '@/hooks/boosts/monetize/useStripeConnectAccount';
import { useTutorial } from '@/hooks/useTutorials';
import { Setting } from '@/interfaces/setting';
import { TutorialType } from '@/interfaces/tutorial';
import { Button } from '@/ui/Button';

import ExplainMetrics from './components/ExplainMetrics';
import Tabs from './components/Tabs';

const BoostsOverview = () => {
  useRequireSetting({
    setting: Setting.BOOSTS,
    message: 'Upgrade to use boosts.',
    redirectTo: '/monetize/boosts/overview',
  });

  const { settings } = useSettings();
  const tutorial = useTutorial(TutorialType.BOOSTS_MONETIZE);
  const { data: stripeConnectAccount, isLoading: isStripeConnectAccountLoading } = useStripeConnectAccount();
  const navigate = useNavigate();
  const boostsAccountStatus = stripeConnectAccount?.boosts_account_status || StripeAccountStatus.INACTIVE;

  const [searchParams] = useSearchParams();

  if (settings && !settings.organization_boosts) {
    return <div className="text-bold">Boosts have been disabled for this account.</div>;
  }

  if (!isStripeConnectAccountLoading && boostsAccountStatus !== StripeAccountStatus.ACTIVE) {
    navigate('marketplace', {
      state: {
        throughBoostInviteEmail: !!searchParams.get('boost_invite_id'),
      },
    });
  }

  return (
    <div className="flex flex-col gap-y-8">
      <PageHeading
        title="Monetize with Boosts"
        description="Get paid to recommend your favorite beehiiv newsletters."
        tutorial={tutorial}
        cta={<ExplainMetrics />}
      >
        <Button Icon={MagnifyingGlassIcon} variant="primary" to="marketplace">
          Explore offers
        </Button>
      </PageHeading>
      <BoostsTopLineMetrics />
      <Tabs />
    </div>
  );
};

export default BoostsOverview;
