import { useCurrentPublicationState } from '../../../../../../context/current-publication-context';
import { Publication } from '../../../../../../interfaces/publication';
import { Section } from '../../../../Components';

import AddPublication from './AddPublication';
import Publications from './Publications';

interface Props {
  webTemplate: any;
  publications: Publication[];
  isLoading: boolean;
  isV2?: boolean;
}

const MultiPubSection = ({ webTemplate, publications, isLoading, isV2 }: Props) => {
  const [currentPublicationId] = useCurrentPublicationState();

  const multiPubIds = webTemplate?.multi_pub_ids || [];
  const selectedPubs = multiPubIds
    .map((id: string) => publications?.find((pub: Publication) => pub.id === id))
    .filter(Boolean);
  const unselectedPubs = publications?.filter((pub: Publication) => {
    return pub.id !== currentPublicationId && !multiPubIds.includes(pub.id);
  });

  if (isV2) {
    return (
      <div className="space-y-6">
        <Publications publications={selectedPubs} />
        <AddPublication webTemplate={webTemplate} publications={unselectedPubs} isLoadingPublications={isLoading} />
      </div>
    );
  }

  return (
    <Section
      title="Multi-Publications"
      description="Promote other publications from your organization on a dedicated page on your website."
      scrollToId="multi-pubs"
      isLoading={isLoading}
    >
      <div className="space-y-6">
        <Publications publications={selectedPubs} />
        <AddPublication webTemplate={webTemplate} publications={unselectedPubs} isLoadingPublications={isLoading} />
      </div>
    </Section>
  );
};

export default MultiPubSection;
