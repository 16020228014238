import toast from 'react-hot-toast';

import { useWebsiteContext } from '@/context/website-context';
import { useUpdateSiteVersion } from '@/hooks/useSiteVersion';

import { Switch } from '../../_components/UI/Switch';
import { Text } from '../../_components/UI/Text';
import SettingsCard from '../_components/SettingsCard';
import SettingsInput from '../_components/SettingsInput';
import SettingsItem from '../_components/SettingsItem';
import SettingsSection from '../_components/SettingsSection';

const WebsiteSettingsGDPR = () => {
  const { site } = useWebsiteContext();
  const createSiteVersion = useUpdateSiteVersion({
    id: site?.draft_site_version?.id || '',
    publishOnUpdate: true,
    onSuccess: () => {
      toast.success('Saved!');
    },
  });

  const tos = site?.draft_site_version?.settings?.terms_of_service || '';

  return (
    <div>
      <SettingsSection hasDivider={false}>
        <SettingsItem
          title="Cookie Banner"
          description="Enable this to show a cookie banner when someone first visits your publication site to allow them to opt out of certain cookies."
        >
          <Switch
            id="cookie_banner_enabled"
            labelText="Show Cookie Banner"
            checked={site?.draft_site_version?.settings?.cookie_banner_enabled || false}
            onCheckedChange={(checked: boolean) =>
              createSiteVersion.mutate({
                settings: {
                  cookie_banner_enabled: checked,
                },
              })
            }
          />
        </SettingsItem>
      </SettingsSection>

      <SettingsSection>
        <SettingsItem
          title="Signup ToS & Privacy Policy"
          description="Enable this to display your terms of service & privacy policy upon signup. Subscribers must check this box to signup."
        >
          <Switch
            id="terms_of_service_enabled"
            labelText="Show Cookie Banner"
            checked={site?.draft_site_version?.settings?.terms_of_service_enabled || false}
            onCheckedChange={(checked: boolean) =>
              createSiteVersion.mutate({
                settings: {
                  terms_of_service_enabled: checked,
                },
              })
            }
          />
        </SettingsItem>

        <SettingsCard className="w-full max-w-xl my-4">
          <div className="flex flex-col">
            <Text size="xs" weight="semibold" variant="primary" as="span">
              Both of these must be toggled on for your publication to be GDPR & CCPA compliant.
            </Text>
            <Text size="xs" weight="medium" variant="secondary" as="span">
              Deletions and exports are handled at the platform level, make sure users email:{' '}
              <a
                href="mailto:privacy@beehiiv.com"
                target="_blank"
                rel="noreferrer"
                className="text-accent-500 underline"
              >
                privacy@beehiiv.com
              </a>
              .
            </Text>
          </div>
        </SettingsCard>
      </SettingsSection>

      <SettingsSection hasDivider={false}>
        <SettingsItem
          title="Subscriber Signup Terms of Service"
          description="Add and customize your publication‘s signup terms of service for your readers."
        >
          <SettingsInput
            className="max-w-sm"
            name="subscriber_signup_tos"
            labelText="Terms of Service"
            type="textarea"
            placeholder="These Terms of Service (“Terms”) govern your access to and use of [Service Name] and any related services provided by [Company Name]..."
            value={tos}
            helperText="Please note, this is accessible via the ToS page on your publication site if the cookie banner is enabled."
            onSave={(value: string) => {
              if (value === tos) return;
              createSiteVersion.mutate({
                settings: {
                  terms_of_service: value,
                },
              });
            }}
          />
        </SettingsItem>
      </SettingsSection>
    </div>
  );
};

export default WebsiteSettingsGDPR;
