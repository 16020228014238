import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';
import styled from 'styled-components';

import ActionModal from "@/components/ActionModal";
import { usePostDeliveryData } from '@/hooks/usePostDeliveryData';
import { Post, PostStatus } from '@/interfaces/post';
import api from '@/services/swarm';
import analytics from '@/utils/analytics';

import MainNav from '../AppLayout/MainNav';

import { PostEditorSteps } from './constants';
import TopBar from './TopBar';

const NavFiller: any = styled.div`
  width: '60px';
  min-width: ${({ largeNavOpen }: any) => (largeNavOpen ? '230px' : '60px')};
  height: 100vh;

  @media (max-width: 640px) {
    width: 0px;
    min-width: 0px;
    display: ${({ isSettingsOpen }: any) => (isSettingsOpen ? 'none' : 'none')};
  }

  @media print {
    display: none;
  }
`;

interface Props {
  currentPostEditorStep: PostEditorSteps;
  setPostEditorStep: Dispatch<SetStateAction<PostEditorSteps>>;
  setPreviewActive: Dispatch<SetStateAction<boolean>>;
  setPreviewOptions: Dispatch<SetStateAction<('Email' | 'Web')[]>>;
  children: React.ReactNode;
  isV2?: boolean;
  post: Post
}

const defaultStepsOrder = [
  PostEditorSteps.COMPOSE,
  PostEditorSteps.AUDIENCE,
  PostEditorSteps.EMAIL,
  PostEditorSteps.WEB,
  PostEditorSteps.REVIEW,
];

const PostEditorLayout = ({ currentPostEditorStep, setPostEditorStep, setPreviewActive, setPreviewOptions, children, isV2, post }: Props) => {
  const [showUnpublishModal, setShowUnpublishModal] = useState(false);
  const { data: postDeliveryData } = usePostDeliveryData(post?.id || "");
  const navigate = useNavigate();
  const [stepsOrder, setStepsOrder] = useState(defaultStepsOrder);

  useEffect(() => {
    if (postDeliveryData) {
      if (postDeliveryData.for_email && postDeliveryData.for_web) {
        setPreviewOptions(['Web', 'Email']);
        setStepsOrder(
          [
            PostEditorSteps.COMPOSE,
            PostEditorSteps.AUDIENCE,
            PostEditorSteps.EMAIL,
            PostEditorSteps.WEB,
            PostEditorSteps.REVIEW,
          ]
        )
      } else if (postDeliveryData.for_email) {
        setPreviewOptions(['Email']);
        setStepsOrder(
          [
            PostEditorSteps.COMPOSE,
            PostEditorSteps.AUDIENCE,
            PostEditorSteps.EMAIL,
            PostEditorSteps.REVIEW,
          ]
        )
      } else if (postDeliveryData.for_web) {
        setPreviewOptions(['Web']);
        setStepsOrder(
          [
            PostEditorSteps.COMPOSE,
            PostEditorSteps.AUDIENCE,
            PostEditorSteps.WEB,
            PostEditorSteps.REVIEW,
          ]
        )
      }
    }
  }, [postDeliveryData, setPreviewOptions]);

  if (post === undefined) {
    return null;
  }

  const {status: postStatus} = post;

  return (
    <div className={cx(`h-full flex flex-col min-h-screen relative bg-gray-100`, { v2: isV2 })}>
      <ActionModal
        modalMessageType="danger"
        buttonType="danger"
        buttonShade="dark"
        actionText="Unschedule"
        isOpen={showUnpublishModal}
        onClose={() => setShowUnpublishModal(false)}
        onProceed={() => {
          api
            .patch(`/posts/${post.id}/transition`, { status: PostStatus.DRAFT })
            .then(() => {
              analytics.track('Unscheduled Post');
              toast.success('Post successfully unscheduled');
              navigate(`/posts/${post.id}`)
            });
        }}
        descriptionText="Unscheduling will revert this post to a draft state. It won’t be sent via email or accessible on web. You’ll need to publish or reschedule it when ready."
        headerText="Unschedule Post"
        isWorking={false}
        disabled={false}
        resourceId="123"
      >
        <p />
      </ActionModal>
      <MainNav largeNavOpen={false} isAdmin={false} />
      <div className="flex max-w-screen">
        <NavFiller />
        <div className="w-full overflow-auto h-screen max-h-screen relative bg-white">
          <div className="sticky sm:block hidden top-0 z-50 bg-white">
            <TopBar
              currentPostEditorStep={currentPostEditorStep}
              setPostEditorStep={setPostEditorStep}
              setShowUnpublishModal={setShowUnpublishModal}
              setPreviewActive={setPreviewActive}
              postStatus={postStatus}
              stepsOrder={stepsOrder}
              post={post}
            />
          </div>
          <div className="py-8">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default PostEditorLayout;
