import { TopLineMetrics } from '../top_line_metrics';

import { BasicBoostSend } from './basic_boost_send';
import { BoostAgreementStatus } from './boost_agreement_status';
import { BoostOffer } from './boost_offer';

// Defines possible monetize user actions on a BoostAgreement for each status.
export const BoostAgreementStatusTransitions: any = {
  [BoostAgreementStatus.PENDING]: [BoostAgreementStatus.ARCHIVED],
  [BoostAgreementStatus.REJECTED]: [BoostAgreementStatus.ARCHIVED],
  [BoostAgreementStatus.ACTIVE]: [BoostAgreementStatus.INACTIVE, BoostAgreementStatus.ARCHIVED],
  [BoostAgreementStatus.INACTIVE]: [BoostAgreementStatus.ACTIVE, BoostAgreementStatus.ARCHIVED],
  [BoostAgreementStatus.PAUSED]: [BoostAgreementStatus.ARCHIVED],
  [BoostAgreementStatus.ARCHIVED]: [],
};

// Defines the labels for each user action on a BoostAgreement for each status.
export const BoostAgreementTransitionLabels: any = {
  [BoostAgreementStatus.ACTIVE]: 'Enable',
  [BoostAgreementStatus.INACTIVE]: 'Disable',
  [BoostAgreementStatus.ARCHIVED]: 'Archive',
};

export const BoostAgreementTransitionVerboseLabels: any = {
  [BoostAgreementStatus.ACTIVE]: 'Enable Web Boost',
  [BoostAgreementStatus.INACTIVE]: 'Disable Web Boost',
  [BoostAgreementStatus.ARCHIVED]: 'Archive Web Boost',
};

/**
 * @see `app/views/api/v2/boosts/monetize/_boost_agreement.json.jbuilder`
 */
export interface BoostAgreement {
  id: string;
  status: BoostAgreementStatus;
  boost_send_is_not_expired: boolean;
  boost_offer: BoostOffer;
  boost_sends: BasicBoostSend[];
  calc_max_payout: string;
  calc_max_payout_cents: number;
  all_time_referral_count: number;
  all_time_earnings_amount: number;
  top_line_metrics: TopLineMetrics;
  created_at: string;
  updated_at: string;
  is_reported: boolean;
}

export { BoostAgreementStatus };
