import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { PageRoute } from '@/interfaces/dream_builder/page_route';

import { ContentTreeItem } from './ContentTreeItem';
import { ContentTreeItemHome } from './ContentTreeItemHome';
import { ContentTreeProvider } from './context';

type Props = {
  route: PageRoute;
  slug: string;
  parentPath: string[];
  showHome?: boolean;
  allowDnD?: boolean;
};

export const ContentTree = ({ route, parentPath, slug, showHome = true, allowDnD = true }: Props) => {
  const content = (
    <ContentTreeProvider value={allowDnD}>
      <div className="flex flex-col">
        {showHome && <ContentTreeItemHome route={route} parentPath={parentPath} />}

        {route?.children &&
          route?.children_keys &&
          route.children_keys?.map(
            (childSlug) =>
              route.children?.[childSlug] && (
                <ContentTreeItem
                  key={route.children[childSlug]?.page_id}
                  route={route.children[childSlug]}
                  parentPath={slug ? [...parentPath, slug] : [...parentPath]}
                  slug={childSlug}
                />
              )
          )}
      </div>
    </ContentTreeProvider>
  );

  if (allowDnD) {
    return <DndProvider backend={HTML5Backend}>{content}</DndProvider>;
  }

  return content;
};
