import { Link, useSearchParams } from 'react-router-dom';
import { ArrowLeft, CaretRight } from '@phosphor-icons/react';

import { useWebsitePageRouteGetter } from '@/context/website-context';
import { useDraftPageVersion } from '@/hooks/usePageVersions';

import { Button } from '../UI/Button';
import { Text } from '../UI/Text';


const Crumb = ({ urlPath, lastItem }: { urlPath: string, lastItem: boolean }) => {

  const pageRouteGetter = useWebsitePageRouteGetter();
  const page = pageRouteGetter?.getPageFromPath(urlPath);
  const { data: draftPageVersion } = useDraftPageVersion(page?.id || '');
  return <>
    <CaretRight size={12} className="text-wb-secondary" weight="bold" />
    <Link
      key={urlPath}
      to={
        urlPath?.length > 0
          ? `/website_builder_v2?path=${encodeURIComponent(urlPath)}`
          : `/website_builder_v2`
      }
      className="leading-none text-ellipsis overflow-hidden"
    >
      <Button size="sm" variant="ghost">
        <Text
          size="xs"
          weight="medium"
          as="p"
          className="truncate"
          variant={lastItem ? 'primary' : 'secondary'}
        >
          {draftPageVersion?.name}
        </Text>
      </Button>
    </Link>
  </>
}

export const Breadcrumbs = () => {
  const [searchParams] = useSearchParams();
  const path = searchParams.get('path')?.split('/');

  const pagePaths = path?.map((_, index) => {
    const urlPath = path?.slice(0, index + 1).join('/');
    return urlPath
  });

  const backPath = path?.slice(0, -1).join('/');

  if ((path?.length || 0) > 0) {
    return (
      <div className="w-full flex items-center gap-4">
        <Link to={backPath ? `/website_builder_v2?path=${encodeURIComponent(backPath)}` : '/website_builder_v2'}>
          <Button variant="outlined" size="sm" LeftIcon={ArrowLeft}>
            Back
          </Button>
        </Link>
        <div className="flex items-center gap-1">
          <Link to="/website_builder_v2" className="leading-none text-ellipsis overflow-hidden">
            <Button size="sm" variant="ghost">
              <Text size="xs" weight="medium" variant="secondary">
                Home
              </Text>
            </Button>
          </Link>
          {pagePaths?.map((urlPath, index) => {
            return (
              <Crumb key={urlPath} urlPath={urlPath} lastItem={index === pagePaths.length - 1} />
            );
          })}
        </div>
      </div>
    );
  }
  return null;
};
