import { IconComponent } from '@heroicons/react';
import { CheckCircleIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

import { SubscriberPreferenceType } from '@/interfaces/subscribers_preferences';

import BooleanTypeIcon from './_icons/BooleanTypeIcon';
import TextTypeIcon from './_icons/TextTypeIcon';

export const LABEL_BY_SUBSCRIBER_PREFERENCE_TYPE: Record<SubscriberPreferenceType, string> = {
  boolean: 'True / False',
  dropdown: 'Dropdown Menu',
  multiple_choice: 'Multiple Choice',
  text: 'Text Input',
};

export const ICON_BY_SUBSCRIBER_PREFERENCE_TYPE: Record<SubscriberPreferenceType, IconComponent> = {
  boolean: BooleanTypeIcon,
  dropdown: ChevronDownIcon,
  multiple_choice: CheckCircleIcon,
  text: TextTypeIcon,
};
