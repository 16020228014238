import { FC, Fragment, PropsWithChildren, ReactNode, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { Button, ButtonProps } from '@/ui/Button';

const RoundedIcon = ({ bgColor, Icon }: { bgColor: string; Icon: React.ReactNode }) => (
  <div className={`rounded-full ${bgColor} p-[10px] relative flex items-center justify-center`}>{Icon}</div>
);

const InfoIcon = () => (
  <div>
    <RoundedIcon
      bgColor="bg-indigo-50"
      Icon={<InformationCircleIcon color="#4746E5" className="h-6 w-6 rounded-full" />}
    />
  </div>
);

const WarningIcon = () => (
  <div>
    <RoundedIcon
      bgColor="bg-amber-50"
      Icon={<ExclamationTriangleIcon color="#D97706" className="h-6 w-6 rounded-full" />}
    />
  </div>
);

const DangerIcon = () => (
  <div>
    <RoundedIcon
      bgColor="bg-red-50"
      Icon={<ExclamationCircleIcon color="#B91C1C" className="h-6 w-6 rounded-full" />}
    />
  </div>
);

type ModalMessageType = 'info' | 'warning' | 'danger';

const ModalIcon = ({ modalMessageType }: { modalMessageType: ModalMessageType }) => {
  switch (modalMessageType) {
    case 'info':
      return <InfoIcon />;
    case 'warning':
      return <WarningIcon />;
    case 'danger':
      return <DangerIcon />;
    default:
      return null;
  }
};

export type ModalSizeTypes = 'sm' | 'md' | 'lg' | 'xl' | 'full-screen';

export type Props = PropsWithChildren<{
  isOpen: boolean;
  isWorking: boolean;
  onClose: () => void;
  onProceed?: (id: string) => void;
  modalMessageType?: ModalMessageType;
  resourceId: string;
  headerText?: string;
  descriptionText?: string;
  actionText?: string;
  cancelText?: string;
  hideCancelButton?: boolean;
  modalSize?: ModalSizeTypes;
  modalHeightClassName?: string;
  bodyId?: string;
  disabled?: boolean;
  overflow?: 'visible' | 'hidden';
  buttonType?: ButtonProps['variant'];
  buttonShade?: ButtonProps['shade'];
  footer?: ReactNode;
  padding?: string;
}>;

const ActionModal: FC<Props> = (props: Props) => {
  const {
    isOpen,
    isWorking,
    onProceed,
    onClose,
    modalMessageType,
    resourceId,
    headerText,
    descriptionText,
    children,
    bodyId,
    footer,
    actionText = 'Confirm',
    cancelText = 'Cancel',
    hideCancelButton = false,
    modalSize = 'sm',
    modalHeightClassName,
    disabled = false,
    overflow,
    buttonType = 'primary',
    buttonShade = 'default',
    padding = 'p-6',
  } = props;
  const cancelRef = useRef(null);
  const sizeClasses = () => {
    switch (modalSize) {
      case 'sm':
        return 'max-w-lg';
      case 'md':
        return 'md:max-w-xl';
      case 'lg':
        return 'md:max-w-xl lg:max-w-2xl';
      case 'xl':
        return 'md:max-w-2xl lg:max-w-3xl xl:max-w-4xl';
      case 'full-screen':
        return 'w-full';
      default:
        return '';
    }
  };

  const overflowClass = () => {
    if (modalHeightClassName) {
      return 'overflow-y-auto';
    }

    if (overflow === 'visible') {
      return 'overflow-visible';
    }

    return '';
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed overflow-y-auto inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-20" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <section className="modal z-50 fixed inset-0 w-screen overflow-auto">
            <div className={`flex min-h-full justify-center p-4 ${modalSize !== 'full-screen' ? `items-center` : ''}`}>
              <Dialog.Panel
                className={cx(
                  'model-guts flex flex-col mx-auto bg-white rounded-lg text-left shadow-xl w-full',
                  sizeClasses()
                )}
                id={bodyId}
              >
                <section className={cx(padding, overflowClass())}>
                  <div className={cx('flex flex-col sm:flex-row gap-4', modalHeightClassName)}>
                    <div className="w-full flex flex-col">
                      <div className="w-full flex flex-row space-x-4">
                        {modalMessageType && <ModalIcon modalMessageType={modalMessageType} />}
                        <div className="flex flex-col">
                          {headerText && <p className="mb-1 text-lg font-semibold">{headerText}</p>}
                          {descriptionText && <p className="text-sm text-[#374151]">{descriptionText}</p>}
                        </div>
                      </div>
                      {children ? <div>{children}</div> : null}
                    </div>
                  </div>
                </section>
                <div className="flex justify-between items-center bg-[#F9FAFB] rounded-b-lg py-4 px-6 mt-auto">
                  <div>{footer}</div>
                  <div className="flex items-center gap-3 ml-auto">
                    {!hideCancelButton && (
                      <Button ref={cancelRef} type="button" variant="primary-inverse" onClick={onClose}>
                        {cancelText}
                      </Button>
                    )}
                    {onProceed && (
                      <Button
                        variant={buttonType}
                        shade={buttonShade}
                        type="button"
                        disabled={disabled}
                        loading={isWorking}
                        onClick={() => onProceed(resourceId)}
                      >
                        {actionText}
                      </Button>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </section>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default ActionModal;
