import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import LargeIconRadioGroup, { LargeIconRadioOption } from '@/components/Form/LargeIconRadioGroup';
import LargeItemRadioGroup, { LargeItemRadioOption } from '@/components/Form/LargeItemRadioGroup';
import { useCurrentUser } from '@/context/current-user-context';
import { useCurrentPublication, useOnboarding } from '@/hooks';
import api from '@/services/swarm';
import analytics from '@/utils/analytics';

import { Layout, LeftPane, RightPane } from '../_components/Layout';
import OnboardingStepForm from '../_components/OnboardingStepForm';

import {
  BUSINESS_TYPE_OPTIONS,
  ONBOARDING_QUESTIONS,
  ORGANIZATION_SIZE_OPTIONS,
  PRIMARY_GOAL_OPTIONS,
  PUBLICATION_RATE_OPTIONS,
} from './_constants';

const OnboardingBusinessQuestionnaire = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { currentUser } = useCurrentUser();
  const { data: currentPublication } = useCurrentPublication();
  const { data: onboarding } = useOnboarding(currentPublication?.onboarding_id);
  const localPlan = localStorage.getItem('selectedPlan');
  const isEnterprise = localStorage.getItem('isEnterprisePlan') === 'true';

  const [selectedOption, setSelectedOption] = useState<LargeIconRadioOption | LargeItemRadioOption | null>(null);
  const [isRequesting, setIsRequesting] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [businessType, setBusinessType] = useState<LargeItemRadioOption | null>(null);
  const [organizationSize, setOrganizationSize] = useState<LargeItemRadioOption | null>(null);
  const [publicationRate, setPublicationRate] = useState<LargeItemRadioOption | null>(null);
  const [primaryGoal, setPrimaryGoal] = useState<LargeItemRadioOption | null>(null);

  useEffect(() => {
    if (onboarding) {
      setBusinessType(BUSINESS_TYPE_OPTIONS.find((option) => option.value === onboarding?.business_type) || null);
      setOrganizationSize(
        ORGANIZATION_SIZE_OPTIONS.find((option) => option.value === onboarding?.organization_size) || null
      );
      setPublicationRate(
        PUBLICATION_RATE_OPTIONS.find((option) => option.value === onboarding?.publication_rate) || null
      );
      setPrimaryGoal(PRIMARY_GOAL_OPTIONS.find((option) => option.value === onboarding?.primary_goal) || null);
    }
  }, [onboarding]);

  const startMaxTrial = () => {
    setIsRequesting(true);
    api
      .patch(`/onboarding/${currentPublication?.onboarding_id}`, {
        status: 'complete',
        publication_id: currentPublication?.id,
      })
      .then(() => {
        if (currentUser?.id) {
          analytics.identify(currentUser?.id, {
            email: currentUser?.email,
            state: 'trialing',
            current_plan: 'launch',
          });
        }
        analytics.track('Completed Onboarding', { email: currentUser?.email });
        analytics.track('Trial Started', {
          state: 'trialing',
          current_plan: 'launch',
          trial_plan: 'max',
        });
      })
      .then(() => {
        api.post(`/organizations/${currentPublication?.organization_id}/trial`, {
          selected_plan: 'max',
        });
      })
      .finally(async () => {
        await queryClient.invalidateQueries(['publications', currentPublication?.id]);
        navigate('/');
      });
  };

  const trackBusinessTypeEvents = () => {
    analytics.identify(currentUser?.id, {
      publisherType: selectedOption?.value,
    });
    analytics.group({
      userId: currentUser?.id,
      groupId: currentPublication?.organization_id,
      objectTypeId: 3,
      traits: {
        publisherType: selectedOption?.value,
      },
    });
    analytics.track('Selected Publisher Type', {
      publisherType: selectedOption?.value,
    });
  };

  const trackOrganizationSizeEvents = () => {
    analytics.group({
      userId: currentUser?.id,
      groupId: currentPublication?.organization_id,
      objectTypeId: 3,
      traits: {
        orgSize: selectedOption?.value,
      },
    });
    analytics.track('Selected Organization Size', {
      orgSize: selectedOption?.value,
    });
  };

  const trackPublicationRateEvents = () => {
    analytics.identify(currentUser?.id, {
      publishingFrequency: selectedOption?.value,
    });
    analytics.track('Selected Publishing Frequency', {
      publishingFrequency: selectedOption?.value,
    });
    analytics.group({
      userId: currentUser?.id,
      groupId: currentPublication?.id,
      objectTypeId: 1,
      traits: {
        publishingFrequency: selectedOption?.value,
      },
    });
  };

  const trackPrimaryGoalEvents = () => {
    analytics.identify(currentUser?.id, {
      goal: selectedOption?.value,
    });
    analytics.track('Selected Primary Goal', {
      goal: selectedOption?.value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsRequesting(true);
    const currentQuestion = ONBOARDING_QUESTIONS[currentQuestionIndex];
    const dataToUpdate = {
      [currentQuestion.onboarding_status]: selectedOption?.value,
      publication_id: currentPublication?.id,
    };

    api
      .patch(`/onboarding/${currentPublication?.onboarding_id}`, dataToUpdate)
      .then(() => {
        // Update the corresponding state based on the current question
        switch (currentQuestion.onboarding_status) {
          case 'business_type':
            setBusinessType(selectedOption);
            if (selectedOption?.value) {
              trackBusinessTypeEvents();
            }
            break;
          case 'organization_size':
            setOrganizationSize(selectedOption);
            if (selectedOption?.value) {
              trackOrganizationSizeEvents();
            }
            break;
          case 'publication_rate':
            setPublicationRate(selectedOption);
            if (selectedOption?.value) {
              trackPublicationRateEvents();
            }
            break;
          case 'primary_goal':
            setPrimaryGoal(selectedOption);
            if (selectedOption?.value) {
              trackPrimaryGoalEvents();
            }
            break;
          default:
            break;
        }

        let nextQuestionIndex = -1;
        if (typeof currentQuestion.next_question === 'number') {
          nextQuestionIndex = currentQuestion.next_question;
        } else if (typeof currentQuestion.next_question === 'object' && selectedOption?.value) {
          nextQuestionIndex = currentQuestion.next_question[selectedOption.value] ?? -1;
        }

        if (nextQuestionIndex !== -1) {
          setCurrentQuestionIndex(nextQuestionIndex);
          // Set selected option to the corresponding state value
          switch (ONBOARDING_QUESTIONS[nextQuestionIndex].onboarding_status) {
            case 'business_type':
              setSelectedOption(businessType);
              break;
            case 'organization_size':
              setSelectedOption(organizationSize);
              break;
            case 'publication_rate':
              setSelectedOption(publicationRate);
              break;
            case 'primary_goal':
              setSelectedOption(primaryGoal);
              break;
            default:
              setSelectedOption(null);
              break;
          }
          return null;
        }

        if (isEnterprise) {
          return startMaxTrial();
        }

        return localPlan === 'launch' ? navigate('/onboarding/next_steps') : navigate('/onboarding/trial_features');
        // return navigate('/onboarding/next_steps');
      })
      .finally(() => {
        setIsRequesting(false);
      });
  };

  const onBack = () => {
    const currentQuestion = ONBOARDING_QUESTIONS[currentQuestionIndex];
    const previousQuestionIndex = currentQuestion.previous_question ?? -1;

    if (typeof previousQuestionIndex === 'string') {
      return navigate(previousQuestionIndex);
    }

    if (previousQuestionIndex !== -1) {
      setCurrentQuestionIndex(previousQuestionIndex);
      // Set selected option to the corresponding state value
      switch (ONBOARDING_QUESTIONS[previousQuestionIndex].onboarding_status) {
        case 'business_type':
          setSelectedOption(businessType);
          break;
        case 'organization_size':
          setSelectedOption(organizationSize);
          break;
        case 'publication_rate':
          setSelectedOption(publicationRate);
          break;
        case 'primary_goal':
          setSelectedOption(primaryGoal);
          break;
        default:
          setSelectedOption(null);
          break;
      }
    }
    return null;
  };

  return (
    <>
      <Helmet>
        <title>Tell us more about you - beehiiv</title>
      </Helmet>

      <Layout>
        <LeftPane>
          <OnboardingStepForm
            title={ONBOARDING_QUESTIONS[currentQuestionIndex].title}
            subtitle={ONBOARDING_QUESTIONS[currentQuestionIndex].subtitle}
            onSubmit={handleSubmit}
            onBack={onBack}
            onSkip={() =>
              localPlan === 'launch' ? navigate('/onboarding/next_steps') : navigate('/onboarding/trial_features')
            }
            isProcessing={isRequesting}
            isSubmitDisabled={!selectedOption}
          >
            <div className="mt-4">
              {Object.keys(ONBOARDING_QUESTIONS[currentQuestionIndex].options[0]).includes('Icon') ? (
                <LargeIconRadioGroup
                  options={ONBOARDING_QUESTIONS[currentQuestionIndex].options as LargeIconRadioOption[]}
                  selected={selectedOption as LargeIconRadioOption}
                  onSelected={setSelectedOption}
                />
              ) : (
                <LargeItemRadioGroup
                  options={ONBOARDING_QUESTIONS[currentQuestionIndex].options as LargeItemRadioOption[]}
                  selected={selectedOption as LargeItemRadioOption}
                  onSelected={setSelectedOption}
                />
              )}
            </div>
          </OnboardingStepForm>
        </LeftPane>
        <RightPane />
      </Layout>
    </>
  );
};

export default OnboardingBusinessQuestionnaire;
