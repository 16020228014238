import Flatpickr from 'react-flatpickr';
import Tippy from '@tippyjs/react';
import cx from 'classnames';

import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { Asset } from '@/interfaces/asset';
import { Author } from '@/interfaces/author';
import { ContentTag } from '@/interfaces/content_tag';
import { Post } from '@/interfaces/post';
import AuthorsActionsMenu from '@/pages/Post/Edit/v2/Compose/AuthorsMenu/AuthorsActionsMenu';

import AddContentTagMenu from '../ContentTags/AddContentTagMenu';
import ThumbnailActionsMenu from '../ThumbnailActionsMenu';

type UpdatePostParams = Post & {
  thumbnail_id?: string | null;
  thumbnail?: Asset | null | undefined;
};

interface Props {
  post: Post;
  updateDisplayDate: (date: Date | null) => void;
  toggleSubtitle: () => void;
  guestAuthors: Author[];
  users: Author[];
  updatePost: (data: UpdatePostParams) => void;
  show?: boolean;
  showAuthorsButton?: boolean;
  setShowCreateGuestAuthorModal: (value: boolean) => void;
  setShowImageLibrary: (value: boolean) => void;
  fileRef: React.RefObject<HTMLInputElement>;
  setShowCreateContentTagModal: (value: boolean) => void;
  contentTags?: ContentTag[];
  refetchContentTags(): void;
}

const TitleContentMenu = ({
  guestAuthors,
  users,
  updatePost,
  updateDisplayDate,
  toggleSubtitle,
  post,
  show,
  showAuthorsButton,
  setShowCreateGuestAuthorModal,
  fileRef,
  setShowImageLibrary,
  contentTags,
  setShowCreateContentTagModal,
  refetchContentTags,
}: Props) => {
  return (
    <div
      className={cx(
        'transition ease-in duration-100 flex flex-wrap lg:flex-nowrap mb-6 -ml-8 min-w-[calc(100%+2rem)]',
        { 'opacity-0': !show },
        { 'opacity-100': show }
      )}
    >
      <Tippy
        offset={[0, 8]}
        placement="top"
        popperOptions={{
          modifiers: [{ name: 'flip' }],
        }}
        trigger="click"
        interactive
        content={
          <div className="absolute z-50">
            <ThumbnailActionsMenu
              post={post}
              fileRef={fileRef}
              setShowImageLibrary={setShowImageLibrary}
              updatePost={updatePost}
            />
          </div>
        }
      >
        <button
          type="button"
          className="flex min-w-[140px] flex-nowrap mb-3 md:mb-0 ml-6 text-sm text-gray-400 hover:text-black transition-colors hover:bg-gray-100 rounded-lg px-2 pt-[.4rem]"
        >
          <span className="block mt-[.1rem]">
            <Icon name="Image" />
          </span>
          <span className="block ml-2 font-medium">Add thumbnail</span>
        </button>
      </Tippy>
      {(!post?.web_subtitle || post?.web_subtitle?.length === 0) && (
        <button
          type="button"
          className="flex min-w-[122px] flex-nowrap mb-3 md:mb-0 ml-6 text-sm text-gray-400 hover:text-black transition-colors hover:bg-gray-100 rounded-lg px-2 pt-[.4rem]"
          onClick={toggleSubtitle}
        >
          <span className="block mt-[.1rem]">
            <Icon name="AIShorten" />
          </span>
          <span className="block ml-2 font-medium">Add subtitle</span>
        </button>
      )}
      {(!post?.content_tag_ids || post?.content_tag_ids?.length === 0) && (
        <Tippy
          offset={[0, 8]}
          placement="top"
          popperOptions={{
            modifiers: [{ name: 'flip' }],
          }}
          trigger="click"
          interactive
          content={
            <div className="absolute z-50">
              <AddContentTagMenu
                contentTags={contentTags || []}
                post={post}
                updatePost={updatePost}
                setShowCreateContentTagModal={setShowCreateContentTagModal}
                refetchContentTags={refetchContentTags}
              />
            </div>
          }
        >
          <Tippy
            offset={[0, 8]}
            placement="top"
            popperOptions={{
              modifiers: [{ name: 'flip' }],
            }}
            trigger="mouseenter"
            interactive
            content={
              <div className="bg-white shadow-lg px-2 py-1 rounded-lg border border-gray-200 text-sm min-w-[8rem] text-center text-gray-500">
                Displayed only on the post card currently
              </div>
            }
          >
            <button
              type="button"
              className="flex min-w-[155px] flex-nowrap mb-3 md:mb-0 ml-6 text-sm text-gray-400 hover:text-black transition-colors hover:bg-gray-100 rounded-lg px-2 pt-[.4rem]"
            >
              <span className="block mt-[.1rem]">
                <Icon name="Tag" />
              </span>
              <span className="block ml-2 font-medium">Add content tags</span>
            </button>
          </Tippy>
        </Tippy>
      )}
      {showAuthorsButton && (
        <Tippy
          offset={[0, 8]}
          placement="top"
          popperOptions={{
            modifiers: [{ name: 'flip' }],
          }}
          trigger="click"
          interactive
          content={
            <div className="absolute z-50">
              <AuthorsActionsMenu
                guestAuthors={guestAuthors}
                users={users}
                post={post}
                updatePost={updatePost}
                smallVersion
                setShowCreateGuestAuthorModal={setShowCreateGuestAuthorModal}
              />
            </div>
          }
        >
          <button
            type="button"
            className="flex min-w-[125px] flex-nowrap mb-3 md:mb-0 ml-6 text-sm text-gray-400 hover:text-black transition-colors hover:bg-gray-100 rounded-lg px-2 py-[.4rem]"
          >
            <span className="block mt-[.1rem]">
              <Icon name="Image" />
            </span>
            <span className="block ml-2 font-medium">Add authors</span>
          </button>
        </Tippy>
      )}
      <Tippy
        offset={[0, 8]}
        placement="top"
        popperOptions={{
          modifiers: [{ name: 'flip' }],
        }}
        trigger="click"
        interactive
        content={
          <Flatpickr
            className="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm disabled:cursor-not-allowed border-gray-300 opacity-100"
            options={{
              enableTime: false,
              altInput: true,
              inline: true,
            }}
            defaultValue={post.override_scheduled_at}
            placeholder="Click to select a date"
            onChange={([date]) => {
              updateDisplayDate(date);
            }}
          />
        }
      >
        <Tippy
          offset={[0, 8]}
          placement="top"
          popperOptions={{
            modifiers: [{ name: 'flip' }],
          }}
          trigger="mouseenter"
          interactive
          content={
            <div className="bg-white shadow-lg px-2 py-1 rounded-lg border border-gray-200 text-sm min-w-[8rem] text-center text-gray-500">
              By default, the display date is the publish date. You can override it to set a custom display date.
            </div>
          }
        >
          <button
            type="button"
            className="flex flex-nowrap min-w-[185px] mb-3 md:mb-0 ml-6 text-sm text-gray-400 hover:text-black transition-colors hover:bg-gray-100 rounded-lg px-2 py-[.4rem]"
          >
            <span className="block mt-[.1rem]">
              <Icon name="Calendar" />
            </span>
            <span className="block ml-2 font-medium">Override display date</span>
          </button>
        </Tippy>
      </Tippy>
    </div>
  );
};

export default TitleContentMenu;
