import { Figure } from '../Figure';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    blockquoteFigure: {
      setBlockquote: () => ReturnType;
      setBlockquoteVariant: (variant?: string) => ReturnType;
    };
  }
}

export const BlockquoteFigure = Figure.extend({
  name: 'blockquoteFigure',

  group: 'block',

  content: 'quote quoteCaption',

  addKeyboardShortcuts() {
    return {
      Enter: () => false,
    };
  },

  addAttributes() {
    return {
      ...this.parent?.(),
      variant: {
        default: '1',
        parseHTML: (element) => element.getAttribute('data-variant'),
        renderHTML: (attributes) => ({
          'data-variant': attributes.variant,
        }),
      },
    };
  },

  addCommands() {
    return {
      setBlockquote:
        () =>
        ({ state, chain }) => {
          const position = state.selection.$from.start();

          return chain()
            .focus()
            .insertContent({
              type: this.name,
              content: [
                {
                  type: 'quote',
                  content: [
                    {
                      type: 'paragraph',
                      attrs: {
                        textAlign: 'left',
                      },
                    },
                  ],
                },
                {
                  type: 'quoteCaption',
                },
              ],
            })
            .focus(position + 1)
            .run();
        },
      setBlockquoteVariant:
        (variant) =>
        ({ commands }) =>
          commands.updateAttributes('blockquoteFigure', { variant }),
    };
  },
});

export default BlockquoteFigure;
