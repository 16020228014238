import { useEffect, useState } from "react";
import { GlobeHemisphereEast } from "@phosphor-icons/react";

import { useWebsiteContext } from "@/context/website-context";
import { useCurrentPublication } from "@/hooks";

import { ImageInput } from "../../_components/UI/ImageInput";
import { Input } from "../../_components/UI/Input"
import { Text } from "../../_components/UI/Text";
import { Textarea } from "../../_components/UI/Textarea";

import { FBIcon } from "./FBIcon";
import SettingsItem from "./SettingsItem";


export function FBSettings({
  onUpdate,
}: {
  onUpdate: (key: string, value: string | undefined) => void;
}) {
  const { site } = useWebsiteContext();
  const currentPublication = useCurrentPublication();

  const [fbTitle, setFBTitle] = useState<string>(site?.draft_site_version?.metadata?.facebook_title || '');
  const [fbDescription, setFBDescription] = useState<string>(site?.draft_site_version?.metadata?.facebook_description || '');
  const fbImageUrl = site?.draft_site_version?.metadata?.facebook_image_url;

  useEffect(() => {
    setFBTitle(site?.draft_site_version?.metadata?.facebook_title || '');
    setFBDescription(site?.draft_site_version?.metadata?.facebook_description || '');
  }, [site]);

  const defaultTitle = site?.draft_site_version?.metadata?.meta_title || currentPublication?.data?.name || '';
  const defaultDescription = site?.draft_site_version?.metadata?.meta_description || `A website for ${currentPublication?.data?.name}`;


  return (
    <SettingsItem
      title="Facebook (Meta) Card"
      description="Customize how your site looks like when shared on Facebook (Meta)."
    >
      <div className="flex flex-col lg:flex-row gap-6 w-full pt-4">
        <div className="flex flex-1 flex-col gap-6">

          <ImageInput
            labelText="Image"
            value={site?.draft_site_version?.metadata?.facebook_image_url || null}
            onSave={() => {
              // TODO: Update with image upload
              onUpdate('facebook_image_url', "https://beehiiv-marketing-images.s3.amazonaws.com/Redesign2023/Homepage/png/homePageOG.jpeg");
            }}
            width={100}
            height={100}
            onRemove={() => {
              onUpdate('facebook_image_url', undefined);
            }}
          />

          <Input
            type="text"
            labelText="Title"
            id="facebook_title"
            placeholder={defaultTitle}
            value={fbTitle}
            onChange={(e) => setFBTitle(e.target.value)}
            onBlur={() => {
              onUpdate('facebook_title', fbTitle);
            }}
          />

          <Textarea
            id="facebook_description"
            labelText="Description"
            placeholder={defaultDescription}
            value={fbDescription}
            onChange={(e) => setFBDescription(e.target.value)}
            onBlur={() => {
              onUpdate('facebook_description', fbDescription);
            }}
          />
        </div>
        <div className="flex flex-1 flex-col gap-3">
          <div className="is-size-7 with-text-color mb-2">Facebook (Meta) Preview</div>
          <div className="bg-wb-primary rounded-xl px-5 py-4 shadow-md flex flex-col gap-4 mb-2">

            <div className="flex items-center gap-2">
              <FBIcon />
              <div className="flex flex-col flex-1">
                <Text size="xs" weight="bold">Name</Text>
                <div className="flex items-center gap-1 py-0.5 px-1.5 w-fit rounded-md bg-wb-tertiary">
                  <GlobeHemisphereEast size={12} weight="fill" />
                  <Text size="2xs" weight="bold" className="text-[11px] tracking-tight">Public</Text>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <div className="w-full h-3 bg-wb-secondary rounded-full" />
              <div className="w-3/4 h-3 bg-wb-secondary rounded-full" />
            </div>

            <div className="flex flex-col bg-wb-secondary rounded-2xl border border-wb-border overflow-hidden">
              {fbImageUrl && <img src={fbImageUrl} className="w-full h-[180px] object-cover" alt="X Card Preview" />}
              <div className="w-full p-3 flex flex-col" >
                <Text size="2xs" variant="secondary" className="uppercase">{currentPublication?.data?.hostname}</Text>
                <Text size="sm" weight="bold">{fbTitle || defaultTitle}</Text>
                <Text size="xs" variant="secondary">{fbDescription || defaultDescription}</Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SettingsItem>
  )
}
