import { useParams } from 'react-router-dom';
import { DotOutline } from '@phosphor-icons/react';

import { useWebsitePageRouteGetter } from '@/context/website-context';
import { useCurrentPublication } from '@/hooks';
import { useDraftPageVersion } from '@/hooks/usePageVersions';

import { Text } from '../../../_components/UI/Text';

export const PageName = () => {
  const { pageId } = useParams() as unknown as { pageId: string };
  const pageRouteGetter = useWebsitePageRouteGetter();
  const { data: draftPageVersion } = useDraftPageVersion(pageId);
  const pageRoute = pageRouteGetter?.getPageRouteFromID(pageId);
  const { data: currentPublication } = useCurrentPublication();
  const path = [currentPublication?.hostname, ...(pageRoute?.path || [])];

  return (
    <div className="flex gap-1.5 items-center text-ellipsis overflow-hidden max-w-[200px] lg:max-w-[500px]">
      <Text weight="medium" size="sm" className="truncate">
        {draftPageVersion?.name}
      </Text>
      <DotOutline size={12} className="text-wb-secondary min-h-[12px] min-w-[12px]" weight="fill" />
      <Text weight="medium" variant="secondary" size="sm" className="truncate">
        {path?.join('/')}
      </Text>
    </div>
  );
};
