import { useQuery } from 'react-query';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import { PageVersion } from '@/interfaces/dream_builder/page_version';

import api from '../../services/swarm';

export default function useDraftPageVersion(pageID: string) {
  const [currentPublicationId] = useCurrentPublicationState();
  const id = "null" // there's only 1 draft page version, this parameter is unused

  const fetchDraftPageVersion = () =>
    api
      .get(`pages/${pageID}/page_versions/${id}`, {
        params: {
          publication_id: currentPublicationId,
          draft: true
        },
      })
      .then((res) => res.data);

  return useQuery<PageVersion>({
    queryKey: ['publications', currentPublicationId, 'pages', pageID, 'page_versions', 'null'],
    queryFn: fetchDraftPageVersion,
  });
}
