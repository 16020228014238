import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import styled from 'styled-components';

import { useSettings } from '@/context/settings-context';

import Navbar from '../Navbar';
import NavbarV2 from '../NavbarV2';
import SettingsNav from '../SettingsNav';

const NavStyled: any = styled.nav`
  width: ${({ largeNavOpen }: any) => (largeNavOpen ? '230px' : '60px')};

  @media (max-width: 640px) {
    z-index: 30;
    width: ${({ isOpen }: any) => (isOpen ? '100vw' : '0px')};
    overflow: hidden;
    display: ${({ isSettingsOpen }: any) => (isSettingsOpen ? 'none' : 'block')};
  }
`;

const NavBody = styled.div`
  height: calc(100vh - 80px);
`;

const MobileDiv = styled.div`
  display: none;

  @media (max-width: 640px) {
    display: block;
    z-index: 31;
  }
`;

interface Props {
  isAdmin: boolean;
  largeNavOpen: boolean;
}

const MainNav = ({ isAdmin, largeNavOpen }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();
  const isSettingsOpen = location.pathname.startsWith('/settings');
  const { settings } = useSettings();

  const handleCloseSettings = () => {
    navigate('/');
  };

  return (
    <>
      <SettingsNav isAdmin={isAdmin} isSettingsOpen={isSettingsOpen} handleCloseSettings={handleCloseSettings} />
      <NavStyled
        className="border-r border-gray-200 bg-white h-screen absolute top-0 left-0 transition-all duration-200 ease-in-out"
        isSettingsOpen={isSettingsOpen}
        largeNavOpen={largeNavOpen}
        isOpen={isOpen}
      >
        <NavBody>{settings?.navigation ? <NavbarV2 largeNavOpen={largeNavOpen} /> : <Navbar />}</NavBody>
        {!isSettingsOpen && isOpen && (
          <MobileDiv className="fixed bottom-8 right-8 rounded-full overflow-hidden">
            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="rounded-full bg-gray-900 text-white p-2 shadow-xl block md:hidden z-100"
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          </MobileDiv>
        )}
      </NavStyled>
      {!isSettingsOpen && !isOpen && (
        <MobileDiv className="fixed bottom-8 right-8 rounded-full overflow-hidden">
          <button
            type="button"
            onClick={() => setIsOpen(true)}
            className="rounded-full bg-gray-900 text-white p-2 shadow-xl block md:hidden z-100"
          >
            <Bars3Icon className="h-4 w-4" />
          </button>
        </MobileDiv>
      )}
    </>
  );
};

export default MainNav;
