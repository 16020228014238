import cx from 'classnames';

import { AdNetworkOpportunityAdvertisement } from '@/interfaces/ad_network/publisher/opportunity';

import ScaledPreviews from '../ScaledPreviews';

interface Props {
  advertisements: AdNetworkOpportunityAdvertisement[];
  selectedAdvertisement?: AdNetworkOpportunityAdvertisement;
  handleSelectAdvertisement: (advertisement: AdNetworkOpportunityAdvertisement | null) => void;
  previewSize?: 'small' | 'large';
}

const AdPreviews = ({
  advertisements,
  selectedAdvertisement,
  handleSelectAdvertisement,
  previewSize = 'small',
}: Props) => {
  return (
    <section className={cx('col-span-6 space-y-4', previewSize === 'small' && 'hidden w-full lg:flex')}>
      <div className={cx('gap-6', previewSize === 'small' ? 'flex w-max' : 'grid w-full grid-cols-1 lg:grid-cols-2')}>
        <ScaledPreviews
          advertisements={advertisements}
          onSelect={handleSelectAdvertisement}
          selectedAdvertisement={selectedAdvertisement}
        />
      </div>
    </section>
  );
};

export default AdPreviews;
