import { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  ArrowLeftOnRectangleIcon,
  ArrowTrendingUpIcon,
  BanknotesIcon,
  BellIcon,
  ChartBarIcon,
  ChatBubbleLeftIcon,
  Cog8ToothIcon,
  ComputerDesktopIcon,
  CubeTransparentIcon,
  GlobeAmericasIcon,
  LightBulbIcon,
  PencilIcon,
  QuestionMarkCircleIcon,
  RocketLaunchIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  UsersIcon,
} from '@heroicons/react/20/solid';

import { NotificationsModal } from '@/components/Notifications/NotificationsModal';
import { UnreadCountBadge } from '@/components/Notifications/UnreadCountBadge';
import { PlanCard } from '@/components/Plan';
import { useAuth } from '@/context/auth-context';
import { useCurrentUser } from '@/context/current-user-context';
import { useSettings } from '@/context/settings-context';
import { useCurrentPublication, useSupportTicketModalParam } from '@/hooks';
import usePlan from '@/hooks/usePlan';
import analytics from '@/utils/analytics';

import NavItemWithModal from './NavItems/NavItemWithModal';
import HelpPanel from './HelpPanel';
import { NavItemLink, NavItemParent, NavSection } from './NavItems';

const Navv2 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: currentPublication } = useCurrentPublication();
  const { currentUser } = useCurrentUser();
  const { plan, isLoading } = usePlan();
  const { settings } = useSettings();

  // Walkthrough Active Checks
  const [searchParams] = useSearchParams();
  const isSettingsActive = searchParams.get('walkthrough_step') === '2';
  const isViewSiteActive = searchParams.get('walkthrough_step') === '7';

  // Help Panel
  const shouldOpenSupportModal = useSupportTicketModalParam();
  const [isHelpOpen, setIsHelpOpen] = useState(shouldOpenSupportModal);

  // Handle Logout
  const { logout } = useAuth();
  const handleLogout = () => {
    // Even though this should redirect by default to login after a logout
    // happens, this is to ensure we don't append any redirect params since
    // it was user initiated to logout
    analytics.track('Signed Out');
    logout.mutateAsync().then(() => navigate({ pathname: '/login', search: '' }));
  };

  // Admin Checks
  const [isMasquerading] = useState(!!localStorage.getItem('masqueradeToken'));
  const showSystemAdmin = Boolean(currentUser?.isSystemAdmin()) && currentPublication && !isMasquerading;
  const showAdNetworkAdmin = Boolean(currentUser?.isAdNetworkAdmin());
  const showAccountantAdmin = Boolean(currentUser?.isBeehiivAccountant());

  return (
    <>
      <HelpPanel isOpen={isHelpOpen} onClose={() => setIsHelpOpen(false)} />

      <div>
        <NavSection>
          <NavItemLink title="Dashboard" Icon={Squares2X2Icon} to="/" />

          <NavItemWithModal
            title="Notifications"
            Icon={BellIcon}
            to="/notifications"
            modal={<NotificationsModal />}
            badge={
              <div className="-mt-1.5">
                <UnreadCountBadge />
              </div>
            }
          />
          {settings?.web_builder && (
            <NavItemLink Icon={ComputerDesktopIcon} title="Website Builder" to="/website_builder" />
          )}

          <NavItemParent title="Write" Icon={PencilIcon}>
            <NavItemLink title="Posts" to="/posts" />
            <NavItemLink title="Content Tags" to="/content_tags" />
            <NavItemLink title="Automations" planType={plan} to="/automations" />
          </NavItemParent>

          <NavItemParent title="Grow" Icon={ArrowTrendingUpIcon}>
            <NavItemLink
              title="Referral Program"
              to="/referral_program/overview"
              planType={plan}
              additionalIsActiveCheck={location.pathname.startsWith('/referral_program')}
            />
            <NavItemLink title="Recommendations" to="/recommendations" />
            <NavItemLink title="Magic Links" to="/magic_links" />
            <NavItemLink
              title="Boosts"
              to="/grow/boosts/overview"
              planType={plan}
              notIncludedInTrial
              additionalIsActiveCheck={location.pathname.startsWith('/grow/boosts')}
            />
          </NavItemParent>

          <NavItemParent title="Monetization" Icon={BanknotesIcon}>
            <NavItemLink
              title="Ads"
              planType={plan}
              to="/monetize/ads"
              additionalIsActiveCheck={location.pathname.startsWith('/monetize/ads')}
            />
            <NavItemLink title="Subscriptions" to="/monetize/subscriptions" planType={plan} includedInGrow />
            <NavItemLink title="Boosts" to="/monetize/boosts" planType={plan} notIncludedInTrial />
          </NavItemParent>

          <NavItemParent title="Audience" Icon={UsersIcon}>
            <NavItemLink title="Subscribers" to="/subscribers" />
            <NavItemLink
              title="Segments"
              to="/segments"
              additionalIsActiveCheck={location.pathname.startsWith('/blasts')}
            />
            <NavItemLink title="Polls" to="/polls" planType={plan} includedInGrow />
            {!settings?.subscriber_tagging && <NavItemLink title="Custom Fields" to="/custom_fields" />}
            {settings?.subscriber_tagging && <NavItemLink title="Subscriber Data" to="/subscriber_data" />}
            <NavItemLink title="Surveys" planType={plan} to="/forms" includedInGrow />
            <NavItemLink title="Subscribe Forms" to="/settings/publication/subscribe_forms" />
          </NavItemParent>

          <NavItemParent title="Analyze" Icon={ChartBarIcon}>
            <NavItemLink title="Subscribers Report" to="/reports/subscriber" planType={plan} />
            <NavItemLink title="Posts Report" to="/reports/post" planType={plan} />
            <NavItemLink title="Clicks Report" to="/reports/click" planType={plan} />
          </NavItemParent>

          <NavItemParent title="Learn" Icon={LightBulbIcon}>
            <NavItemLink title="Blog" href="https://blog.beehiiv.com/" />
            <NavItemLink title="Updates" href="https://product.beehiiv.com/" />
            <NavItemLink title="Tutorials" href="https://www.youtube.com/@beehiiv" />
            <NavItemLink title="NewsletterXP" href="https://www.beehiiv.com/courses/newsletter-xp" />
          </NavItemParent>

          <NavItemLink title="Wallet" Icon={BanknotesIcon} to="/wallet" />
          {settings?.partner_program && (
            <NavItemLink title="Partner Program" Icon={RocketLaunchIcon} to="/partner_program" />
          )}
        </NavSection>
        <NavSection>
          {!isLoading && <PlanCard />}
          <NavItemLink
            title="Settings"
            Icon={Cog8ToothIcon}
            to="/settings"
            additionalIsActiveCheck={isSettingsActive}
          />
          <NavItemLink
            title="View Site"
            Icon={GlobeAmericasIcon}
            href={currentPublication?.url || ''}
            additionalIsActiveCheck={isViewSiteActive}
          />
          <NavItemLink title="Help" Icon={QuestionMarkCircleIcon} onClick={() => setIsHelpOpen(true)} />
          <NavItemLink
            id="nav-ada-chat-button"
            classNames="hidden"
            title="Chatbot Assistant"
            Icon={ChatBubbleLeftIcon}
            onClick={window.adaEmbed.toggle}
          />
          <NavItemLink title="Sign Out" Icon={ArrowLeftOnRectangleIcon} onClick={handleLogout} />
          {(showSystemAdmin || showAdNetworkAdmin || showAccountantAdmin) && (
            <>
              {showAdNetworkAdmin && <NavItemLink title="Ad network" Icon={CubeTransparentIcon} to="/ad_network" />}
              {showSystemAdmin && <NavItemLink title="System admin" Icon={ShieldCheckIcon} to="/system_admin/users" />}
              {showAccountantAdmin && (
                <NavItemLink title="Admin Reports" Icon={ArrowLeftOnRectangleIcon} to="/admin_reports/ledgers" />
              )}
            </>
          )}
        </NavSection>
      </div>
    </>
  );
};

export default Navv2;
