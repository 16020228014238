import { useState } from 'react';
import { CheckCircleIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

import CustomFieldSelect from '@/components/CustomFieldSelect';
import { SimpleSelect, Switch, Textarea } from '@/components/Form';
import HelperText from '@/components/Form/HelperText';
import Input from '@/components/Form/Input';
import Modal from '@/components/Modal';
import { Typography, TypographyStack } from '@/components/Typography';
import useCreateCustomField from '@/hooks/useCreateCustomField';
import { CustomField } from '@/interfaces/custom_field';
import {
  PublicationSubscriberPreference,
  SubscriberPreferenceDisplayType,
  SubscriberPreferenceType,
  SubscriberPreferenceVisibility,
} from '@/interfaces/subscribers_preferences';
import analytics from '@/utils/analytics';

import { usePreferencesContext } from '../../preferences/context';
import CustomFieldForm from '../CustomFieldForm';

import OptionsForm from './OptionsForm';

const Form = () => {
  const [showCustomFieldFormModal, setShowCustomFieldFormModal] = useState(false);

  const { editablePreference, setEditablePreference } = usePreferencesContext();

  const handleChangeInAttribute = (key: keyof PublicationSubscriberPreference, value: any) => {
    setEditablePreference({ ...editablePreference, [key]: value });
  };

  const handleChangeInPreferenceType = (newValue: SubscriberPreferenceType) => {
    // Set display type to be unstyled when type is text or boolean
    // and reset options
    if ([SubscriberPreferenceType.TEXT, SubscriberPreferenceType.BOOLEAN].includes(newValue)) {
      handleChangeInAttribute('display_type', SubscriberPreferenceDisplayType.UNSTYLED);
      handleChangeInAttribute('subscriber_preference_options', []);
    }

    handleChangeInAttribute('preference_type', newValue);
  };

  const hideDisplayType = [SubscriberPreferenceType.TEXT, SubscriberPreferenceType.BOOLEAN].includes(
    editablePreference.preference_type
  );

  const showOptionsForm = [SubscriberPreferenceType.DROPDOWN, SubscriberPreferenceType.MULTIPLE_CHOICE].includes(
    editablePreference.preference_type
  );

  const { mutateAsync: createCustomField } = useCreateCustomField((newCustomField: CustomField) => {
    setShowCustomFieldFormModal(false);
    setEditablePreference({
      ...editablePreference,
      custom_field_id: newCustomField.id,
    });
  });

  const handleSubmitCustomFieldForm = async (display: any, kind: any) => {
    analytics.track('Created a custom field', {
      view: 'Subscriber Preferences Form',
    });
    await createCustomField({ display, kind });
  };

  return (
    <>
      <Modal
        className="w-full sm:w-1/3"
        isOpen={showCustomFieldFormModal}
        onClose={() => setShowCustomFieldFormModal(false)}
      >
        <CustomFieldForm
          header=""
          footerClassName="flex justify-end"
          onSubmit={handleSubmitCustomFieldForm}
          onCancel={() => setShowCustomFieldFormModal(false)}
        />
      </Modal>
      <form>
        <div className="flex flex-col gap-y-5">
          <Input
            name="name"
            labelText="Name"
            placeholderText="Name"
            type="text"
            value={editablePreference.name}
            onChange={(e) => handleChangeInAttribute('name', e.currentTarget.value)}
            required
          />
          <Textarea
            name="description"
            labelText="Description"
            value={editablePreference.description}
            rows={2}
            onChange={(e) => handleChangeInAttribute('description', e.currentTarget.value)}
          />
          <SimpleSelect
            labelText="Type"
            helperText="Choose a type of configuration format for the preference"
            options={[
              { label: 'Multiple Choice', value: SubscriberPreferenceType.MULTIPLE_CHOICE, icon: CheckCircleIcon },
              { label: 'Text Input', value: SubscriberPreferenceType.TEXT },
              { label: 'Dropdown Menu', value: SubscriberPreferenceType.DROPDOWN, icon: ChevronDownIcon },
              { label: 'True / False', value: SubscriberPreferenceType.BOOLEAN },
            ]}
            name="preference_type"
            value={editablePreference.preference_type}
            onSelect={(_, value) => handleChangeInPreferenceType(value)}
          />
          {showOptionsForm ? <OptionsForm /> : null}
          {!hideDisplayType ? (
            <SimpleSelect
              labelText="Options Format"
              helperText="Choose how you want your multiple choice options to be displayed"
              options={[
                { label: 'Radio Button', value: SubscriberPreferenceDisplayType.RADIO_BUTTONS },
                { label: 'Checkbox', value: SubscriberPreferenceDisplayType.CHECKBOXES },
                { label: 'Numerals', value: SubscriberPreferenceDisplayType.NUMERALS },
                { label: 'Unstyled', value: SubscriberPreferenceDisplayType.UNSTYLED },
              ]}
              name="display_type"
              value={editablePreference.display_type}
              onSelect={(_, value) => handleChangeInAttribute('display_type', value)}
            />
          ) : null}
          <div className="flex flex-col gap-y-2">
            <CustomFieldSelect
              customFieldId={editablePreference.custom_field_id}
              labelText="Custom Field"
              helperText=""
              className="w-full"
              onSelectCustomField={(customFieldId) => handleChangeInAttribute('custom_field_id', customFieldId)}
              onClearCustomField={() => handleChangeInAttribute('custom_field_id', null)}
              refetchFetchOptions={false}
            />
            <HelperText>
              You can only use data type custom fields you have created or create a{' '}
              <Typography token="font-semibold/text/xs" colorWeight="700" color="secondary">
                <button type="button" onClick={() => setShowCustomFieldFormModal(true)}>
                  new custom field
                </button>
              </Typography>
              .
            </HelperText>
          </div>

          <div className="flex flex-col gap-y-4">
            <TypographyStack>
              <Typography token="font-medium/text/base">Preference Center Settings</Typography>
              <Typography token="font-normal/text/sm" colorWeight="700">
                Determine if you want this preference to be shown and/or required for your subscribers on their
                management page.
              </Typography>
            </TypographyStack>
            <Switch
              name="visibility"
              checked={editablePreference.visibility === SubscriberPreferenceVisibility.VISIBLE}
              labelText="Visible"
              onChange={(_, checked) =>
                handleChangeInAttribute(
                  'visibility',
                  checked ? SubscriberPreferenceVisibility.VISIBLE : SubscriberPreferenceVisibility.HIDDEN
                )
              }
            />
            <Switch
              name="required"
              checked={editablePreference.required}
              labelText="Required"
              onChange={(_, checked) => handleChangeInAttribute('required', checked)}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default Form;
