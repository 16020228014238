import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import { CaretRight, DotsThree, File, House, Icon as PhosphorIcon, Plus } from '@phosphor-icons/react';
import cx from 'classnames';

import { useWebsitePageRouteGetter } from '@/context/website-context';
import { useCreatePage } from '@/hooks/usePages';
import { useDraftPageVersion } from '@/hooks/usePageVersions';
import { PageRoute } from '@/interfaces/dream_builder/page_route';

import { cn } from '../../_utils/cn';
import { PageOption } from '../Main/PageOption';
import { Text } from '../UI/Text';
import { Tooltip } from '../UI/Tooltip';

import { ContentTreeItemDndWrapper } from './ContentTreeItemDndWrapper';
import { useContentTreeContext } from './context';

interface Props {
  route: PageRoute;
  slug: string;
  parentPath: string[];
}

export const ContentTreeItem = ({ route, parentPath, slug }: Props) => {
  const isAllowDnD = useContentTreeContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const { pageId } = useParams() as unknown as { pageId: string };
  const createPage = useCreatePage();
  const createPageMutation = async () => {
    await createPage.mutateAsync({ parentPath: [...parentPath, slug] });
  };

  const { data: draftPageVersion } = useDraftPageVersion(route.page_id);
  const pageRouteGetter = useWebsitePageRouteGetter();
  const isHome = parentPath?.length === 0 && !slug;
  const isCurrentPath = pageId === route.page_id;
  const isDefaultPage = pageRouteGetter?.isDefaultPage(route.page_id);
  const hasChildren = (route?.children_keys?.length || 0) > 0;

  // Auto-open the tree if the current page is inside this page item
  const openedPageIDPath = pageRouteGetter?.getPageRouteFromID(pageId)?.path?.join('/');
  const currentPath = [...parentPath, slug]?.join('/');
  const isOpenedPageInsideThisPageItem =
    (openedPageIDPath?.length || 0) > currentPath?.length && openedPageIDPath?.startsWith(currentPath);
  useEffect(() => {
    if (isOpenedPageInsideThisPageItem) {
      setIsOpen(true);
    }
  }, [isOpenedPageInsideThisPageItem]);

  const Icon = useMemo((): PhosphorIcon => (isHome ? House : File), [isHome]);

  const content = <Link
    // TODO: @putrikarunia decide route for page
    to={`/website_builder_v2/page/${route.page_id}`}
    onDragStart={!isAllowDnD ? (e) => {
      e.preventDefault();
      e.stopPropagation();
      toast.error('You can\'t re-arrange default pages');
    } : undefined}
    className={cx(
      'h-8 px-1.5 py-2 flex rounded-lg flex-row w-full items-center gap-2 group',
      isCurrentPath || isSettingsOpen ? 'bg-wb-secondary' : 'hover:bg-wb-secondary',
      isAllowDnD ? 'cursor-grab' : 'cursor-pointer'
    )}
  >
    <div
      className="h-4 w-4 flex items-center justify-center cursor-pointer"
      role="none"
      onClick={
        hasChildren && !isHome
          ? (e) => {
            e.preventDefault(); // this line prevents changing to the URL of the link href
            e.stopPropagation(); // this line prevents the link click from bubbling
            setIsOpen((o) => !o);
          }
          : undefined
      }
    >
      {hasChildren && !isHome && (
        <CaretRight
          size={10}
          weight="fill"
          className={cx('my-auto text-wb-secondary transition-all', isOpen ? 'rotate-90' : '')}
        />
      )}
    </div>
    <Icon weight="bold" size={16} className="my-auto text-wb-secondary" />

    <Tooltip center={draftPageVersion?.name} className="flex-1 flex items-center text-ellipsis overflow-hidden">
      <Text as="p" size="2xs" weight="medium" className="truncate">
        {draftPageVersion?.name}
      </Text>
    </Tooltip>
    <div className={cn("group-hover:opacity-100 flex items-center gap-1", isSettingsOpen ? 'opacity-100' : 'opacity-0')}>

      <Tooltip center="Page Options" className="flex-1  flex items-center cursor-pointer">
        <PageOption
          pageId={route.page_id}
          align="start"
          open={isSettingsOpen}
          onOpenChange={setIsSettingsOpen}
          isDefaultPage={isDefaultPage || false}
          isHome={isHome}
        >
          <div className="hover:bg-wb-highlight rounded-full p-0.5 cursor-pointer">
            <DotsThree weight="bold" className="my-auto text-wb-secondary h-4 w-4" />
          </div>
        </PageOption>
      </Tooltip>
      <Tooltip center="Add Page Inside" className="flex-1 flex items-center cursor-pointer">
        <button
          type="button"
          className="hover:bg-wb-highlight rounded-full p-0.5"
          onClick={createPageMutation}
        >
          <Plus weight="bold" className="my-auto text-wb-secondary h-4 w-4" />
        </button>
      </Tooltip>
    </div >
  </Link >
  return (
    <div className={cx('flex flex-col', parentPath?.length <= 0 ? '' : 'ml-4')}>
      {isAllowDnD ? (
        <ContentTreeItemDndWrapper path={[...parentPath, slug]} id={route.page_id} setIsOpen={setIsOpen}>
          {content}
        </ContentTreeItemDndWrapper>
      ) : (
        content
      )}

      {(isHome || isOpen) && route?.children && route?.children_keys && (
        <div className="flex flex-col">
          {route.children_keys?.map(
            (childSlug) =>
              route.children?.[childSlug] && (
                <ContentTreeItem
                  key={route.children[childSlug]?.page_id}
                  route={route.children[childSlug]}
                  parentPath={slug ? [...parentPath, slug] : [...parentPath]}
                  slug={childSlug}
                />
              )
          )}
        </div>
      )}
    </div>
  );
};
