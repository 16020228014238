import { ReactElement, useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import PostEditorLayout from '@/components/Layout/PostEditorLayout';
import { PostEditorSteps } from '@/components/Layout/PostEditorLayout/constants';
import PreviewModalV2 from '@/components/PreviewModalV2';
import { useCurrentPublication } from '@/hooks';
import { IData } from '@/interfaces/general';
import { Post } from '@/interfaces/post';
import api from '@/services/swarm';

import AudienceView from './AudienceView';
import ComposeView from './Compose';
import EmailView from './EmailView';
import ReviewView from './ReviewView';
import WebView from './WebView';

interface Props {
  postId: string;
  collaborationEnabled: boolean;
}

const Form = ({ postId, collaborationEnabled }: Props) => {
  const [postEditorStep, setPostEditorStep] = useState<PostEditorSteps>(PostEditorSteps.COMPOSE);
  const [previewActive, setPreviewActive] = useState(false);
  const [previewOptions, setPreviewOptions] = useState<('Email' | 'Web')[]>([]);
  const post = useQuery<Post>(['post-v2', postEditorStep, previewActive], () => api.get(`/posts/${postId}`).then((res) => res.data), {
    cacheTime: 0,
  });
  const { data: currentPublication } = useCurrentPublication();

  const renderTab = (): ReactElement => {
    if (!post.data) return <div />;

    switch (postEditorStep) {
      case PostEditorSteps.COMPOSE:
        return <ComposeView post={post.data} collaborationEnabled={collaborationEnabled} />;
      case PostEditorSteps.AUDIENCE:
        return <AudienceView post={post.data} />;
      case PostEditorSteps.EMAIL:
        return <EmailView post={post.data} />;
      case PostEditorSteps.WEB:
        return <WebView post={post.data} />;
      case PostEditorSteps.REVIEW:
        return <ReviewView post={post.data} setPostEditorStep={setPostEditorStep} />;
      default:
        return <ComposeView post={post.data} collaborationEnabled={collaborationEnabled} />;
    }
  };

  const fetchPreview = useCallback(
    (platform: string, advancedParams: IData, onFetch: (html: string) => void) => {
      if (!currentPublication?.id) {
        return;
      }

      const params = {
        publication_id: currentPublication?.id,
        is_v2: true,
        platform,
        ...advancedParams,
      };
      api.get(`/posts/${postId}/preview`, { params }).then((resp) => onFetch(resp.data.html));
    },
    [postId, currentPublication]
  );

  if (!post.data) return null;

  return (
    <PostEditorLayout
      currentPostEditorStep={postEditorStep}
      setPostEditorStep={setPostEditorStep}
      setPreviewActive={setPreviewActive}
      setPreviewOptions={setPreviewOptions}
      isV2
      post={post.data}
    >
      <PreviewModalV2
        fetchPreview={fetchPreview}
        active={previewActive}
        tabs={previewOptions}
        post={post.data}
        onClose={() => setPreviewActive(false)}
        showSubscriberSelect
      />
      <div>{renderTab()}</div>
    </PostEditorLayout>
  );
};

export default Form;
