import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Dialog } from '@headlessui/react';
import { TiptapCollabProvider } from '@hocuspocus/provider';
import { EditorContent, useEditor } from '@tiptap/react';
import { CollabHistoryVersion, watchPreviewContent } from '@tiptap-pro/extension-collaboration-history';
import moment from 'moment-mini';

import ActionModal from '@/components/ActionModal';
import IconButton from '@/components/IconHelpers/IconButton';
import Text from '@/components/Text';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import ExtensionKit from '@/components/TiptapEditor/extensions/extension-kit';
import { Settings } from '@/interfaces/setting';
import { Button } from '@/ui/Button';
import { cn } from '@/utils/cn';

import { Styled } from '../../../../components/TiptapEditor/styled';

const HistoryModalItem = ({
  active,
  date,
  onClick,
  isCurrent,
}: {
  active?: boolean;
  date: number;
  onClick: () => void;
  isCurrent?: boolean;
}) => {
  const relativeLabel = useMemo(() => moment(date).fromNow(), [date]);
  const dateLabel = useMemo(() => moment(date).format('dddd, DD/MM/YYYY, HH:mm'), [date]);

  const wrapperClass = cn(
    'flex flex-col items-start justify-start p-2 text-left rounded',
    !active && 'hover:bg-surface-50',
    active && 'bg-surface-50'
  );

  const titleClass = cn('text-sm font-semibold', !active && 'text-gray-700');

  return (
    <button className={wrapperClass} type="button" onClick={onClick}>
      <span className={titleClass}>{isCurrent ? 'Current' : relativeLabel}</span>
      <span className="text-xs text-gray-600">{dateLabel}</span>
    </button>
  );
};

const HistoryModalContent = ({
  publicationId,
  onClose,
  provider,
  versions,
  currentVersion,
  onRevert,
  settings,
}: {
  publicationId: string;
  onClose?: () => void;
  provider: TiptapCollabProvider;
  versions: Array<CollabHistoryVersion> | null;
  currentVersion: number | null;
  onRevert?: (version: number) => void;
  settings?: Settings;
}) => {
  const [currentVersionId, setCurrentVersionId] = useState<number | null>(null);
  const [requestedRestore, setRequestedRestore] = useState(false);
  const revertedVersions = useMemo(() => versions?.sort((va, vb) => (va.date <= vb.date ? 1 : -1)), [versions]);

  const editor = useEditor({
    editable: false,
    extensions: [
      ...(ExtensionKit({
        publicationId,
        provider,
        usesCollaboration: false,
        useCursors: false,
        allowAds: true,
        allowPolls: true,
        settings,
      }) as any),
    ],
    editorProps: {
      attributes: {
        class: 'pt-12 pb-20',
      },
    },
  });

  const handleVersionChange = useCallback(
    (newVersion: number) => {
      setCurrentVersionId(newVersion);

      provider.sendStateless(
        JSON.stringify({
          action: 'version.preview',
          version: newVersion,
        })
      );
    },
    [provider]
  );

  useEffect(() => {
    if (currentVersionId === null && revertedVersions && revertedVersions.length > 0) {
      const initialVersion = revertedVersions[0].version;
      setCurrentVersionId(initialVersion);

      provider.sendStateless(
        JSON.stringify({
          action: 'version.preview',
          version: initialVersion,
        })
      );
    }
  }, [provider, currentVersionId, currentVersion, revertedVersions]);

  useEffect(() => {
    if (editor) {
      const unbindContentWatcher = watchPreviewContent(provider, (newContent) => {
        editor.commands.setContent(newContent);
      });

      return () => {
        unbindContentWatcher();
      };
    }

    return () => {};
  }, [editor, provider]);

  const restoreDisabled = currentVersionId === null || currentVersionId === currentVersion;

  const handleRevert = useCallback(() => {
    if (restoreDisabled) {
      return;
    }

    if (onRevert) {
      onRevert(currentVersionId);
      toast.success('Version restored');
    }

    if (onClose) {
      onClose();
    }
  }, [onRevert, currentVersionId, restoreDisabled, onClose]);

  return (
    <div className="grid w-full h-full grid-cols-12">
      <div className="col-span-9 py-16 overflow-auto">
        <Styled.Container>
          <EditorContent editor={editor} />
        </Styled.Container>
      </div>
      <div className="flex flex-col h-full col-span-3 overflow-hidden border-l border-surface-200">
        <div className="flex items-center justify-between flex-none px-5 py-2 border-b border-surface-200">
          <p className="text-sm font-bold">Version history</p>
          <IconButton onClick={onClose}>
            <Icon name="Close" $size="0.875rem" />
          </IconButton>
        </div>
        <div className="flex flex-col flex-1 gap-1 p-3 overflow-auto">
          {!revertedVersions || revertedVersions.length === 0 ? (
            <div className="py-4 text-sm font-semibold text-center">No versions currently available</div>
          ) : null}
          {revertedVersions?.map((version, key) => (
            <HistoryModalItem
              key={version.version}
              isCurrent={key === 0}
              active={currentVersionId === version.version}
              date={version.date}
              onClick={() => handleVersionChange(version.version)}
            />
          ))}
        </div>
        <div className="flex items-center justify-end flex-none gap-1 p-3 border-t border-surface-200">
          <ActionModal
            isOpen={requestedRestore}
            onClose={() => setRequestedRestore(false)}
            onProceed={handleRevert}
            headerText="Revert Post"
            resourceId="version-revert"
            isWorking={false}
            actionText="Restore Version"
          >
            <Text>
              This will revert the content of your post to this version for all connected users. Are you sure?
            </Text>
          </ActionModal>
          {onClose && (
            <Button type="button" variant="primary-inverse" onClick={onClose}>
              Cancel
            </Button>
          )}
          <Button type="button" variant="primary" disabled={restoreDisabled} onClick={() => setRequestedRestore(true)}>
            Restore
            <Icon name="History" $size="0.875rem" className="ml-1" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export const HistoryModal = ({
  publicationId,
  open = false,
  provider,
  onClose,
  versions,
  currentVersion,
  onRevert,
  settings,
}: {
  publicationId?: string;
  open: boolean;
  provider: TiptapCollabProvider;
  onClose: () => void;
  versions: Array<CollabHistoryVersion> | null;
  currentVersion: number | null;
  onRevert?: (version: number) => void;
  settings?: Settings;
}) => {
  if (!publicationId) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full p-8 z-20">
        <Dialog.Overlay onClick={onClose} className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30" />
        <Dialog.Panel className="relative z-10 bg-white w-[95%] max-w-[75rem] h-full rounded-lg outline-none ring-0">
          {open === true ? (
            <HistoryModalContent
              onClose={onClose}
              versions={versions}
              currentVersion={currentVersion}
              publicationId={publicationId}
              provider={provider}
              onRevert={onRevert}
              settings={settings}
            />
          ) : null}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
