import { Dispatch, SetStateAction } from 'react';

import { useThreadComposer } from '@/components/TiptapEditor/components/panels/ThreadComposer/Context';
import Button from '@/components/TiptapEditor/components/ui/Button';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import { Post } from '@/interfaces/post';

interface Props {
  post: Post;
  setShowSubtitle: Dispatch<SetStateAction<boolean>>;
  updatePost: (data: Post) => void;
}

const SubtitleTooltipMenu = ({ post, setShowSubtitle, updatePost }: Props) => {
  const { openComposer } = useThreadComposer();

  const handleOpenComposer = () => {
    openComposer();
  };

  const deleteSubtitle = () => {
    updatePost({ ...post, web_subtitle: '' });
    setShowSubtitle(false);
  };

  return (
    <div className="flex h-[3rem] bg-gray-800 rounded-lg p-2">
      <Button $variant="quaternary" $leftSlot={<Icon name="Comment" />} onClick={handleOpenComposer} />
      <Button $variant="quaternary" $leftSlot={<Icon name="Trash" />} onClick={deleteSubtitle} />
    </div>
  );
};

export default SubtitleTooltipMenu;
