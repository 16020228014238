import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import Card from '../../../components/Card';
import Text from '../../../components/Text';
import { Segment, SegmentType } from '../../../interfaces/segment';
import api from '../../../services/swarm';
import ConditionsEditor from '../ConditionsEditor';
import FilterByActiveSubscribersWarning from '../ConditionsEditor/FilterByActiveSubscribersWarning';
import { SEGMENT_TYPE_OPTIONS } from '../constants';
import FormTitle from '../FormTitle';
import useSegmentForm from '../hooks/useSegmentForm';
import MetadataForm from '../MetadataForm';
import { RequestPayload } from '../types';

interface Props {
  segment: Segment;
}

const SegmentForm = ({ segment }: Props) => {
  const navigate = useNavigate();
  const {
    isNextStepButtonDisabled,
    isPendingOrProcessing,
    isSaving,
    setIsSaving,
    name,
    setName,
    description,
    setDescription,
    segmentType,
    setSegmentType,
    mainLogicalOperator,
    hasConditionsChanged,
    blocks,
    addConditionBlock,
    addGroupBlock,
    duplicateConditionBlock,
    currentPublicationId,
    isMissingActiveSubscriberCondition,
    handleAddActiveSubscribersCondition,
    handleSelectWhichName,
    handleFilterChange,
    handleRefineChange,
    handleGroupLogicalOperatorChange,
    handleDeleteCondition,
    handleDeleteRefine,
    handleSelectConditionName,
    handleSelectConditionNameInGroup,
    handleMainLogicalOperatorChange,
    handleSortConditionBlocks,
  } = useSegmentForm(segment);

  const isManualSegment = segmentType === SegmentType.MANUAL;

  const handleUpdate = (payload: RequestPayload) => {
    setIsSaving(true);
    api
      .patch(`/segments/${segment?.id}`, payload)
      .then(() => {
        toast.success('Segment updated successfully');
        navigate(`/segments/${segment?.id}`);
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const payload: RequestPayload = {
      name,
      description,
      segment_type: segmentType,
      publication_id: currentPublicationId,
    };

    // This is to ensure that on updates, we only pass
    // the conditions attribute if it actually
    if (hasConditionsChanged) {
      payload.conditions = {
        logical_operator: mainLogicalOperator,
        conditions: blocks,
      };
    }

    analytics.track('Updated a Segment');

    return handleUpdate(payload);
  };

  return (
    <Card>
      <div>
        <FormTitle>{segment ? 'Edit Segment' : 'New Segment'}</FormTitle>
        <form onSubmit={handleSubmit}>
          <MetadataForm
            segmentName={name}
            segmentDescription={description}
            onSegmentNameChange={setName}
            onSegmentDescriptionChange={setDescription}
            segmentOptions={SEGMENT_TYPE_OPTIONS}
            segmentType={!isManualSegment ? segmentType : undefined}
            onSegmentTypeChange={!isManualSegment ? setSegmentType : undefined}
          />

          {!isManualSegment ? (
            <div className="mt-6">
              <Text size="sm" type="label">
                Conditions *
              </Text>
              <ConditionsEditor
                mainLogicalOperator={mainLogicalOperator}
                blocks={blocks}
                onAddConditionBlock={addConditionBlock}
                onAddGroupBlock={addGroupBlock}
                onDuplicateCondition={duplicateConditionBlock}
                onSelectWhichName={handleSelectWhichName}
                onFilterChange={handleFilterChange}
                onRefineChange={handleRefineChange}
                onGroupLogicalOperatorChange={handleGroupLogicalOperatorChange}
                onDeleteCondition={handleDeleteCondition}
                onDeleteRefine={handleDeleteRefine}
                onSelectConditionName={handleSelectConditionName}
                onSelectConditionNameInGroup={handleSelectConditionNameInGroup}
                onChangeMainLogicalOperator={handleMainLogicalOperatorChange}
                onSortConditionBlocks={handleSortConditionBlocks}
                className="mt-2"
              />
            </div>
          ) : null}

          {isMissingActiveSubscriberCondition && (
            <FilterByActiveSubscribersWarning
              handleAddActiveSubscribersCondition={handleAddActiveSubscribersCondition}
            />
          )}

          <div className="pt-8">
            <Button type="submit" disabled={isNextStepButtonDisabled} loading={isSaving || isPendingOrProcessing}>
              Update Segment
            </Button>
          </div>
        </form>
      </div>
    </Card>
  );
};

export default SegmentForm;
