import React, { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import useClickedOutside from '@/hooks/useClickedOutside';

import { useCurrentPublicationState } from '../../../../../context/current-publication-context';
import { useCurrentPublication } from '../../../../../hooks';
import usePublications from '../../../../../hooks/usePublications/usePublications';
import { Publication } from '../../../../../interfaces/publication';
import { SkeletonLoader } from '../../../../SkeletonLoader';

import PublicationOptions from './PublicationOptions';

const PublicationDropdown = () => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  // Close dropdown if mouse clicks outside the dropdown
  // are captured
  useClickedOutside(menuRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  const { data: publications, isLoading: isLoadingPublications } = usePublications();
  const { data: currentPublication } = useCurrentPublication();
  const [currentPublicationId, setCurrentPublicationId] = useCurrentPublicationState();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const hasMultiplePublications = publications?.length > 1;

  const onSelectOption = (publication: Publication) => {
    if (currentPublicationId !== publication.id) {
      setCurrentPublicationId(publication.id);
      queryClient.invalidateQueries();
      navigate('/');
    }

    setIsOpen(false);
  };

  const onToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const isLoading = isLoadingPublications || !currentPublication;

  return (
    <SkeletonLoader
      isLoading={isLoading}
      skeletons={
        <div className="flex items-center justify-end">
          <div className="bg-gray-200 h-8 w-full rounded" />
        </div>
      }
      as={React.Fragment}
    >
      {currentPublication && (
        <div className={cx('relative flex flex-col space-y-4')}>
          <div className="relative">
            <button
              aria-expanded={isOpen}
              aria-haspopup="true"
              className={cx(
                'flex-grow-0 relative w-full bg-gray-50 border-gray-200 rounded-md pl-3 pr-4 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm',
                hasMultiplePublications ? 'cursor-pointer' : 'cursor-default'
              )}
              type="button"
              onClick={hasMultiplePublications ? onToggleDropdown : undefined}
            >
              <div className="flex items-center">
                <div className="w-4 h-4 min-w-4 min-h-4 rounded overflow-hidden">
                  {currentPublication.logo.thumb.url ? (
                    <img className="h-4 w-4" src={currentPublication.logo.thumb.url} alt="" />
                  ) : (
                    <div className="bg-gray-200 h-4 w-4 rounded" />
                  )}
                </div>
                <div className="truncate mr-2 flex-1">
                  <span className="ml-2 block truncate">{currentPublication.name}</span>
                </div>
                {hasMultiplePublications && (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" />
                  </span>
                )}
              </div>
            </button>
            {publications && (
              <div ref={menuRef}>
                <PublicationOptions
                  currentPublicationId={currentPublication.id}
                  publications={publications}
                  isOpen={isOpen}
                  onSelectOption={onSelectOption}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </SkeletonLoader>
  );
};

export default PublicationDropdown;
