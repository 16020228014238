import { PropsWithChildren, useState } from 'react';
import { Layout as LayoutIcon, Play, Plus, SidebarSimple } from '@phosphor-icons/react';

import { useWebsiteDefaultRoute, useWebsitePageRoute } from '@/context/website-context';
import { ContentTree } from '@/routes/website/_components/ContentTree';
import { TopNav } from '@/routes/website/_components/Layout/TopNav';
import { SideNav } from '@/routes/website/_components/SideNav';
import { NavSection } from '@/routes/website/_components/SideNav/NavSection';
import { NavSectionTitle } from '@/routes/website/_components/SideNav/NavSectionTitle';
import { DeviceGate } from '@/ui/DeviceGate';

import { Button } from '../../../_components/UI/Button';

import { ActionMenu } from './ActionMenu';
import { LogoDropDown } from './LogoDropDown';
import { PageName } from './PageName';
import { PublishDropDown } from './PublishDropDown';

export const Layout = ({ children }: PropsWithChildren<{}>) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const pageRoutes = useWebsitePageRoute();
  const defaultRoutes = useWebsiteDefaultRoute();
  return (
    <DeviceGate reason="The feature is currently not supported on mobile devices">
      <div className="h-screen w-screen flex flex-col">
        <TopNav>
          {/* center */}
          <div className="absolute top-0 left-0 right-0 bottom-0 w-full flex items-center justify-center">
            <PageName />
          </div>

          {/* left */}
          <div className="relative flex justify-start gap-6">
            <div className="flex items-center gap-1.5">
              <LogoDropDown />
              <Button variant="ghost" Icon={SidebarSimple} onClick={() => setIsSidebarCollapsed((prev) => !prev)} />
            </div>
            <ActionMenu Icon={Plus} text="Insert" onClick={() => { }} />
            <ActionMenu Icon={LayoutIcon} text="Templates" onClick={() => { }} />
          </div>

          {/* right */}
          <div className="relative flex justify-end gap-2">
            <Button variant="secondary" Icon={Play} iconWeight="fill" />
            <PublishDropDown />
          </div>
        </TopNav>

        <div className="bg-wb-primary flex-grow flex overflow-auto">
          <SideNav className="border-r border-wb-primary" isResizable isCollapsed={isSidebarCollapsed}>
            <NavSection>
              <NavSectionTitle title="Pages" />
              {pageRoutes && <ContentTree route={pageRoutes} parentPath={[]} slug="" />}
            </NavSection>
            <NavSection>
              <NavSectionTitle title="Default Pages" />
              {defaultRoutes && <ContentTree showHome={false} allowDnD={false} route={defaultRoutes} parentPath={[]} slug="" />}
            </NavSection>
          </SideNav>
          <div className="w-full h-full p-7">{children}</div>
        </div>
      </div>
    </DeviceGate>
  );
};
