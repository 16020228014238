import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PencilSquareIcon } from '@heroicons/react/20/solid';
import moment from 'moment-mini';

import { useInfiniteScroll } from '@/hooks';
import useCampaignOpportunities from '@/hooks/useAdNetwork/internal/useCampaignOpportunities';
import { AdNetworkCampaignPayoutMethod } from '@/interfaces/ad_network/internal/campaign';
import {
  AdNetworkCampaignPerformanceFilter,
  AdNetworkCampaignPerformanceOpportunity,
} from '@/interfaces/ad_network/internal/campaign_performance_opportunity';
import { Pagination } from '@/interfaces/general';

import { LoadingListPage } from '../../../_components/LoadingListPage';
import { Tabs } from '../../../_components/Tabs';
import EditClicksModal from '../_components/EditClicksModal';

import { Table } from './_components/Table';
import { useOutletCampaignPerformance } from './_layout';

interface CampaignPerformanceDeliveredProps {
  opportunities: AdNetworkCampaignPerformanceOpportunity[];
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetching: boolean;
  isLoading: boolean;
  pagination: Pagination;
}

const formatter = new Intl.NumberFormat('en-US');

const CampaignPerformanceDelivered = ({
  opportunities,
  fetchNextPage,
  hasNextPage,
  isFetching,
  isLoading,
  pagination,
}: CampaignPerformanceDeliveredProps) => {
  const campaign = useOutletCampaignPerformance();
  const [currentOpportunityId, setCurrentOpportunityId] = useState<string | undefined>();
  const handleClose = () => setCurrentOpportunityId(undefined);
  const { ref: lastElementRef } = useInfiniteScroll({ fetchNextPage, hasNextPage, isFetching, isLoading });

  return (
    <>
      <div className="border-b border-gray-100 p-4 flex space-x-2">
        <div>
          <Tabs>
            <Tabs.Tab to="">Delivered</Tabs.Tab>
            <Tabs.Tab to="../upcoming">Upcoming</Tabs.Tab>
            <Tabs.Tab to="../pending">Pending</Tabs.Tab>
            <Tabs.Tab to="../declined_or_expired">Declined or Expired</Tabs.Tab>
          </Tabs>
        </div>
      </div>
      <div className="flex flex-col overflow-x-auto">
        <div className="overflow-x-auto w-full">
          <Table>
            <colgroup>
              <col width="10%" />
              <col width="20%" />
              <col width="20%" />
              <col width="10%" />
              <col width="10%" />
            </colgroup>
            <Table.Head>
              <Table.Row>
                <Table.Header>Publication ID</Table.Header>
                <Table.Header>Publication</Table.Header>
                <Table.Header>Post</Table.Header>
                <Table.Header>Version</Table.Header>
                <Table.Header>Date</Table.Header>
                <Table.Header align="right">Total Clicks</Table.Header>
                <Table.Header align="right">Payable Clicks</Table.Header>
                <Table.Header align="right">CPC</Table.Header>
                <Table.Header align="right">Opens</Table.Header>
                <Table.Header align="right">CTR</Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {opportunities.map((opportunity) => {
                const isLastRow = opportunities.length - 1 === opportunities.indexOf(opportunity);
                const campaignHasAutoPayout = campaign.payout_method === AdNetworkCampaignPayoutMethod.AUTO;
                const canEdit = campaignHasAutoPayout && opportunity.is_finalized;
                const isFinalized = opportunity.is_finalized;
                const isEdited =
                  isFinalized && opportunity.disbursement.approved_clicks !== opportunity.disbursement.actual_clicks;

                // Read from live data until finalized, then use the stored value
                const actualClicks = isFinalized
                  ? opportunity.disbursement.actual_clicks
                  : opportunity.total_unique_eligible_clicked;

                return (
                  <Table.Row key={opportunity.ad_network_opportunity_id} ref={isLastRow ? lastElementRef : null}>
                    <EditClicksModal
                      opportunity={opportunity}
                      modalOpen={currentOpportunityId === opportunity.ad_network_opportunity_id}
                      onClose={handleClose}
                    />
                    <Table.Cell>
                      <span className="font-mono">{opportunity.publication_alphanumeric_id}</span>
                    </Table.Cell>
                    <Table.Cell>
                      <Link
                        to={`/ad_network/publications/${opportunity.publication_id}`}
                        className="hover:underline"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {opportunity.publication_name}
                        {opportunity.banned && <span className="text-red-500"> (banned)</span>}
                      </Link>
                    </Table.Cell>
                    <Table.Cell muted>
                      <Link
                        to={`/posts/${opportunity.post_id}`}
                        className="hover:underline"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {opportunity.post_title}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>{opportunity.advertisement_option_name}</Table.Cell>
                    <Table.Cell>{moment(opportunity.date).format('MMM D, YYYY')}</Table.Cell>
                    <Table.Cell align="right">{formatter.format(opportunity.total_eligible_clicked)}</Table.Cell>
                    <Table.Cell align="right">
                      <div className="flex justify-end items-center space-x-2">
                        {isEdited ? (
                          <>
                            <span className="line-through text-gray-300 select-none">
                              {formatter.format(actualClicks)}
                            </span>
                            <span>{formatter.format(opportunity.disbursement.approved_clicks)}</span>
                          </>
                        ) : (
                          <span>{formatter.format(actualClicks)}</span>
                        )}
                        {canEdit && (
                          <button
                            type="button"
                            className="text-gray-500 hover:text-primary-500"
                            onClick={() => setCurrentOpportunityId(opportunity.ad_network_opportunity_id)}
                          >
                            <PencilSquareIcon className="w-4 h-4" />
                          </button>
                        )}
                      </div>
                    </Table.Cell>
                    <Table.Cell align="right">{opportunity.payout_per_click}</Table.Cell>
                    <Table.Cell align="right">{formatter.format(opportunity.total_unique_opened)}</Table.Cell>
                    <Table.Cell align="right">
                      {opportunity.total_unique_opened === 0
                        ? 0
                        : ((100 * actualClicks) / opportunity.total_unique_opened).toFixed(1)}
                      %
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      </div>
      <div className="sticky bottom-0">
        <div className="px-4 py-2 border-t border-gray-100 bg-white">
          <p className="text-sm text-gray-500">
            Showing {opportunities.length} of {pagination.total}
          </p>
        </div>
      </div>
    </>
  );
};

export default function Loader() {
  const { campaign_id: id } = useParams<'campaign_id'>() as { campaign_id: string };
  const response = useCampaignOpportunities({
    campaignId: id,
    filter: AdNetworkCampaignPerformanceFilter.DELIVERED,
  });

  const { data, isSuccess, isError, isLoading, fetchNextPage, hasNextPage, isFetching } = response;

  if (!isSuccess) return <LoadingListPage isLoading={isLoading} isError={isError} />;
  const opportunities = data.pages.flatMap((page) => page.opportunities);
  const { pagination } = data.pages[0];

  return (
    <CampaignPerformanceDelivered
      opportunities={opportunities}
      pagination={pagination}
      fetchNextPage={fetchNextPage}
      hasNextPage={!!hasNextPage}
      isFetching={isFetching}
      isLoading={isLoading}
    />
  );
}
