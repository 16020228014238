import React, { createContext, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import api from '../services/swarm';

interface IReleaseVersionContext {
  currentVersion?: string;
  latestVersion?: string;
  isOutdated: boolean;
}

const ReleaseVersionContext = createContext<IReleaseVersionContext | undefined>(undefined);

ReleaseVersionContext.displayName = 'ReleaseVersionContext';

const ReleaseVersionProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentVersion, setCurrentVersion] = useState<string | undefined>();
  const [latestVersion, setLatestVersion] = useState<string | undefined>();

  const isOutdated = Boolean(currentVersion && latestVersion && currentVersion !== latestVersion);

  const responseInterceptor = (response: AxiosResponse<any, any>) => {
    const releaseVersion = response.headers['x-release-version'];
    if (!releaseVersion || releaseVersion === 'not_set') return response;

    if (!currentVersion) {
      setCurrentVersion(releaseVersion);
      return response;
    }

    if (currentVersion !== releaseVersion) {
      setLatestVersion(releaseVersion);
    }

    return response;
  };

  useEffect(() => {
    const interceptor = api.interceptors.response.use(responseInterceptor);

    return () => {
      api.interceptors.response.eject(interceptor);
    };
  }, [currentVersion]);

  return (
    <ReleaseVersionContext.Provider
      value={{
        currentVersion,
        latestVersion,
        isOutdated,
      }}
    >
      {children}
    </ReleaseVersionContext.Provider>
  );
};

function useReleaseVersion() {
  const context = React.useContext(ReleaseVersionContext);
  if (context === undefined) {
    throw new Error(`useReleaseVersion must be used within a ReleaseVersionProvider`);
  }
  return context;
}

export { ReleaseVersionContext, ReleaseVersionProvider, useReleaseVersion };
