import Navbar from '../Navbar';

import NavigationV2 from './Navigation';

interface Props {
  largeNavOpen: boolean;
}

const NavbarV2 = ({ largeNavOpen }: Props) => {
  return (
    <>
      <Navbar className="sm:hidden" />
      <NavigationV2 largeNavOpen={largeNavOpen} className="hidden sm:flex" />
    </>
  );
};

export default NavbarV2;
