import { ChangeEvent } from 'react';
import { PlusIcon, TrashIcon } from '@heroicons/react/20/solid';

import Input from '@/components/Form/Input';
import IconButton from '@/components/IconHelpers/IconButton';
import { Typography } from '@/components/Typography';
import { Button } from '@/ui/Button';

import { usePreferencesContext } from '../../preferences/context';

const indexToAlphabet = (index: number) => (index + 1 + 9).toString(36).toUpperCase();

const OptionsForm = () => {
  const { editablePreference, setEditablePreference } = usePreferencesContext();

  const handleChangeInOption = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const newOptions = [...editablePreference.subscriber_preference_options];
    newOptions[index].value = e.currentTarget.value;
    setEditablePreference({
      ...editablePreference,
      subscriber_preference_options: newOptions,
    });
  };

  const handleClickDeleteOption = (index: number) => {
    const newOptions = [...editablePreference.subscriber_preference_options];
    // eslint-disable-next-line no-underscore-dangle
    newOptions[index]._destroy = true;
    setEditablePreference({
      ...editablePreference,
      subscriber_preference_options: newOptions,
    });
  };

  const handleClickAdd = () => {
    const newOptions = [...editablePreference.subscriber_preference_options];

    newOptions.push({
      id: '',
      deleted_at: null,
      created_at: null,
      updated_at: null,
      deleted_by_user_id: null,
      subscriber_preference_id: editablePreference.id,
      sort_order: newOptions.length,
      value: '',
    });

    setEditablePreference({
      ...editablePreference,
      subscriber_preference_options: newOptions,
    });
  };

  return (
    <div className="flex flex-col gap-y-2">
      {editablePreference.subscriber_preference_options
        // eslint-disable-next-line no-underscore-dangle
        .filter((preferenceOption) => preferenceOption._destroy !== true)
        .map((option, index) => (
          <div
            className="flex flex-row gap-x-2 items-center"
            // eslint-disable-next-line react/no-array-index-key
            key={`${index}-${option.id}`}
          >
            <Typography token="font-medium/text/xs">Option</Typography>
            <Input
              className="w-2/3"
              name={`option_${index}`}
              placeholderText={`Option ${indexToAlphabet(index)}`}
              type="text"
              value={option.value}
              onChange={handleChangeInOption(index)}
              required
            />
            <IconButton onClick={() => handleClickDeleteOption(index)}>
              <TrashIcon />
            </IconButton>
          </div>
        ))}
      <Button className="w-fit" variant="flush" size="xxs" type="button" Icon={PlusIcon} onClick={handleClickAdd}>
        Add option
      </Button>
    </div>
  );
};

export default OptionsForm;
