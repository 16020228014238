import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import {
  AcademicCapIcon,
  CodeBracketIcon,
  FilmIcon,
  LightBulbIcon,
  NewspaperIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import ActionModal from '@/components/ActionModal';
import Modal from '@/components/Modal';
import SlideOver from '@/components/SlideOver';
import Text from '@/components/Text';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useSettings } from '@/context/settings-context';
import { useSupportTicketModalParam } from '@/hooks';
import { ZendeskTicket } from '@/interfaces/zendesk/ticket';
import api from '@/services/swarm';

import ZendeskTicketForm from '../../../../ZendeskTicketForm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface SectionCardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  url: string;
}

const categories: any = [
  {
    id: '1',
    title: 'Knowledge Base',
    description:
      'Get the most out of beehiiv with articles on our features & functionality, and answers to commonly asked questions.',
    icon: <LightBulbIcon className="w-6 h-6 text-primary-300" />,
    url: 'https://support.beehiiv.com/hc/en-us',
  },
  {
    id: '2',
    title: 'Tutorials',
    description:
      'View our YouTube channel to learn how to get started on beehiiv and utilize all our features, directly from the beehiiv team',
    icon: <FilmIcon className="w-6 h-6 text-primary-300" />,
    url: 'https://www.youtube.com/channel/UCI80tsW3wYNGEJLxwJo42vw',
  },
  {
    id: '3',
    title: 'Course',
    description: 'Check out our course on how build, scale, and monetize your newsletter',
    icon: <AcademicCapIcon className="w-6 h-6 text-primary-300" />,
    url: 'https://www.beehiiv.com/courses/newsletter-xp',
  },
  {
    id: '4',
    title: 'Blog',
    description: 'Tips, tricks, and suggestions to help creators use beehiiv',
    icon: <NewspaperIcon className="w-6 h-6 text-primary-300" />,
    url: 'https://blog.beehiiv.com',
  },
  {
    id: '5',
    title: 'API Documentation',
    description: 'Build on beehiiv with our extensive API',
    icon: <CodeBracketIcon className="w-6 h-6 text-primary-300" />,
    url: 'https://developers.beehiiv.com/docs/v2',
  },
];

const SectionCard = ({ title, description, icon, url }: SectionCardProps) => {
  return (
    <button
      type="button"
      className="w-full relative flex items-center text-left rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
      onClick={() => window.open(url, '_blank', 'noreferrer noopener')}
    >
      <div className="flex justify-between items-start">
        <div className="pr-4">{icon}</div>
        <div>
          <p className="text-sm font-medium text-gray-900">{title}</p>
          <p className="text-sm text-gray-500">{description}</p>
        </div>
      </div>
    </button>
  );
};

const AnswerLinks = () => (
  <>
    Want answers sooner? We just launched an expansive{' '}
    <a
      className="text-primary-600 underline"
      href="https://support.beehiiv.com/hc/en-us"
      target="_blank"
      rel="noopener noreferrer"
    >
      Knowledge Base
    </a>{' '}
    that should provide answers to 95% of all questions. We recommend checking{' '}
    <a
      className="text-primary-600 underline"
      href="https://support.beehiiv.com/hc/en-us"
      target="_blank"
      rel="noopener noreferrer"
    >
      there
    </a>{' '}
    first.
  </>
);

const HelpPanel = ({ isOpen, onClose }: Props) => {
  const { settings } = useSettings();
  const [currentPublicationId] = useCurrentPublicationState();
  const shouldOpenSupportModal = useSupportTicketModalParam();
  const [awaitingConfirmation, setAwaitingConfirmation] = useState(false);
  const [confirmed, setConfirmed] = useState(shouldOpenSupportModal);
  const [submitted, setSubmitted] = useState(false);

  const createTicket = useMutation((formData: FormData) => api.post('/zendesk/tickets', formData), {
    onError: (errPayload: any) => {
      toast.error(errPayload?.response?.data?.error || 'Something went wrong');
    },
    onSuccess: () => {
      toast.success('Support ticket created successfully');
      setSubmitted(true);
    },
  });

  const onZendeskTicketFormSubmit = async (data: ZendeskTicket) => {
    /*
      Due to needing to upload an image
      we have to use multipart form instead of JSON
    */
    const formData = new FormData();
    formData.append('publication_id', currentPublicationId);

    formData.append('ticket[priority]', data.priority.toString());
    formData.append('ticket[reason]', data.reason.toString());
    formData.append('ticket[subject]', data.subject);
    formData.append('ticket[body]', data.body);
    if (data.attachment) formData.append('ticket[attachment]', data.attachment);

    await createTicket.mutateAsync(formData);
  };

  const closeForm = () => {
    setConfirmed(false);
  };

  return (
    <>
      <Modal
        isOpen={confirmed}
        includeCloseButton={false}
        onClose={closeForm}
        className="bg-white rounded-t-lg py-6 px-4 space-y-3 max-w-lg w-full z-50"
      >
        {submitted ? (
          <>
            <div className="flex justify-between mb-4">
              <Text size="lg" type="bold" className="text-gray-800 w-fit">
                Support Ticket Created
              </Text>
              <div>
                <button
                  className="rounded-sm p-1 border border-gray-200 text-gray-400 hover:bg-gray-200 transition-all hover:text-gray-600"
                  type="button"
                  onClick={closeForm}
                >
                  <XMarkIcon className="h-4 w-4" />
                </button>
              </div>
            </div>
            <Text size="sm" className="text-gray-600">
              <p className="mb-4">
                Your support request has been created successfully and you will receive a confirmation email shortly. A
                member of our support staff will respond to your request within 24 - 48 hours, pending your plan tier.
              </p>
              <AnswerLinks />
            </Text>
          </>
        ) : (
          <>
            <div className="flex justify-between mb-6">
              <Text size="lg" type="bold" className="text-gray-800 w-fit">
                New Support Ticket
              </Text>
              <div>
                <button
                  className="rounded-sm p-1 border border-gray-200 text-gray-400 hover:bg-gray-200 transition-all hover:text-gray-600"
                  type="button"
                  onClick={closeForm}
                >
                  <XMarkIcon className="h-4 w-4" />
                </button>
              </div>
            </div>
            <ZendeskTicketForm onSubmit={onZendeskTicketFormSubmit} />
          </>
        )}
      </Modal>
      <ActionModal
        isOpen={awaitingConfirmation}
        onClose={() => {
          setAwaitingConfirmation(false);
        }}
        resourceId=""
        headerText="File a Support Ticket"
        onProceed={() => {
          setAwaitingConfirmation(false);
          setConfirmed(true);
          setSubmitted(false);
        }}
        isWorking={false}
      >
        <p className="text-gray-600 text-sm">
          <span className="font-semibold text-gray-700">Expected wait time:</span> 24 - 48 hours, pending your plan
          tier.
          <div className="mt-1">
            <AnswerLinks />
          </div>
        </p>
      </ActionModal>
      <SlideOver isOpen={isOpen} onClose={onClose} headerText="Help" className="relative z-40" width="md:max-w-md">
        <>
          <div className="space-y-2 mb-6">
            {categories.map((category: any) => {
              return (
                <SectionCard
                  key={category.id}
                  title={category.title}
                  description={category.description}
                  icon={category.icon}
                  url={category.url}
                />
              );
            })}
          </div>

          {settings?.support_tickets && (
            <div className="absolute -bottom-2 right-6">
              <button
                onClick={() => setAwaitingConfirmation(true)}
                type="button"
                className="inline-flex text-xs text-gray-400 hover:text-gray-500 underline"
              >
                Support Ticket
              </button>
            </div>
          )}
        </>
      </SlideOver>
    </>
  );
};

export default HelpPanel;
