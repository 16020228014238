import { FC } from 'react';

import { Publication } from '../../../../../interfaces/publication';

import PublicationOption from './PublicationOption';

interface Props {
  isOpen: boolean;
  currentPublicationId: string;
  publications: Publication[];
  onSelectOption: (publication: Publication) => void;
}

const PublicationOptions: FC<Props> = (props) => {
  const { isOpen, currentPublicationId, publications, onSelectOption } = props;

  const className = isOpen
    ? 'absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm opacity-100 scale-100'
    : 'absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm opacity-0 scale-95 pointer-events-none';

  return (
    <ul aria-labelledby="location" aria-orientation="vertical" className={className} role="menu" tabIndex={-1}>
      {publications.map((publication) => (
        <PublicationOption
          key={publication.id}
          currentPublicationId={currentPublicationId}
          publication={publication}
          onSelectOption={onSelectOption}
        />
      ))}
    </ul>
  );
};

export default PublicationOptions;
