import { ChangeEvent, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Editor } from '@tiptap/react';

import { MediaLibrary } from '@/components/MediaLibrary';
import { ThreadComposer } from '@/components/TiptapEditor/components/panels/ThreadComposer';
import {
  ThreadComposerConsumer,
  ThreadComposerProvider,
} from '@/components/TiptapEditor/components/panels/ThreadComposer/Context';
import API from '@/components/TiptapEditor/lib/api';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useContentTags } from '@/hooks';
import useGuestAuthors from '@/hooks/useGuestAuthors';
import useUsers from '@/hooks/useUsers';
import { ContentTag } from '@/interfaces/content_tag';
import { Post } from '@/interfaces/post';
import api from '@/services/swarm';

import CreateGuestAuthorModal from './AuthorsMenu/CreateGuestAuthorModal';
import CreateContentTagModal from './ContentTags/CreateContentTagModal';
import AuthorsMenu from './AuthorsMenu';
import ContentTags from './ContentTags';
import DisplayDate from './DisplayDate';
import Subtitle from './Subtitle';
import Thumbnail from './Thumbnail';
import Title from './Title';

interface Props {
  editor: Editor;
  post: Post;
  setIsSaving: (isSaving: boolean) => void;
}

const PostMeta = ({ editor, post, setIsSaving }: Props) => {
  const [subTitleTippyMenusShown, setSubTitleTippyMenusShown] = useState(false);
  const [showCreateContentTagModal, setShowCreateContentTagModal] = useState(false);
  const [showCreateGuestAuthorModal, setShowCreateGuestAuthorModal] = useState(false);
  const [showSubtitle, setShowSubtitle] = useState(Boolean(post.web_subtitle?.length));
  const [isThumbnailUploading, setIsThumbnailUploading] = useState(false);
  const [postData, setPostData] = useState(post);
  const [showImageLibrary, setShowImageLibrary] = useState(false);
  const thumbnailFileRef = useRef<HTMLInputElement>(null);
  const [publicationId] = useCurrentPublicationState();
  const contentTagsQuery = useContentTags({ search: '' });
  const { data: contentTagsResp, isLoading: isLoadingContentTags, refetch: refetchContentTags } = contentTagsQuery;
  const { data: users, isLoading: isLoadingUsers, isError: isErrorUsers } = useUsers();
  const {
    data: guestAuthors,
    isLoading: isLoadingGuestAuthors,
    isError: isErrorGuestAuthors,
    refetch: refetchGuestAuthors,
  } = useGuestAuthors();

  const contentTags = (contentTagsResp?.pages[0]?.contentTags as unknown as ContentTag[]) || [];
  const usersEditable = !isLoadingUsers && !isErrorUsers && users && users.length > 0;
  const guestAuthorsEditable =
    !isLoadingGuestAuthors && !isErrorGuestAuthors && guestAuthors && guestAuthors.guest_authors.length > 0;

  const updatePost = async (data: Post) => {
    try {
      setIsSaving(true);
      await api.patch(`/posts/${data.id}`, { post: data });

      let extendedData = data;
      if (data.thumbnail_id === 'delete') {
        extendedData = { ...data, ...{ thumbnail_id: null, thumbnail: null } };
      }

      setPostData(extendedData);
      setIsSaving(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(`Error updating post with ${JSON.stringify(data)}: e`);
    }
  };

  const updateDisplayDate = (date: Date | null) => {
    const updateVal = date ? date.toISOString() : '';
    updatePost({ ...postData, ...{ override_scheduled_at: updateVal } });
  };

  const uploadThumbnail = (file: File) => {
    if (isThumbnailUploading) return;

    setIsThumbnailUploading(true);

    API.uploadPublicationAsset({
      file,
      publicationId,
    })
      .then(async (res) => {
        const { id } = res.data;

        await updatePost({ ...postData, ...{ thumbnail_id: id, thumbnail: res.data } });
      })
      .catch((errPayload) => {
        const error = errPayload?.response?.data?.error || 'Something went wrong';
        toast.error(error);
      })
      .finally(() => setIsThumbnailUploading(false));
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      uploadThumbnail(file);
    }
  };

  const onSubTitleTippyShow = () => {
    setSubTitleTippyMenusShown(true);
  };

  const onSubTitleTippyHide = () => {
    setSubTitleTippyMenusShown(false);
  };

  return (
    <>
      <ThreadComposerProvider>
        <ThreadComposerConsumer>
          {() => (
            <>
              <ThreadComposer editor={editor} />
              <div className="relative z-0 px-2">
                <div className="mx-auto my-6 max-w-3xl">
                  {post?.content_tag_ids.length > 0 && !isLoadingContentTags && (
                    <div className="mb-4 relative w-full">
                      <ContentTags
                        post={postData}
                        contentTags={contentTags}
                        updatePost={updatePost}
                        setShowCreateContentTagModal={setShowCreateContentTagModal}
                        refetchContentTags={refetchContentTags}
                      />
                    </div>
                  )}
                  {postData.thumbnail && (
                    <Thumbnail
                      post={postData}
                      updatePost={updatePost}
                      thumbnailFileRef={thumbnailFileRef}
                      setShowImageLibrary={setShowImageLibrary}
                    />
                  )}
                  <Title
                    guestAuthors={guestAuthors}
                    onSubTitleTippyHide={onSubTitleTippyHide}
                    onSubTitleTippyShow={onSubTitleTippyShow}
                    post={postData}
                    setShowCreateGuestAuthorModal={setShowCreateGuestAuthorModal}
                    setShowImageLibrary={setShowImageLibrary}
                    setShowSubtitle={setShowSubtitle}
                    showSubtitle={showSubtitle}
                    thumbnailFileRef={thumbnailFileRef}
                    updateDisplayDate={updateDisplayDate}
                    updatePost={updatePost}
                    users={users}
                    contentTags={contentTags}
                    setShowCreateContentTagModal={setShowCreateContentTagModal}
                    refetchContentTags={refetchContentTags}
                  />
                  {showSubtitle && (
                    <Subtitle
                      onSubTitleTippyHide={onSubTitleTippyHide}
                      onSubTitleTippyShow={onSubTitleTippyShow}
                      post={postData}
                      setShowSubtitle={setShowSubtitle}
                      subTitleTippyMenusShown={subTitleTippyMenusShown}
                      updatePost={updatePost}
                    />
                  )}
                  {(usersEditable || guestAuthorsEditable) && (
                    <div className="mt-12">
                      <AuthorsMenu
                        users={users || []}
                        post={postData}
                        guestAuthors={guestAuthors?.guest_authors || []}
                        updatePost={updatePost}
                        setShowCreateGuestAuthorModal={setShowCreateGuestAuthorModal}
                      />
                    </div>
                  )}
                  {postData.override_scheduled_at && (
                    <DisplayDate post={postData} updateDisplayDate={updateDisplayDate} />
                  )}
                </div>
              </div>
            </>
          )}
        </ThreadComposerConsumer>
      </ThreadComposerProvider>
      <CreateContentTagModal
        isOpen={showCreateContentTagModal}
        onClose={() => setShowCreateContentTagModal(false)}
        onSuccess={() => {
          setShowCreateContentTagModal(false);
          refetchContentTags();
        }}
      />
      <CreateGuestAuthorModal
        isOpen={showCreateGuestAuthorModal}
        onClose={() => setShowCreateGuestAuthorModal(false)}
        onSuccess={() => {
          refetchGuestAuthors();
          setShowCreateGuestAuthorModal(false);
        }}
      />
      <input
        className="w-0 h-0 overflow-hidden"
        onInput={onFileChange}
        type="file"
        accept=".jpg,.jpeg,.png,.webp,.gif"
        ref={thumbnailFileRef}
      />
      <MediaLibrary
        onClose={() => setShowImageLibrary(false)}
        isOpen={showImageLibrary}
        onMediaSelect={async (payload) => {
          await updatePost({ ...postData, ...{ thumbnail_id: payload.media.id, thumbnail: payload.media } });
          setShowImageLibrary(false);
        }}
        publicationId={publicationId}
      />
    </>
  );
};

export default PostMeta;
