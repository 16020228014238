import { useState } from 'react';
import toast from 'react-hot-toast';
import { UseQueryResult } from 'react-query';
import cx from 'classnames';

import TrialPlan from '@/components/Plan/PlanCard/Trial/TrialPlan';
import { useSettings } from '@/context/settings-context';
import { useWebTemplate } from '@/hooks';
import { usePublishHomePage } from '@/hooks/useHomePages';
import { usePublishLandingPage } from '@/hooks/useLandingPages';
import { useNavGroups } from '@/hooks/useNavGroups';
import usePlan from '@/hooks/usePlan';
import { usePremiumSettings } from '@/hooks/usePublications';
import { usePublishUpgradePage } from '@/hooks/useUpgradePages';
import { usePublishWebTheme } from '@/hooks/useWebTemplates';
import { NavGroup } from '@/interfaces/nav_group';
import { WebTemplate } from '@/interfaces/web_template';

import { PageProvider } from './_components/Context/PageContext';
import GeneralSettings from './_components/GeneralSettings';
import HomePageSettings from './_components/HomePage';
import Layout from './_components/Layout';
import IframeRenderer from './_components/Layout/IframeRenderer';
import UpgradePageDecisionTree from './_components/Layout/UpgradePageDecisionTree';
import SignupPageSettings from './_components/SignupPage';
import UpgradePageSettings from './_components/UpgradePage';
import usePageNavigator, { PageTypeObject } from './_hooks/usePageNavigator';
import useTabNavigator from './_hooks/useTabNavigator';
import NavigationLayoutSettings from './custom_pages/_components/NavigationLayoutSettings';

export interface LayoutPageData {
  navGroups: NavGroup[] | undefined;
  webTemplate: WebTemplate | undefined;
  currentPage: PageTypeObject;
}

const LayoutPage = () => {
  const navGroupsQuery = useNavGroups();
  const { data: navGroups } = navGroupsQuery;
  const { plan } = usePlan();
  const { settings } = useSettings();
  const { isLayoutTab } = useTabNavigator();

  const webTemplateRequest = useWebTemplate();
  const { data: webTemplate }: UseQueryResult<WebTemplate, Error> = webTemplateRequest;

  const { data: premiumSettings } = usePremiumSettings();
  const isPremiumActive = premiumSettings?.premium_tier_active;

  const [publishId, setPublishId] = useState<string>('');
  const { page, pageType } = usePageNavigator();
  const { isStylesTab } = useTabNavigator();

  const isSignupPage = pageType === 'signup_page' && !isStylesTab;
  const isUpgradePage = pageType === 'upgrade_page' && !isStylesTab;
  const isHomePage = pageType === 'home_page' && !isStylesTab;
  const isTrialCardShowing = (isSignupPage || isUpgradePage || isHomePage) && plan === 'trial';

  const disablePage = pageType === 'upgrade_page' && !isPremiumActive;

  const disablePublishActions = isLayoutTab && plan === 'launch' && !settings?.landing_pages;

  const publishWebTheme = usePublishWebTheme({
    onSuccess: () => {
      toast.success('Published!');
    },
  });

  const publishLandingPage = usePublishLandingPage({
    pageId: isSignupPage ? publishId : '',
    onSuccess: () => {
      toast.success('Published!');
    },
  });

  const publishHomePage = usePublishHomePage({
    pageId: isHomePage ? publishId : '',
    onSuccess: () => {
      toast.success('Published!');
    },
  });

  const publishUpgradePage = usePublishUpgradePage({
    pageId: isUpgradePage ? publishId : '',
    onSuccess: () => {
      toast.success('Published!');
    },
  });

  const isPublishing =
    publishWebTheme.isLoading ||
    publishLandingPage.isLoading ||
    publishHomePage.isLoading ||
    publishUpgradePage.isLoading;

  const handlePublish = () => {
    if (isStylesTab) {
      publishWebTheme.mutate();
    } else if (isSignupPage) {
      publishLandingPage.mutate();
    } else if (isHomePage) {
      publishHomePage.mutate();
    } else if (isUpgradePage) {
      publishUpgradePage.mutate();
    }
  };

  const handlePublishCTA = () => {
    if (isStylesTab) {
      return 'Publish Styles';
    }

    if (isSignupPage) {
      return 'Publish Signup Page';
    }

    if (isHomePage) {
      return 'Publish Home Page';
    }

    if (isUpgradePage) {
      return 'Publish Upgrade Page';
    }

    return 'Publish';
  };

  return (
    <PageProvider<LayoutPageData>
      pageData={{
        navGroups,
        webTemplate,
        currentPage: page,
      }}
    >
      <Layout
        removePadding
        onPublish={handlePublish}
        isPublishing={isPublishing}
        publishCtaText={handlePublishCTA()}
        disablePublishActions={disablePublishActions}
        sidePanelChildren={
          <div className={cx(!isStylesTab && 'pt-6')}>
            {isHomePage && <NavigationLayoutSettings />}
            {isStylesTab && <GeneralSettings />}
            {isSignupPage && <SignupPageSettings setPublishId={setPublishId} />}
            {isUpgradePage && <UpgradePageSettings setPublishId={setPublishId} />}
            {isHomePage && <HomePageSettings setPublishId={setPublishId} />}
            {isTrialCardShowing && !disablePage && <TrialPlan />}
          </div>
        }
      >
        <IframeRenderer
          hideUrlBar={disablePage}
          overrideIframeChildren={disablePage ? <UpgradePageDecisionTree isPremiumActive={isPremiumActive} /> : null}
        />
      </Layout>
    </PageProvider>
  );
};

export default LayoutPage;
