import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

import { DeviceGate } from '@/ui/DeviceGate';

import { SideNav } from '../SideNav';

import { BackButton } from './BackButton';
import { TopNav } from './TopNav';
import { WebsiteHostname } from './WebsiteHostname';

type Props = PropsWithChildren<{
  sidenavChildren?: React.ReactNode;
  backPath: string;
}>;

export const Layout = ({ children, sidenavChildren, backPath }: Props) => {
  const navigate = useNavigate();

  return (
    <DeviceGate reason="The feature is currently not supported on mobile devices">
      <div className="h-screen w-screen flex flex-col">
        <TopNav>
          <div className="flex justify-start gap-4">
            <BackButton
              onClick={() => {
                navigate(backPath);
              }}
            />
            <WebsiteHostname />
          </div>
        </TopNav>

        <div className="bg-wb-primary flex-grow flex overflow-auto">
          <SideNav>{sidenavChildren}</SideNav>
          <div className="w-full h-full overflow-y-auto p-7">{children}</div>
        </div>
      </div>
    </DeviceGate>
  );
};
