import { PropsWithChildren } from 'react';

import { Text } from '../../_components/UI/Text';

type Props = PropsWithChildren<{
  title: string;
  description?: string;
}>;

const SettingsItem = ({ title, description, children }: Props) => {
  return (
    <div className="flex flex-col space-y-4 py-6">
      <div className="flex flex-col space-y-2 max-w-[620px]">
        <div className="flex-1">
          <Text size="md" weight="medium" variant="primary" as="h4">
            {title}
          </Text>
        </div>
        <Text size="sm" weight="medium" variant="secondary" as="p">
          {description}
        </Text>
      </div>
      {children}
    </div>
  );
};

export default SettingsItem;
