import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import Tippy from '@tippyjs/react';

import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { ContentTag } from '@/interfaces/content_tag';
import { Post } from '@/interfaces/post';

import ContentTagsActionsMenu from './ContentTagsActionsMenu';

interface Props {
  post: Post;
  contentTags?: ContentTag[];
  updatePost: (data: Post) => void;
  setShowCreateContentTagModal: (value: boolean) => void;
  refetchContentTags(): void;
}

const ContentTags = ({ contentTags, post, updatePost, setShowCreateContentTagModal, refetchContentTags }: Props) => {
  const [showActionMenus, setShowActionMenus] = useState(false);
  const [tippyMenusShown, setTippyMenusShown] = useState(false);
  const currentContentTags = contentTags?.filter((tag) => post.content_tag_ids.includes(tag.id));

  const onMouseEnter = () => {
    setShowActionMenus(true);
  };

  const onMouseLeave = () => {
    if (!tippyMenusShown) {
      setShowActionMenus(false);
    }
  };

  const onTippyShow = () => {
    setTippyMenusShown(true);
  };

  const onTippyHide = () => {
    setTippyMenusShown(false);
  };

  return (
    <div className="relative" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Transition
        show={showActionMenus}
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute -ml-10 z-50">
          <Tippy
            offset={[0, 8]}
            placement="bottom-start"
            popperOptions={{
              modifiers: [{ name: 'flip' }],
            }}
            trigger="click"
            interactive
            onShow={onTippyShow}
            onHide={onTippyHide}
            content={
              <div className="absolute z-50">
                <ContentTagsActionsMenu
                  contentTags={contentTags}
                  post={post}
                  updatePost={updatePost}
                  setShowCreateContentTagModal={setShowCreateContentTagModal}
                  refetchContentTags={refetchContentTags}
                />
              </div>
            }
          >
            <div>
              <Button $leftSlot={<Icon name="DragMenu" />} $variant="tertiary" $size="small" $isIconButton />
            </div>
          </Tippy>
        </div>
      </Transition>
      <div className="relative cursor-pointer">
        <ul className="flex w-full">
          {currentContentTags &&
            currentContentTags.length > 0 &&
            currentContentTags?.map((tag) => (
              <li key={tag.id}>
                <div className="flex items-center justify-center overflow-hidden text-ellipsis border border-1 px-6 py-1 rounded-2xl text-sm font-semibold mr-2 text-gray-900 border-gray-300 bg-gray-100">
                  {tag.display}
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default ContentTags;
