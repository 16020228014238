export enum BoostAgreementStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  INACTIVE = 'inactive',
  PAUSED = 'paused',
  PENDING = 'pending',
  REJECTED = 'rejected',
}

export default BoostAgreementStatus;
