import * as React from 'react';

import { cn } from '../../_utils/cn';

import InputWrapper from './InputWrapper';

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  labelText?: string;
  helperText?: string | React.ReactNode;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, labelText, helperText, ...props }, ref) => {
    return (
      <InputWrapper labelText={labelText} helperText={helperText}>
        <textarea
          className={cn(
            'flex min-h-[80px] w-full rounded-md border border-wb-primary bg-wb-primary px-3 py-2 text-sm ring-offset-background placeholder:text-wb-tertiary focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
            className
          )}
          ref={ref}
          {...props}
        />
      </InputWrapper>
    );
  }
);
Textarea.displayName = 'Textarea';

export { Textarea };
