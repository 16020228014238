import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Howl } from 'howler';

import { SimpleSelect, Switch } from '@/components/Form';
import IconButton from '@/components/IconHelpers/IconButton';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import Tooltip from '@/components/Tooltip';
import { useElevenlabsVoices, usePostTextToSpeechConfig, useUpdatePostTextToSpeechConfig } from '@/hooks';
import { ElevenlabsVoice, Post } from '@/interfaces/post';
import { cn } from '@/utils/cn';

interface Props {
  postId: Post['id'];
}

export const TextToSpeechConfig: FC<Props> = ({ postId }) => {
  const { data: textToSpeechConfig, isLoading, refetch } = usePostTextToSpeechConfig(postId);

  const { data: voicesData = [] } = useElevenlabsVoices();

  const voices = Array.isArray(voicesData) ? voicesData : [];

  const [formData, setFormData] = useState(textToSpeechConfig);

  const voiceSamplePlayer = useRef<Howl>();

  const playSample = useCallback((voice: ElevenlabsVoice) => {
    voiceSamplePlayer.current?.unload();

    const howl = new Howl({
      src: [voice.preview_url],
      loop: false,
      volume: 0.5,
    });

    howl.play();

    voiceSamplePlayer.current = howl;
  }, []);

  useEffect(() => setFormData(textToSpeechConfig), [textToSpeechConfig]);

  const postTextToSpeechConfigMutation = useUpdatePostTextToSpeechConfig({ postId, onError: refetch });

  const onChange = useCallback(
    async (data: any) => {
      setFormData({ ...formData, ...data });

      await postTextToSpeechConfigMutation.mutateAsync(data);

      refetch();
    },
    [formData, postTextToSpeechConfigMutation, refetch]
  );

  if (isLoading) {
    return (
      <div className="flex items-center">
        <LoadingSpinner className="mr-2" />
      </div>
    );
  }

  return (
    <div className="space-y-8 !mb-60">
      <div className={cn('text-sm font-medium text-gray-700 flex gap-1 items-center')}>
        <span>Audio Newsletter Settings</span>

        <Tooltip
          id="tts-config-info"
          text="Audio will capture the entire post content, regardless of post visibility settings. It might take 5-10 minutes to generate the audio transcript."
        />
      </div>

      <Switch
        size="small"
        name="text_to_speech_config_enabled"
        labelText="Add an audio transcript to your post"
        helperText="Readers will see a 'listen online' option in email header to access the audio transcript at the top of the published web post"
        checked={!!formData?.enabled}
        onChange={(_, value: boolean) => onChange({ enabled: value })}
      />

      {!!formData?.enabled && (
        <SimpleSelect
          disabled={!formData?.enabled}
          name="elevenlabs-voice-id"
          labelText="Choose Voice"
          required
          value={formData?.voice_id || ''}
          onSelect={(_name: string, value: string) => onChange({ voice_id: value })}
          options={voices?.map((v) => {
            return {
              value: v.voice_id,
              label: v.name,
              optionAction: (
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    playSample(v);
                  }}
                >
                  <Icon name="Volume" />
                </IconButton>
              ),
            };
          })}
          emptyLabel="No voices available"
          placeholderText="Select a Voice"
        />
      )}
    </div>
  );
};
