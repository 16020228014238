import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
  ArrowTrendingUpIcon as ArrowTrendingUpIconSolid,
  BanknotesIcon as BanknotesIconSolid,
  ChartBarIcon as ChartBarIconSolid,
  DocumentTextIcon as DocumentTextIconSolid,
  Squares2X2Icon as Squares2X2IconSolid,
  SwatchIcon as SwatchIconSolid,
  UserGroupIcon as UserGroupIconSolid,
} from '@heroicons/react/20/solid';
import {
  ArrowTrendingUpIcon,
  BanknotesIcon,
  ChartBarIcon,
  CogIcon,
  DocumentTextIcon,
  Squares2X2Icon,
  SwatchIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import cx from 'classnames';

import { PlanCard } from '@/components/Plan';
import { Typography } from '@/components/Typography';
import { useSettings } from '@/context/settings-context';

import PublicationDropdown from '../PublicationDropdown';

import NavMenu from './NavMenu';
import { NavParentItem } from './NavParentItem';
import { NavSection } from './NavSection';
import NavStartWriting from './NavStartWriting';

interface Props {
  largeNavOpen: boolean;
  className?: string;
}

const NavigationV2 = ({ largeNavOpen, className }: Props) => {
  const [navMenuOpen, setNavMenuOpen] = useState<string>('');
  const [searchParams] = useSearchParams();
  const { settings } = useSettings();
  const hasHiivDream = settings?.hiiv_dream;

  // WalkthroughPortal
  const openForWalkthrough = (step: string) => {
    return searchParams.get('walkthrough_step') === step;
  };

  return (
    <nav className={cx('h-screen flex-col hidden', className)}>
      <PublicationDropdown largeNavOpen={largeNavOpen} />
      <NavSection largeNavOpen={largeNavOpen}>
        <div className="flex h-auto flex-col space-y-2">
          <NavParentItem
            largeNavOpen={largeNavOpen}
            title="Dashboard"
            BoldIcon={Squares2X2IconSolid}
            Icon={Squares2X2Icon}
            to="/"
          />
          <div className="py-2">
            <NavStartWriting largeNavOpen={largeNavOpen} />
          </div>
          <NavParentItem
            largeNavOpen={largeNavOpen}
            title="Posts"
            Icon={DocumentTextIcon}
            BoldIcon={DocumentTextIconSolid}
            to="/posts"
          />
          <NavMenu
            navMenuOpen={navMenuOpen}
            forceOpen={openForWalkthrough('3') || openForWalkthrough('5')}
            setNavMenuOpen={setNavMenuOpen}
            id="audience"
            links={[
              { title: 'Subscribers', to: '/subscribers' },
              { title: 'Segments', to: '/segments' },
              { title: 'Automations', to: '/automations' },
              { title: 'Polls', to: '/polls' },
              { title: 'Surveys', to: '/forms' },
              { title: 'Subscribe Forms', to: '/settings/publication/subscribe_forms' },
            ]}
            largeNavOpen={largeNavOpen}
            title="Audience"
            Icon={UserGroupIcon}
            BoldIcon={UserGroupIconSolid}
          />
          <NavMenu
            id="grow"
            navMenuOpen={navMenuOpen}
            setNavMenuOpen={setNavMenuOpen}
            links={[
              { title: 'Referral Program', to: '/referral_program/overview' },
              { title: 'Recommendations', to: '/recommendations' },
              { title: 'Magic Links', to: '/magic_links' },
              { title: 'Boosts', to: '/grow/boosts' },
            ]}
            largeNavOpen={largeNavOpen}
            title="Grow"
            Icon={ArrowTrendingUpIcon}
            BoldIcon={ArrowTrendingUpIconSolid}
          />
          <NavMenu
            id="monetize"
            navMenuOpen={navMenuOpen}
            setNavMenuOpen={setNavMenuOpen}
            links={[
              { title: 'Ads', to: '/monetize/ads' },
              { title: 'Subscriptions', to: '/monetize/subscriptions' },
              { title: 'Boosts', to: '/monetize/boosts' },
            ]}
            largeNavOpen={largeNavOpen}
            title="Monetize"
            Icon={BanknotesIcon}
            BoldIcon={BanknotesIconSolid}
          />
          <NavMenu
            id="design"
            navMenuOpen={navMenuOpen}
            forceOpen={openForWalkthrough('7')}
            setNavMenuOpen={setNavMenuOpen}
            links={[
              { title: 'Website Builder', to: '/website_builder' },
              ...(hasHiivDream ? [{ title: 'Website Builder V2', to: '/website_builder_v2' }] : []),
              { title: 'Newsletter Builder', to: '/post_themes/edit' },
            ]}
            largeNavOpen={largeNavOpen}
            title="Design"
            Icon={SwatchIcon}
            BoldIcon={SwatchIconSolid}
          />
          <NavMenu
            id="analyze"
            navMenuOpen={navMenuOpen}
            setNavMenuOpen={setNavMenuOpen}
            links={[
              { title: 'Subscribers Report', to: '/reports/subscriber' },
              { title: 'Posts Report', to: '/reports/post' },
              { title: 'Clicks Report', to: '/reports/click' },
            ]}
            largeNavOpen={largeNavOpen}
            title="Analyze"
            Icon={ChartBarIcon}
            BoldIcon={ChartBarIconSolid}
          />
        </div>
      </NavSection>
      {largeNavOpen && !navMenuOpen && (
        <div className="p-4 pb-[68px]">
          <PlanCard />
        </div>
      )}

      <div
        className={cx(
          'flex flex-col fixed left-0 bottom-0  bg-white border-r border-gray-200 transition-all',
          largeNavOpen ? 'w-[230px]' : 'w-[60px]'
        )}
      >
        <hr className="w-full" />
        <div className={cx('p-2 w-full', largeNavOpen ? 'pl-4' : 'pl-2')}>
          <Link
            to="/settings"
            className={cx(
              'flex flex-row space-x-2 p-2 rounded-lg hover:bg-surface-100',
              !largeNavOpen && 'aspect-1 flex flex-col justify-center items-center',
              largeNavOpen ? 'w-full' : 'w-fit mx-auto'
            )}
          >
            <CogIcon className="h-4 w-4 text-surface-900 my-auto" />
            {largeNavOpen && (
              <Typography token="font-medium/text/sm" className="my-auto text-gray-700">
                Settings
              </Typography>
            )}
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default NavigationV2;
