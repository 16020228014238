import { Route, Routes } from 'react-router-dom';

import Layout from './routes/advertiser_access/_layout';
import Campaign from './routes/advertiser_access/$advertiser_id/campaign_reports/$campaign_id';

const App = () => {
  return (
    <Routes>
      <Route path=":advertiser_id">
        <Route element={<Layout />}>
          <Route path="campaign_reports">
            <Route path=":campaign_id" element={<Campaign />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
