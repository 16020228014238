import React from 'react';
import toast from 'react-hot-toast';

import { useWebsiteContext } from '@/context/website-context';
import { useUpdateSiteVersion } from '@/hooks/useSiteVersion';

import { Switch } from '../_components/UI/Switch';

import SettingsItem from './_components/SettingsItem';
import SettingsSection from './_components/SettingsSection';

const WebsiteSettingsGeneral = () => {
  const { site } = useWebsiteContext();
  const updateSiteVersion = useUpdateSiteVersion({
    id: site?.draft_site_version?.id || '',
    publishOnUpdate: true,
    onSuccess: () => {
      toast.success('Saved!');
    },
  });

  return (
    <div>
      <SettingsSection>
        <SettingsItem
          title="Notifications"
          description="Notify subscribers when events occur through a notification bell icon at the top, right-hand corner of your navbar. This may help increase community engagement."
        >
          <Switch
            id="notifications_enabled"
            labelText="Enable notifications"
            checked={site?.draft_site_version?.settings?.notifications_enabled || false}
            onCheckedChange={(value: boolean) =>
              updateSiteVersion.mutate({
                settings: {
                  notifications_enabled: value,
                },
              })
            }
          />
        </SettingsItem>
      </SettingsSection>

      <SettingsSection>
        <SettingsItem
          title="Mobile App"
          description="Enable mobile app installation for your subscribers. This will allow your subscribers to download a native mobile app directly onto their Home Screen and benefit from features like longer login sessions, push notifications, and more."
        >
          <Switch
            id="pwa_enabled"
            labelText="Enable mobile app"
            checked={site?.draft_site_version?.settings?.pwa_enabled || false}
            onCheckedChange={(value: boolean) =>
              updateSiteVersion.mutate({
                settings: {
                  pwa_enabled: value,
                },
              })
            }
          />
        </SettingsItem>
      </SettingsSection>

      <SettingsSection>
        <SettingsItem
          title="Security"
          description="Enable to prevent spam submission via Turnstile, Cloudfare’s CAPTCHA solution, on your website and embed forms. It will be invisible on the page so user experience will not be impacted."
        >
          <Switch
            id="captcha_enabled"
            labelText="Enable Stricter Site Security"
            checked={site?.draft_site_version?.settings?.captcha_enabled || false}
            onCheckedChange={(value: boolean) =>
              updateSiteVersion.mutate({
                settings: {
                  captcha_enabled: value,
                },
              })
            }
          />
        </SettingsItem>
      </SettingsSection>
    </div>
  );
};

export default WebsiteSettingsGeneral;
