import { PropsWithChildren } from 'react';

import { cn } from '../../_utils/cn';

import { Label } from './Label';
import { Text } from './Text';

export type InputWrapperProps = PropsWithChildren<{
  labelText?: string;
  helperText?: string | React.ReactNode;
  name?: string;
  className?: string;
}>;

const InputWrapper = ({ labelText, helperText, children, name, className }: InputWrapperProps) => {
  return (
    <div className={cn('flex flex-col gap-1.5', className)}>
      {labelText && <Label htmlFor={name}>{labelText}</Label>}
      {children}
      {helperText && (
        <Text size="xs" weight="medium" variant="secondary" as="span">
          {helperText}
        </Text>
      )}
    </div>
  );
};

export default InputWrapper;
