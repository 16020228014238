import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import LoadingBox from '@/components/LoadingBox';
import { useCurrentPublicationState } from '@/context/current-publication-context';
import { useCustomField } from '@/hooks';
import api from '@/services/swarm';

import CustomFieldForm from '../../_components/CustomFieldForm';

interface RouteParams {
  customFieldId: string;
}

const EditCustomField = () => {
  const { customFieldId: id } = useParams() as unknown as RouteParams;
  const [currentPublicationId] = useCurrentPublicationState();
  const navigate = useNavigate();

  const { data, isLoading, isError } = useCustomField(id);

  const updateCustomFieldMutation = useMutation(
    ({ display, kind }: { display: string; kind: string }) =>
      api.patch(`/custom_fields/${id}`, {
        publication_id: currentPublicationId,
        custom_field: {
          display,
          kind,
        },
      }),
    {
      onError: (errPayload: any) => {
        toast.error(errPayload?.response?.data?.error || 'Something went wrong');
      },
      onSuccess: () => {
        toast.success('Custom Field updated successfully');
        navigate(`/subscribers/custom_fields`);
      },
    }
  );

  const onSubmit = async (display: string, kind: string) => {
    await updateCustomFieldMutation.mutateAsync({ display, kind });
  };

  return (
    <LoadingBox isLoading={isLoading} isError={isError}>
      {data && (
        <CustomFieldForm
          onSubmit={onSubmit}
          onCancel={() => navigate('/subscribers/custom_fields')}
          header="Edit Custom Field"
          custom_field={{ display: data?.display, kind: data?.kind }}
        />
      )}
    </LoadingBox>
  );
};

export default EditCustomField;
