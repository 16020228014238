import { useState } from 'react';
import cx from 'classnames';

import { useTrialStatus } from '@/hooks/usePublicationDashboard';
import useCreateTrial from '@/routes/onboarding/_hooks/useCreateTrial';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import { getLowestTier, plans } from '../../utils/tiered_plans';
import Modal from '../Modal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const UpgradeIntent: React.FC<Props> = ({ isOpen, onClose }) => {
  const [maxSubscribers, setMaxSubscribers] = useState(100);
  const [billingInterval, setBillingInterval] = useState<'monthly' | 'yearly'>('monthly');

  const trialStatusQuery = useTrialStatus();
  const { data: trialStatus } = trialStatusQuery || {};
  const trialEligible = trialStatus?.trial_eligible;
  const createTrial = useCreateTrial();

  const startTrial = () => {
    createTrial.mutate(
      { selectedPlan: 'scale' },
      {
        onSuccess: () => {
          analytics.track('Trial Started', {
            state: 'trialing',
            current_plan: 'launch',
            trial_plan: 'scale',
          });

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        },
      }
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="max-w-5xl w-full">
      <div className="p-8">
        <div className="space-y-4 mb-8">
          <div>
            <h1 className="text-2xl leading-6 font-bold text-gray-900 mb-1">The newsletter suite of your dreams ✨</h1>
            <p className="text-base text-gray-500">Simple pricing. Predictable. Built for you.</p>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <label className="flex flex-col space-y-2" htmlFor="subscribers">
              <span className="text-lg">How many subscribers?</span>
              <select
                className="border-gray-900 border-2 rounded p-2"
                onChange={(e) => setMaxSubscribers(Number(e.target.value))}
                value={maxSubscribers}
              >
                <option value={100}>less than 100</option>
                <option value={500}>less than 500</option>
                <option value={1000}>less than 1,000</option>
                <option value={2500}>less than 2,500</option>
                <option value={5000}>less than 5,000</option>
                <option value={10000}>less than 10,000</option>
                <option value={25000}>less than 25,000</option>
                <option value={50000}>less than 50,000</option>
                <option value={75000}>less than 75,000</option>
                <option value={100000}>less than 100,000</option>
                <option value={100001}>more than 100,000</option>
              </select>
            </label>
            <label className="flex flex-col space-y-2" htmlFor="subscribers">
              <span className="text-lg">How would you like to pay?</span>
              <select
                className="border-gray-900 border-2 rounded p-2"
                onChange={(e) => setBillingInterval(e.target.value as 'monthly' | 'yearly')}
                value={billingInterval}
              >
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </select>
            </label>
          </div>

          <div className="border-gray-900 border-2 rounded grid divide-x-2 divide-y-2 lg:divide-y-0 divide-gray-300 grid-cols-1 lg:grid-cols-4">
            {plans.map((plan) => {
              const tier = getLowestTier(plan.id, maxSubscribers);
              const disabled = tier.disabled && plan.id !== 'enterprise';

              return (
                <div className={cx('p-4 space-y-4', !!disabled && 'opacity-25 cursor-not-allowed')} key={plan.id}>
                  <p className="uppercase font-black space-x-2 flex items-center text-xl">
                    {plan.logo}
                    <span>{plan.name}</span>
                  </p>
                  {billingInterval === 'monthly' ? (
                    <div>
                      <p className="font-bold space-x-2 text-2xl">{tier.pricePerMonthBilledMonthly}</p>
                      <p className="text-gray-600">
                        {plan.id === 'launch' ? (
                          <span>No commitment</span>
                        ) : (
                          // eslint-disable-next-line react/jsx-no-useless-fragment
                          <>{plan.id !== 'enterprise' ? <span>billed monthly</span> : <span>&nbsp;</span>}</>
                        )}
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p className="font-bold space-x-2 text-2xl">{tier.pricePerMonthBilledAnnually}</p>
                      <p className="text-gray-600">
                        {tier.pricePerYearBilledAnnually}
                        {plan.id === 'enterprise' && <span>&nbsp;</span>}
                      </p>
                    </div>
                  )}
                  <p className="font-bold">{plan.inclusionDescription}</p>
                  <ul className="list-disc">
                    {plan.features.map((f) => (
                      <li className="flex items-center space-x-2" key={f}>
                        <div className="w-2 h-2 rounded-full bg-primary-500" />
                        <span className="line-clamp-1">{f}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex space-x-2 justify-end">
          {trialEligible ? (
            <Button block onClick={startTrial} loading={createTrial.isLoading || createTrial.isSuccess}>
              <span className="text-lg">Start a Scale Trial</span>
            </Button>
          ) : (
            <Button to="/settings/billing" block>
              <span className="text-lg">Go to Billing</span>
            </Button>
          )}
        </div>
        <p className="text-sm text-gray-500 text-center mt-4">
          <span>Learn more about </span>
          <a
            className="underline hover:text-primary-500"
            href="https://www.beehiiv.com/enterprise"
            target="_blank"
            rel="noopener noreferrer"
          >
            enterprise plans
          </a>
        </p>
      </div>
    </Modal>
  );
};

export default UpgradeIntent;
