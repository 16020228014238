import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import { Organization } from '@/interfaces/organization';
import { Button } from '@/ui/Button';

import { useAuth } from '../../context/auth-context';
import { useCurrentUser } from '../../context/current-user-context';

interface Props {
  org?: Organization;
}

const MasqueradeBanner = ({ org }: Props) => {
  const navigate = useNavigate();
  const { currentUser, reloadUser } = useCurrentUser();
  const { isMasquerading, endMasquerade } = useAuth();
  const { flagged_for_review: flaggedForReview = false, sending_enabled: sendingEnabled = true } = org || {};
  const [isHidden, setIsHidden] = useState(false);

  const getAccountState = () => {
    let accountState: string;

    if (!sendingEnabled) {
      accountState = 'Blocked';
    } else if (flaggedForReview) {
      accountState = 'Flagged for Manual Review';
    } else {
      accountState = 'Active';
    }

    return accountState;
  };

  if (isHidden || !isMasquerading || !currentUser) return null;

  const handleEndMasquerade = () => {
    endMasquerade
      .mutateAsync()
      .then(() => {
        reloadUser();
      })
      .then(() => {
        navigate(`/system_admin/organizations?query=${currentUser.id}`);
      });
  };

  return (
    <div className="sticky top-0 z-80">
      <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 flex items-center justify-between">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">
              You are masquerading as {currentUser.first_name} {currentUser.last_name}.
              <span className="font-bold"> Please Note: </span>This Account is currently &quot;{getAccountState()}&quot;
            </p>
          </div>
        </div>
        <div>
          <Button variant="primary-inverse" className="mr-2" onClick={() => setIsHidden(true)}>
            Hide
          </Button>
          <Button variant="primary-inverse" onClick={handleEndMasquerade}>
            Stop masquerading
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MasqueradeBanner;
