import { useCurrentPublication } from '@/hooks';

import { Text } from '../UI/Text';

export const WebsiteHostname = () => {
  const { data: currentPublication } = useCurrentPublication();

  return (
    <div className="flex gap-2 items-center">
      <div className="w-7 h-7 min-w-7 min-h-7 rounded overflow-hidden">
        {currentPublication?.logo?.thumb?.url ? (
          <img className="h-7 w-7" src={currentPublication?.logo?.thumb?.url} alt="" />
        ) : (
          <div className="bg-wb-secondary h-4 w-4 rounded" />
        )}
      </div>
      <Text variant="secondary" size="sm">
        {currentPublication?.hostname}
      </Text>
    </div>
  );
};
