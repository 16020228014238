import { useState } from 'react';

import analytics from '@/utils/analytics';

import { useCurrentPublicationState } from '../context/current-publication-context';
import { useCurrentUser } from '../context/current-user-context';
import { IntentAction } from '../interfaces/upgrades';

import usePublication from './usePublications/usePublication';

interface Props {
  intentAction?: IntentAction;
}

const useUpgradeIntent = ({ intentAction }: Props) => {
  const { currentUser } = useCurrentUser();
  const [currentPublicationId] = useCurrentPublicationState();
  const [isOpen, setIsOpen] = useState(false);
  const { data: currentPublication } = usePublication(currentPublicationId);

  const pushDataLayerEvent = () => {
    analytics.track('Upgrade Intent', {
      user_id: currentUser?.id,
      email: currentUser?.email,
      intent_action: intentAction,
      current_plan: currentPublication?.plan_name,
    });
  };

  const handleOpen = () => {
    setIsOpen(true);
    pushDataLayerEvent();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    handleOpen,
    handleClose,
    pushDataLayerEvent,
  };
};

export default useUpgradeIntent;
