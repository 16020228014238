import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  useWebsiteDefaultRoute,
  useWebsitePageList,
  useWebsitePageRoute,
  useWebsitePageRouteGetter,
} from '@/context/website-context';
import { Page } from '@/interfaces/dream_builder/page';
import { PageRoute } from '@/interfaces/dream_builder/page_route';

import { getPageRouteFromPath } from '../_utils/getPageRouteFromPath';

interface ReturnProps {
  pages: Page[];
  pageRoutes: PageRoute;
  defaultRoutes: PageRoute;
}

const useSetPages = () => {
  const pageRoutes = useWebsitePageRoute();
  const pageList = useWebsitePageList();
  const defaultRoutes = useWebsiteDefaultRoute();
  const pageRouteGetter = useWebsitePageRouteGetter();
  const [searchParams] = useSearchParams();
  const path = searchParams.get('path');

  const [pages, setpages] = useState<Page[]>();
  const homePage = pageRoutes?.page_id ? pageRouteGetter?.getPageFromID(pageRoutes?.page_id) : undefined;

  useEffect(() => {
    if (!path) {
      if (pageList && homePage) {
        setpages([homePage, ...pageList.filter((page) => page.id !== homePage.id)]);
        return;
      }
      setpages(pageList);
    } else if (pageRoutes) {
      const pageRoute = getPageRouteFromPath(pageRoutes, path.split('/'));
      const pageIDs = pageRoute
        ? [
            pageRoute.page_id,
            ...(pageRoute.children ? Object.values(pageRoute.children).map((child) => child.page_id) : []),
          ]
        : [];

      const currentPagesList = pageIDs
        .map((id: string) => pageRouteGetter?.getPageFromID(id))
        .filter(Boolean) as Page[];
      setpages(currentPagesList);
    }
  }, [pageList, path, pageRoutes, homePage, pageRouteGetter]);

  return {
    pages: pages as Page[],
    pageRoutes,
    defaultRoutes,
  } as ReturnProps;
};

export default useSetPages;
