import { Fragment, ReactNode } from 'react';

import { useSettings } from '@/context/settings-context';
import { usePostClicks } from '@/hooks';
import { Order } from '@/interfaces/general';
import { DeviceType, GroupBy, PostClick } from '@/interfaces/post';

interface Props {
  postId: string;
  children: (
    clicks: PostClick[],
    totalClicks: number | undefined,
    isLoading: boolean,
    fetchNextPage: () => void,
    hasNextPage: boolean | undefined,
    isFetchingNextPage: boolean
  ) => ReactNode;
  direction?: Order;
  platform?: 'web' | 'email';
  deviceType?: DeviceType;
  allResults?: boolean;
  groupBy?: GroupBy;
}

const PostClicksContainer = ({
  postId,
  children,
  platform,
  direction = Order.DESC,
  deviceType,
  groupBy,
  allResults = false,
}: Props) => {
  const { settings } = useSettings();
  const canUseApiary = settings?.use_apiary === true;

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = usePostClicks({
    postId,
    useApiary: canUseApiary,
    platform,
    direction,
    deviceType,
    allResults,
    groupBy,
    staleTime: undefined,
  });

  const totalClicks = data?.pages[0]?.pagination?.total;
  const clicks = data?.pages?.flatMap((page) => page.clicks) || [];

  return <>{children(clicks, totalClicks, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage)}</>;
};

export default PostClicksContainer;
