import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { useUpdateWebTemplate } from '../../../../../../hooks';
import { Publication } from '../../../../../../interfaces/publication';

import PublicationItem from './PublicationItem';

interface Props {
  publications: Publication[];
}

const Publications = ({ publications }: Props) => {
  const webTemplateMutation = useUpdateWebTemplate();
  const [list, setList] = useState<any>(null);
  const lengthCheck = list?.length !== publications?.length;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    if (publications && lengthCheck) {
      setList(publications);
    }
  }, [publications, lengthCheck]);

  const onDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const activePostitionId = list.find((position: any) => position.id === active.id);
      const overPostitionId = list.find((position: any) => position.id === over.id);
      const oldIndex = list.indexOf(activePostitionId);
      const newIndex = list.indexOf(overPostitionId);
      const newList = arrayMove(list, oldIndex, newIndex);
      setList(newList);
      const updatableList = newList.map((item: any) => item.id);
      webTemplateMutation.mutate({ multi_pub_ids: updatableList as string[] });
      toast.success('Saved!');
    }
  };

  const handleRemovePublication = (id: string) => {
    const newList = list.filter((item: any) => item.id !== id);
    setList(newList);

    const updatableList = newList.map((item: any) => item.id);
    if (updatableList.length === 0) {
      webTemplateMutation.mutate({ multi_pub_ids: updatableList as string[], enable_multi_pub_post_signup: false });
    } else {
      webTemplateMutation.mutate({ multi_pub_ids: updatableList as string[] });
    }

    toast.success('Saved!');
  };

  if (!list || list.length === 0) {
    return null;
  }

  return (
    <div className="bg-white border border-gray-200 shadow sm:rounded-md relative curser-pointer mt-2">
      <div className="divide-y divide-gray-200">
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
          <SortableContext items={list} strategy={verticalListSortingStrategy}>
            {list?.length > 0
              ? list.map((item: any) => {
                  return (
                    <PublicationItem key={item?.id} listItem={item} onRemoveItem={handleRemovePublication}>
                      <div className="flex space-x-4">
                        <div className="flex items-center space-x-1">
                          <div className="w-4 h-4">
                            <svg
                              clipRule="evenodd"
                              fillRule="evenodd"
                              strokeLinejoin="round"
                              strokeMiterlimit="2"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                              className="opacity-20"
                            >
                              <path d="m12 16.495c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25z" />
                              <path d="m20 16.495c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25zm0-6.75c1.242 0 2.25 1.008 2.25 2.25s-1.008 2.25-2.25 2.25-2.25-1.008-2.25-2.25 1.008-2.25 2.25-2.25z" />
                            </svg>
                          </div>
                        </div>

                        <p className="text-sm font-medium text-gray-800 truncate max-w-sm">{item.name}</p>
                      </div>
                    </PublicationItem>
                  );
                })
              : null}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
};

export default Publications;
