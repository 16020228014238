import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '../../../../context/auth-context';
import { useCurrentUser } from '../../../../context/current-user-context';
import api from '../../../../services/swarm';

const Masquerade = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const redirect = queryParams.get('redirect');
  const userId = queryParams.get('userId');

  const { masquerade } = useAuth();
  const { reloadUser } = useCurrentUser();

  const navigate = useNavigate();

  function masqueradeUser(user_id: string) {
    masquerade
      .mutateAsync({
        user_id,
      })
      .then(() => {
        reloadUser();
      })
      .then(() => {
        if (redirect) {
          navigate(redirect);
        } else {
          navigate('/');
        }
        window.location.reload();
      });
  }

  useEffect(() => {
    if (userId && email) {
      toast.error('Cannot provide both user_id & email');
      navigate('/system_admin/users');
    } else if (userId) {
      api
        .get(`/system_admin/user/${userId}/confirm`)
        .then(() => {
          masqueradeUser(userId);
        })
        .catch((err) => {
          console.log(err.response.data);
          toast.error(err.response.data.error || 'User not found');
          navigate('/system_admin/users');
        });
    } else if (email) {
      api
        .get(`/system_admin/user`, {
          params: {
            email,
          },
        })
        .then((res) => {
          const { user } = res.data;

          // Check if the user is deleted based on the response
          if (user.deleted_at) {
            toast.error('User is deleted');
            navigate('/system_admin/users');
          } else {
            const { id } = user;

            masqueradeUser(id);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.error || 'User not found');
          navigate('/system_admin/users');
        });
    } else {
      toast.error('User not found');
      navigate('/system_admin/users');
    }
  });

  return <></>;
};

export default Masquerade;
