import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { BellIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { ButtonProp, CommonProps, HrefProp, ToProp } from '@/interfaces/nav_item';

import NavItem from './NavItem';
import NavItemLink from './NavItemLink';

type Props = CommonProps & (ToProp | HrefProp | ButtonProp);

const BASE_STYLES = 'flex space-x-1.5 items-center justify-between rounded -mt-0.5 w-full pt-0.5';
const COLOR_MAP: any = Object.freeze({
  secondary: 'bg-action-secondary-50 text-action-secondary-500 hover:bg-action-secondary-50',
  tertiary: 'bg-action-tertiary-50 text-action-tertiary-500, hover:bg-action-tertiary-50',
});

const getVariant = (planType: string, isActive: boolean, notIncludedInTrial: boolean) => {
  if (!isActive) return 'primary';
  if (planType === 'trial' && notIncludedInTrial) return 'secondary';
  if (planType === 'trial') return 'tertiary';
  if (planType === 'upgrade') return 'secondary';
  if (isActive) return 'secondary';
  return 'primary';
};

const NavItemWithModal = ({
  title,
  Icon,
  to,
  planType,
  onClick,
  notIncludedInTrial = false,
  additionalIsActiveCheck = false,
  includedInGrow = false,
  badge,
  modal,
}: Props) => {
  const { pathname } = useLocation();
  const startsWith = to && pathname.startsWith(to) && to !== '/';
  const isActive = pathname === to || startsWith || additionalIsActiveCheck;

  const variant = useMemo(() => {
    return getVariant(planType || '', isActive, notIncludedInTrial);
  }, [planType, isActive, notIncludedInTrial]);

  return (
    <>
      <div className="md:hidden">
        <NavItemLink title="Notifications" Icon={BellIcon} to="/notifications" />
      </div>
      <Menu as="div" className="hidden md:inline-block">
        {() => (
          <>
            <Menu.Button className="w-full">
              <button
                type="button"
                onClick={onClick}
                className={cx(BASE_STYLES, isActive && COLOR_MAP[variant], !isActive && 'hover:bg-surface-100')}
              >
                <NavItem
                  Icon={Icon}
                  title={title}
                  planType={planType}
                  isActive={isActive}
                  notIncludedInTrial={notIncludedInTrial}
                  includedInGrow={includedInGrow}
                />
                {badge && <div className="ml-2">{badge}</div>}
              </button>
            </Menu.Button>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className={cx(
                  'z-10 shadow-xl h-[400px] overflow-auto absolute left-[103%] w-[400px] origin-top-right bg-white divide-y divide-gray-100 rounded-md ring-1 ring-black ring-opacity-5 -mt-7 focus:outline-none'
                )}
              >
                {modal}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
};

export default NavItemWithModal;
