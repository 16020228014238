import { PublicationLogo } from '@/components/PublicationLogo';

export interface Props {
  logoUrl: string;
  url: string;
  name: string;
  description: string | null;
}

const PublicationDetails = ({ logoUrl, url, name, description }: Props) => {
  return (
    <div className="flex flex-row justify-between space-x-4">
      <div className="space-y-4">
        <a href={url} target="_blank" rel="noreferrer">
          <PublicationLogo publication={{ logo_url: logoUrl, name }} size="xl" />
          <h3 className="text-lg font-semibold leading-6 text-gray-900">{name}</h3>
        </a>
        {description && <p className="mt-1 max-w-2xl text-sm text-gray-500">{description}</p>}
      </div>
    </div>
  );
};

export default PublicationDetails;
