import { WebsiteProvider } from '@/context/website-context';

import { Text } from '../_components/UI/Text';

import { Layout } from './_components/Layout';

const PageEditorPage = () => {
  return (
    <Layout>
      <Text variant="secondary" size="xl" weight="semibold">
        Editor coming soon...{' '}
      </Text>
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <PageEditorPage />
  </WebsiteProvider>
);
