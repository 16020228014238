import { PropsWithChildren } from 'react';
import cx from 'classnames';

type Props = PropsWithChildren<{
  className?: string;
}>;

const SettingsCard = ({ children, className }: Props) => {
  return <div className={cx('border border-wb-hr p-4 rounded-lg', className)}>{children}</div>;
};

export default SettingsCard;
