import { useEffect, useState } from "react";
import { MagnifyingGlass } from "@phosphor-icons/react";

import { useWebsiteContext } from "@/context/website-context";
import { useCurrentPublication } from "@/hooks";

import { Input } from "../../_components/UI/Input"
import { Text } from "../../_components/UI/Text";
import { Textarea } from "../../_components/UI/Textarea";

import SettingsItem from "./SettingsItem"

export function MetadataSettings({
  onUpdate,
}: {
  onUpdate: (key: string, value: string) => void;
}) {
  const { site } = useWebsiteContext();
  const currentPublication = useCurrentPublication();

  const [metaTitle, setMetaTitle] = useState<string>(site?.draft_site_version?.metadata?.meta_title || '');
  const [metaDescription, setMetaDescription] = useState<string>(site?.draft_site_version?.metadata?.meta_description || '');

  useEffect(() => {
    setMetaTitle(site?.draft_site_version?.metadata?.meta_title || '');
    setMetaDescription(site?.draft_site_version?.metadata?.meta_description || '');
  }, [site]);


  return (
    <SettingsItem
      title="Metadata"
      description="Extra content for search engines to crawl and index your website."
    >
      <div className="flex flex-col lg:flex-row gap-6 w-full pt-4">
        <div className="flex flex-1 flex-col gap-6">
          <Input
            type="text"
            id="meta_title"
            labelText="Meta Title"
            placeholder={currentPublication?.data?.name}
            value={metaTitle}
            onChange={(e) => setMetaTitle(e.target.value)}
            onBlur={() => {
              onUpdate('meta_title', metaTitle);
            }}
            helperText={
              <span>
                Recommended: 70 characters. You&apos;ve used{' '}
                <span className="text-wb-primary">{metaTitle.length}</span>.
              </span>
            }
          />

          <Textarea id="meta_description"
            labelText="Meta Description"
            placeholder={`A website for ${currentPublication?.data?.name}`}
            value={metaDescription}
            onChange={(e) => setMetaDescription(e.target.value)}
            onBlur={() => {
              onUpdate('meta_description', metaDescription);
            }}
            helperText={
              <span>
                Recommended: 156 characters. You&apos;ve used{' '}
                <span className="text-wb-primary">{metaDescription.length}</span>.
              </span>
            }
          />
        </div>
        <div className="flex flex-1 flex-col gap-3">
          <div className="is-size-7 with-text-color mb-2">Search Engine Result Preview</div>
          <div className="bg-wb-primary rounded-xl px-5 py-4 shadow-md">
            <div className="flex items-center gap-3 w-full mb-4">
              {/* TODO: Replace with uploaded google.png */}
              <img className="w-[60px] h-[35px]" src="https://build.typedream.com/images/google.png" alt="Google Logo" />
              <div className="border border-wb-tertiary rounded-full p-1.5 flex-1">
                <MagnifyingGlass size={15} className="text-wb-tertiary" />
              </div>
            </div>

            <Text size="xl" weight="medium" className="text-[#1a0dab]"
              as="p"
            >{metaTitle || currentPublication?.data?.name}</Text>
            <Text size="sm" weight="medium" variant="secondary"
              as="p"
            >{metaDescription || `A website for ${currentPublication?.data?.name}`}</Text>
          </div>
        </div>
      </div>
    </SettingsItem>
  )
}
