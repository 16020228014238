import { useMutation } from 'react-query';

import api from '@/services/swarm';
import { registerTempUser } from '@/utils/analytics/tempUser';

interface Props {
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  selectedPlan?: string;
  isEnterprisePlan?: boolean;
  isTrialPlan?: boolean;
  passwordConfirmation?: string;
  marketingAttribution?: any;
  ehawkTal?: any;
}

const useCreateTempUser = () => {
  return useMutation(
    ({
      email,
      firstName,
      lastName,
      password,
      passwordConfirmation,
      marketingAttribution,
      ehawkTal,
      selectedPlan,
      isEnterprisePlan,
      isTrialPlan,
    }: Props) =>
      api
        .post(`/signups/new_temp_user`, {
          email,
          first_name: firstName,
          last_name: lastName,
          password,
          password_confirmation: passwordConfirmation,
          marketing_attribution: marketingAttribution,
          ehawk_tal: JSON.parse(ehawkTal) || {},
          selected_plan: selectedPlan,
          is_enterprise_plan: isEnterprisePlan,
          is_trial_plan: isTrialPlan,
        })
        .then((res) => {
          registerTempUser({
            email: res.data.email,
            firstName: res.data.first_name,
            lastName: res.data.last_name,
            emailVerified: false,
            selectedPlan,
            isTrialPlan,
          });
          return res.data;
        })
  );
};

export default useCreateTempUser;
