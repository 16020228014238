import React from 'react';
import { GearSix, House } from '@phosphor-icons/react';

import { SkeletonLoader } from '@/components/SkeletonLoader';
import { WebsiteProvider } from '@/context/website-context';
import { useSite } from '@/hooks/useSite';

import { ContentTree } from './_components/ContentTree';
import { Layout } from './_components/Layout';
import { Main } from './_components/Main';
import { Breadcrumbs } from './_components/Main/Breadcrumbs';
import { PageCard } from './_components/Main/PageCard';
import { TopBar } from './_components/Main/TopBar';
import { NavMenuItem } from './_components/SideNav/NavMenuItem';
import { NavSection } from './_components/SideNav/NavSection';
import { NavSectionTitle } from './_components/SideNav/NavSectionTitle';
import useSetPages from './_hooks/useSetPages';

const WebsiteDashboardPage = () => {
  const { isLoading } = useSite();
  const { pages, pageRoutes, defaultRoutes } = useSetPages();

  return (
    <Layout
      backPath="/"
      sidenavChildren={
        <>
          <NavSection>
            <NavSectionTitle title="Site Settings" />
            <NavMenuItem Icon={House} title="Home" to="/website_builder_v2" />
            <NavMenuItem Icon={GearSix} title="Site Settings" to="/website_builder_v2/settings" />
          </NavSection>
          <NavSection>
            <NavSectionTitle title="Pages" />
            {pageRoutes && <ContentTree route={pageRoutes} parentPath={[]} slug="" />}
          </NavSection>
          <NavSection>
            <NavSectionTitle title="Default Pages" />
            {defaultRoutes && (
              <ContentTree showHome={false} allowDnD={false} route={defaultRoutes} parentPath={[]} slug="" />
            )}
          </NavSection>
        </>
      }
    >
      <Main>
        <Breadcrumbs />
        <TopBar showDropdown />
        <SkeletonLoader
          isLoading={isLoading}
          skeletons={
            <div className="grid grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] gap-6">
              <div className="cursor-pointer p-4 overflow-hidden bg-neutral-50 dark:bg-neutral-900 border border-solid border-wb-primary shadow-wb-md rounded-xl h-[150px] relative" />
              <div className="cursor-pointer p-4 overflow-hidden bg-neutral-50 dark:bg-neutral-900 border border-solid border-wb-primary shadow-wb-md rounded-xl h-[150px] relative" />
              <div className="cursor-pointer p-4 overflow-hidden bg-neutral-50 dark:bg-neutral-900 border border-solid border-wb-primary shadow-wb-md rounded-xl h-[150px] relative" />
            </div>
          }
          as={React.Fragment}
        >
          <div className="grid grid-cols-[repeat(auto-fill,_minmax(250px,_1fr))] gap-6">
            {pages?.map((page) => {
              return <PageCard page={page} />;
            })}
          </div>
        </SkeletonLoader>
      </Main>
    </Layout>
  );
};

export default () => (
  <WebsiteProvider>
    <WebsiteDashboardPage />
  </WebsiteProvider>
);
