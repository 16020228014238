import { createElement } from 'react';
import cx from 'classnames';

interface LoaderProps {
  isLoading: boolean;
  children: React.ReactNode;
  skeletons?: React.ReactNode | React.ReactNode[];
  className?: string;
  as?: string | React.ComponentType;
}

const SkeletonLoader: React.FC<LoaderProps> = ({ isLoading, children, skeletons, className, as }: LoaderProps) => {
  const Wrapper = (props: any) => createElement(as || 'div', props);

  return (
    <Wrapper>{isLoading ? <div className={cx('w-full animate-pulse', className)}>{skeletons}</div> : children}</Wrapper>
  );
};

SkeletonLoader.defaultProps = {
  className: '',
};

export default SkeletonLoader;
