import cx from 'classnames';

import { Accordion } from '@/components/Accordion';
import * as CardUI from '@/components/Card/CardUI';
import { Title2xl, TitleBase, TitleSm, Typography, TypographyStack } from '@/components/Typography';

interface Props {
  title: string | React.ReactNode;
  subText: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  defaultOpen?: boolean;
  topBorder?: boolean;
  titleSize?: 'sm' | 'base' | 'lg' | '2xl';
  isControlledState?: boolean;
  isControlledStateOpen?: boolean;
  onClick?: () => void;
}

const AccordionCard = ({ children, footer, title, subText, defaultOpen = false, topBorder = true, titleSize = "sm", isControlledState, isControlledStateOpen, onClick }: Props) => {
  const titles = {
    sm: <TitleSm>{title}</TitleSm>,
    base: <TitleBase>{title}</TitleBase>,
    lg: <Typography token="font-medium/text/lg">{title}</Typography>,
    "2xl": <Title2xl>{title}</Title2xl>,
  }
  return (
    <CardUI.Card hasPadding={false}>
      <Accordion
        defaultOpen={defaultOpen}
        className='p-4'
        isControlledState={isControlledState}
        isControlledStateOpen={isControlledStateOpen}
        onClick={onClick}
        titleChildren={
          <TypographyStack>
            {titles[titleSize]}
            <Typography className="w-full" colorWeight="500" weight="normal" size="sm">
              {subText}
            </Typography>
          </TypographyStack>
        }
      >
        <div className={cx("mt-4 p-4", topBorder ? "border-t border-t-surface-200" : "")}>{children}</div>
        {footer}
      </Accordion>
    </CardUI.Card>
  );
};

export default AccordionCard;
