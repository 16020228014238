import BoostDisplayOrder from '@/pages/Monetize/Boosts/Dashboard/components/Settings/Sections/BoostDisplayOrder';

import { useCurrentPublication, useUpdateWebTemplate, useWebTemplate } from '../../../../../hooks';
import { usePublications, useUpdatePublication } from '../../../../../hooks/usePublications';
import { Section, SwitchForm } from '../../../Components';

import AuthorsSection from './Authors';
import EmailCaptureSection from './EmailCaptureSection';
import FeaturedPosts from './FeaturedPosts';
import GDPRSection from './GDPRSection';
import MultiPubSection from './MultiPubSection';
import RecommendedReadingSection from './RecommendReadingSection';
import SignupFlowSection from './SignupFlowSection';
import TurnstileCaptchaSetting, { TurnstilePayload } from './TurnstileCaptchaSetting';

const Advanced = () => {
  const { data: currentPublication }: any = useCurrentPublication();
  const { data: publications, isLoading: isLoadingPublications } = usePublications();
  const hasMultiplePublications = publications?.length > 1;

  const publicationMutation = useUpdatePublication();
  const webTemplateRequest = useWebTemplate();
  const { data: webTemplate }: any = webTemplateRequest;

  const webTemplateMutation = useUpdateWebTemplate();

  return (
    <>
      {/* Signup Flow Section */}
      <SignupFlowSection />

      {/* Authors Section */}
      <AuthorsSection />

      {/* Multi-Pub Section */}
      {hasMultiplePublications && (
        <MultiPubSection publications={publications} webTemplate={webTemplate} isLoading={isLoadingPublications} />
      )}

      {/* Feature Posts Section */}
      <Section
        title="Featured Posts"
        description="Select up to 6 posts to feature on your website."
        scrollToId="featured-posts"
        isLoading={!webTemplateRequest?.data}
      >
        <SwitchForm
          name="enable_featured_post_badges"
          label="Enable featured post badges"
          description="By enabling, a badge will be displayed over featured posts. This can be helpful for readers to identify featured posts more easily."
          value={webTemplate?.enable_featured_post_badges || false}
          onSave={(val: boolean) => webTemplateMutation.mutateAsync({ enable_featured_post_badges: val })}
          toastSuccessMessage="Saved!"
        />
        <FeaturedPosts webTemplateRequest={webTemplateRequest} />
      </Section>

      {/* Recommended Reading Section */}
      <RecommendedReadingSection />

      {/* Email Capture Section */}
      <EmailCaptureSection />

      {/* Boosts */}
      <Section title="Boosts" scrollToId="boosts" isLoading={!currentPublication}>
        <BoostDisplayOrder />
      </Section>

      {/* GDPR & Compliance */}
      <GDPRSection />

      {/* Configuration Section */}
      <Section
        title="Configuration"
        description="Manage additional settings below."
        scrollToId="configuration"
        isLoading={!currentPublication || !webTemplate}
        hasDivider={false}
      >
        <TurnstileCaptchaSetting
          toggleable={webTemplate?.recaptcha_disableable}
          enabled={webTemplate?.enable_recaptcha || false}
          onChange={(payload: TurnstilePayload) => webTemplateMutation.mutateAsync(payload)}
        />
        <SwitchForm
          name="no_indexing"
          label="Enable No Indexing?"
          description="You can enable a noindex meta tag in the header of your publication by turning on this option. It will prevent your posts from showing up in Google Search."
          value={currentPublication?.noindex_enabled || false}
          onSave={(val: boolean) => publicationMutation.mutateAsync({ noindex_enabled: val })}
          toastSuccessMessage="Saved!"
        />
        <SwitchForm
          name="logged_subscribe_form"
          label="Hide Subscribe Form"
          description="Don't show the subscribe form for logged in users on the website."
          value={webTemplate?.logged_subscribe_form || false}
          onSave={(val: boolean) => webTemplateMutation.mutateAsync({ logged_subscribe_form: val })}
          toastSuccessMessage="Saved!"
        />
        <SwitchForm
          name="written_by"
          label="Enable Written By Widget?"
          description="You can toggle on and off the authors widget located on the home page of your website."
          value={currentPublication?.render_authors_widget || false}
          onSave={(val: boolean) => publicationMutation.mutateAsync({ render_authors_widget: val })}
          toastSuccessMessage="Saved!"
        />
        <SwitchForm
          name="toggleBreadcrumbs"
          label="Enable Breadcrumbs on Web Pages?"
          description="You can toggle on and off breadcrumbs for web publication pages."
          value={currentPublication?.render_breadcrumbs || false}
          onSave={(val: boolean) => publicationMutation.mutateAsync({ render_breadcrumbs: val })}
          toastSuccessMessage="Saved!"
        />
        <SwitchForm
          name="togglePagination"
          label="Enable Pagination on Web Pages?"
          description="You can toggle on and off pagination for web publication pages. If you turn them off then we will show Load More button in the Post section. "
          value={currentPublication?.render_pagination || false}
          onSave={(val: boolean) => publicationMutation.mutateAsync({ render_pagination: val })}
          toastSuccessMessage="Saved!"
        />
      </Section>
    </>
  );
};

export default Advanced;
