import { LogicalOperator } from '@/interfaces/condition';
import { Condition, Group } from '@/interfaces/segment';

interface ConditionsPayload {
  conditions: Condition[] | Group[];
  logical_operator: LogicalOperator;
}

export interface RequestPayload {
  name?: string;
  description?: string;
  segment_type?: string;
  publication_id: string;
  conditions?: ConditionsPayload;
}

export enum SubscriberImportType {
  CSV = 'csv',
  Manual = 'manual',
}
