import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { AdjustmentsVerticalIcon, ArrowRightIcon } from '@heroicons/react/20/solid';

import ActionModal from '@/components/ActionModal';
import { Breadcrumbs } from '@/components/Breadcrumbs';
import Card from '@/components/Card';
import ImportSubscriberListForm from '@/pages/Settings/Pages/Publication/ImportSubscribers/ImportSubscriberList/ImportSubscriberListForm';
import ImportSubscriberModalContent from '@/pages/Settings/Pages/Publication/ImportSubscribers/ImportSubscriberList/ImportSubscriberModalContent';
import { useImportSubscriberList } from '@/pages/Settings/Pages/Publication/ImportSubscribers/ImportSubscriberList/useImportSubscriberList';
import api from '@/services/swarm';
import { Button } from '@/ui/Button';
import analytics from '@/utils/analytics';

import ConditionsEditor from '../ConditionsEditor';
import FilterByActiveSubscribersWarning from '../ConditionsEditor/FilterByActiveSubscribersWarning';
import { MANUAL_SEGMENT_OPTION, SEGMENT_TYPE_DESCRIPTION, SEGMENT_TYPE_OPTIONS } from '../constants';
import FormTitle from '../FormTitle';
import useSegmentForm from '../hooks/useSegmentForm';
import MetadataForm from '../MetadataForm';
import { RequestPayload } from '../types';

import SelectSegment from './SelectSegment';

const NewSegment = () => {
  const navigate = useNavigate();

  const {
    currentPublicationId,
    isNextStepButtonDisabled,
    nextStepLabel,
    canAddConditions,
    canImportSubscribers,
    isSaving,
    setIsSaving,
    name,
    setName,
    description,
    setDescription,
    segmentType,
    setSegmentType,
    showSecondStep,
    setShowSecondStep,
    mainLogicalOperator,
    blocks,
    addConditionBlock,
    addGroupBlock,
    duplicateConditionBlock,
    canSave,
    isMissingActiveSubscriberCondition,
    handleAddActiveSubscribersCondition,
    handleSelectWhichName,
    handleFilterChange,
    handleRefineChange,
    handleGroupLogicalOperatorChange,
    handleDeleteCondition,
    handleDeleteRefine,
    handleSelectConditionName,
    handleSelectConditionNameInGroup,
    handleMainLogicalOperatorChange,
    handleSortConditionBlocks,
  } = useSegmentForm();

  const {
    modalHeaderText,
    publicationName,
    canImport,
    showMappingModal,
    showConfirmModal,
    onImportConfirm,
    onMappingStart,
    csvColumns,
    setCsvColumns,
    setFile,
    file,
    commaSeparatedEmails,
    handleEmailInputChange,
    sendWelcomeEmail,
    publicationWelcomeEmail,
    setSendWelcomeEmail,
    isSystemAdmin,
    skipEmailValidation,
    setSkipEmailValidation,
    setColumnMapping,
    columnMappingErrors,
    setColumnMappingErrors,
    makeFormData,
    handleCancelImport,
    importType,
    handleImportTypeChange,
  } = useImportSubscriberList();

  const onCreateSuccess = (segmentId: string) => {
    toast.success('Segment created successfully');
    navigate(`/segments/${segmentId}`);
  };

  const createImport = (segmentId: string) => api.post('/subscription_imports', makeFormData(segmentId));

  const createSegment = async () => {
    const payload: RequestPayload = {
      name,
      description,
      segment_type: segmentType,
      publication_id: currentPublicationId,
      conditions: {
        logical_operator: mainLogicalOperator,
        conditions: blocks,
      },
    };

    setIsSaving(true);

    try {
      const response = await api.post(`/segments`, payload);
      const newId = response?.data?.id;
      if (newId) {
        if (canImport) {
          try {
            await createImport(newId);
          } catch (error: any) {
            toast.error(error.message || 'A segment was created but something went wrong while importing your list!');
          }
        }

        onCreateSuccess(newId);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.error || 'Something went wrong');
    }

    setIsSaving(false);
  };

  const handleSaveSegment = (e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (canImport) {
      onImportConfirm();
      return;
    }

    analytics.track('Created a Segment');
    createSegment();
  };

  const validateImport = async () => {
    setIsSaving(true);

    try {
      const response = await api.post('/subscription_imports/preflight', makeFormData());
      if (response.data === '') {
        // No column mapping required, let's create the segment
        analytics.track('Created a Segment');
        createSegment();
      } else {
        // Set mappable columns and show column mapping modal
        setCsvColumns(response.data.columns);
        onMappingStart();
      }
    } catch (error: any) {
      toast.error(error.message || 'Something when wrong while importing your list, please try again!');
    }

    setIsSaving(false);
  };

  const modalId = 'newSegment-importSubscribersModal';

  return (
    <div className="mb-24">
      <Helmet>
        <title>New Segment</title>
      </Helmet>

      <div className="flex flex-row justify-between items-center w-full mb-4">
        <Breadcrumbs>
          <Breadcrumbs.Item to="/segments">Segments</Breadcrumbs.Item>
          <Breadcrumbs.Item to="/segments/new">New Segment</Breadcrumbs.Item>
        </Breadcrumbs>
        {showSecondStep && (
          <Button
            type="button"
            disabled={!canSave}
            loading={isSaving}
            onClick={handleSaveSegment}
            iconRight
            Icon={AdjustmentsVerticalIcon}
          >
            Save segment
          </Button>
        )}
      </div>

      <Card>
        <div>
          <FormTitle>
            {!showSecondStep && 'New Segment'}
            {showSecondStep ? nextStepLabel : null}
          </FormTitle>
          <form>
            {!showSecondStep && (
              <>
                <MetadataForm
                  segmentName={name}
                  onSegmentNameChange={setName}
                  segmentDescription={description}
                  onSegmentDescriptionChange={setDescription}
                />
                <div className="mt-5">
                  <SelectSegment
                    segmentType={segmentType}
                    onSegmentTypeChange={setSegmentType}
                    segmentOptions={[...SEGMENT_TYPE_OPTIONS, MANUAL_SEGMENT_OPTION]}
                    segmentDescription={SEGMENT_TYPE_DESCRIPTION}
                  />
                </div>
              </>
            )}
            {!showSecondStep ? (
              <div className="pt-8">
                <Button
                  type="button"
                  variant="primary-inverse"
                  disabled={isNextStepButtonDisabled}
                  onClick={() => setShowSecondStep(true)}
                  Icon={ArrowRightIcon}
                  iconRight
                >
                  {nextStepLabel}
                </Button>
              </div>
            ) : null}
            {showSecondStep && canAddConditions ? (
              <div className="mt-6">
                <ConditionsEditor
                  mainLogicalOperator={mainLogicalOperator}
                  blocks={blocks}
                  onAddConditionBlock={addConditionBlock}
                  onAddGroupBlock={addGroupBlock}
                  onDuplicateCondition={duplicateConditionBlock}
                  onSelectWhichName={handleSelectWhichName}
                  onFilterChange={handleFilterChange}
                  onRefineChange={handleRefineChange}
                  onGroupLogicalOperatorChange={handleGroupLogicalOperatorChange}
                  onDeleteCondition={handleDeleteCondition}
                  onDeleteRefine={handleDeleteRefine}
                  onSelectConditionName={handleSelectConditionName}
                  onSelectConditionNameInGroup={handleSelectConditionNameInGroup}
                  onChangeMainLogicalOperator={handleMainLogicalOperatorChange}
                  onSortConditionBlocks={handleSortConditionBlocks}
                  className="mt-2"
                />

                {isMissingActiveSubscriberCondition && (
                  <FilterByActiveSubscribersWarning
                    handleAddActiveSubscribersCondition={handleAddActiveSubscribersCondition}
                  />
                )}
              </div>
            ) : null}
            {showSecondStep && canImportSubscribers ? (
              <div className="mt-6 w-1/2">
                <ActionModal
                  isOpen={showConfirmModal || showMappingModal}
                  onClose={handleCancelImport}
                  onProceed={showMappingModal ? createSegment : validateImport}
                  resourceId={currentPublicationId}
                  isWorking={false}
                  headerText={modalHeaderText}
                  actionText="Import"
                  modalSize="xl"
                  bodyId={modalId}
                  overflow="visible"
                  disabled={columnMappingErrors}
                >
                  <ImportSubscriberModalContent
                    publicationName={publicationName}
                    showMappingModal={showMappingModal}
                    csvColumns={csvColumns}
                    setColumnMappingErrors={setColumnMappingErrors}
                    setColumnMapping={setColumnMapping}
                  />
                </ActionModal>

                {showSecondStep && canImportSubscribers && (
                  <p className="text-sm text-gray-500 mb-4">
                    Any emails that are not currently subscribed to this publication will be imported as active
                    subscribers and added to the segment.
                  </p>
                )}

                <ImportSubscriberListForm
                  selectedImportType={importType}
                  onImportTypeChange={handleImportTypeChange}
                  onFileChange={setFile}
                  selectedFile={file}
                  commaSeparatedEmails={commaSeparatedEmails}
                  onCommaSeparatedEmailsChange={handleEmailInputChange}
                  shouldSendWelcomeEmail={sendWelcomeEmail}
                  shouldDisableWelcomeEmail={!publicationWelcomeEmail}
                  onSendWelcomeEmailChange={setSendWelcomeEmail}
                  canSkipEmailValidation={isSystemAdmin}
                  shouldSkipEmailValidation={skipEmailValidation}
                  onSkipEmailValidationChange={setSkipEmailValidation}
                  csvUploadElementLabel=""
                  csvTextInputElementLabel=""
                />
              </div>
            ) : null}
          </form>
        </div>
      </Card>
    </div>
  );
};

export default NewSegment;
