import { ElementType, PropsWithChildren, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Accordion } from '@/components/Accordion';

import NavItem from './NavItem';

type Props = PropsWithChildren<{
  title: string;
  Icon?: ElementType;
  defaultOpen?: boolean;
}>;

const NavItemParent = ({ title, Icon, children, defaultOpen = false }: Props) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    const childrenArray: any = children;
    const hasActiveChildPath = childrenArray?.some((child: any) => {
      if (!child) {
        return false;
      }
      const startsWith = child.props.to && location.pathname.startsWith(child.props.to);
      return child.props.to === location.pathname || startsWith || child.props.additionalIsActiveCheck;
    });

    if (hasActiveChildPath || defaultOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, defaultOpen]);

  return (
    <Accordion
      defaultOpen={defaultOpen}
      titleChildren={<NavItem Icon={Icon} title={title} />}
      onClick={() => setOpen(!isOpen)}
      isControlledState
      isControlledStateOpen={isOpen}
      className="hover:bg-surface-100 rounded pt-0.5"
    >
      <div className="flex flex-col space-y-1 pl-6 pt-2">{children}</div>
    </Accordion>
  );
};

export default NavItemParent;
