import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { ClockIcon } from "@heroicons/react/24/outline";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import cx from "classnames";
import moment from "moment-mini";

import { Accordion } from "@/components/Accordion";
import { AccordionCard } from "@/components/Accordion/variants/AccordionCard"
import * as CardUI from '@/components/Card/CardUI';
import { OpensByHourChart } from "@/components/charts";
import LargeItemRadioGroup from "@/components/Form/LargeItemRadioGroup";
import { TitleSm, Typography, TypographyStack } from "@/components/Typography";
import { useSettings } from "@/context/settings-context";
import usePostNodeContent from "@/hooks/usePostNodeContent";
import { Post, PostNodeContent, PostStatus } from "@/interfaces/post";
import { Button } from "@/ui/Button";
import { ButtonGroup } from "@/ui/Button/ButtonGroup/ButtonGroup";

interface Props {
  post: Post;
  selectedTime: string | null;
  setSelectedTime: (time: string | null) => void;
  scheduleSettingsOpen: boolean;
  setScheduleSettingsOpen: (open: boolean) => void;
  onChange: (data: any) => void;
}

interface PostPublishedProps {
  post: Post;
  iconColor: string;
}

interface SchedulePostProps {
  post: Post;
  iconColor: string;
  selectedTime: string | null;
  setSelectedTime: (data: any) => void;
  scheduleSettingsOpen: boolean;
  setScheduleSettingsOpen: (open: boolean) => void;
  onChange: (data: any) => void;
}

const PostPublished = ({post, iconColor}: PostPublishedProps) => {
  return (
    <CardUI.Card hasPadding={false}>
      <Accordion
        defaultOpen={false}
        className='p-4 cursor-default'
        onClick={() => {}}
        icon={<span/>}
        titleChildren={
          <TypographyStack>
            <TitleSm>
              <span className="flex flex-row gap-3">
                <ClockIcon className={cx("h-6 w-6 flex-grow", iconColor)} />
                <div className="flex flex-row gap-1">
                  <Typography token="font-medium/text/lg" colorWeight="900">Published on</Typography>
                  <Typography token="font-medium/text/lg" colorWeight="900">{moment(post.scheduled_at).format("MMMM Do YYYY, h:mma")}</Typography>
                </div>
              </span>
            </TitleSm>
            <Typography className="w-full" colorWeight="500" weight="normal" size="sm" />
          </TypographyStack>
        }
      />
    </CardUI.Card>
  )
}

const SchedulePost = ({post, iconColor, setSelectedTime, selectedTime, scheduleSettingsOpen, setScheduleSettingsOpen, onChange}: SchedulePostProps) => {
  const { settings } = useSettings();
  const { scheduled_at: scheduledAt } = post;
  const onClickRecommended = (time: string) => {
    toast.success(`Date set to ${moment(time).calendar()}`);

    setSelectedTime(time);
  };
  const { data: postNodeData } = usePostNodeContent({ id: post.id });
  const postNodeContent = postNodeData || ({} as PostNodeContent);
  const minBoostSendBy = postNodeContent?.boost?.map((boost) => boost?.send_by).sort()[0];

  const schedulePostOption = useMemo(() => ({
    name: "Schedule for later",
    value: "later",
  }), []);

  const publishNowOption = useMemo(() => ({
    name: "Publish now",
    value: "now",
  }), []);

  const scheduledInThePast = moment(scheduledAt).isBefore(moment());
  const [selectedOption, setSelectedOption] = useState(scheduledInThePast ? publishNowOption : schedulePostOption);

  useEffect(() => {
    if (scheduledInThePast) {
      setSelectedOption(publishNowOption);
    } else {
      setSelectedOption(schedulePostOption);
    }
  }, [post.scheduled_at, scheduledInThePast, publishNowOption, schedulePostOption]);

  const options = [
    schedulePostOption,
    publishNowOption,
  ]

  const handleOptionSelected = (option: any) => {
    if (option.value === "now") {
      setSelectedTime(moment().toISOString());
    }
    setSelectedOption(option);
  }

  const onClickReview = () => {
    if (!selectedTime) return;
    const formattedTime = moment(selectedTime).toISOString()
    onChange({ scheduled_at: formattedTime });
    setScheduleSettingsOpen(false);
  }

  return (
    <AccordionCard
        title={
          <span className="flex flex-row gap-3">
            <ClockIcon className={cx("h-6 w-6 flex-grow", iconColor)} />
            <div className="flex flex-row gap-1">
              <Typography token="font-medium/text/lg" colorWeight="900">Schedule{!scheduleSettingsOpen && (selectedTime) && "d"}</Typography>
              {
                !scheduleSettingsOpen && (selectedTime) && <Typography token="font-normal/text/lg" colorWeight="500">
                  to <Typography token="font-medium/text/lg" colorWeight="900">Publish on {moment(selectedTime || "").format("MMMM Do YYYY, h:mma")}</Typography>
                </Typography>
              }
            </div>
          </span>
        }
        subText=""
        topBorder={false}
        isControlledState
        isControlledStateOpen={scheduleSettingsOpen}
        onClick={() => setScheduleSettingsOpen(!scheduleSettingsOpen)}
        footer={
          <div className="flex px-3 py-6 justify-end items-center gap-3 self-stretch bg-surface-50">
            <ButtonGroup>
              <Button onClick={onClickReview} type="button" variant="primary" size="sm" disabled={!selectedTime} >Review</Button>
            </ButtonGroup>
          </div>
        }
      >
        <div className="flex flex-col gap-6">

          {settings?.recommended_sending_time && <OpensByHourChart onClickRecommended={onClickRecommended} />}
          <LargeItemRadioGroup
            justifyItems="start"
            options={options}
            selected={selectedOption}
            onSelected={handleOptionSelected}
            variant="secondary"
          />
          {
            selectedOption.value === "later" && (
              <div className="flex flex-row items-center gap-2">
                <input
                  id="scheduled_at"
                  type="datetime-local"
                  className={cx('rounded border-gray-200 text-gray-600 w-full mt-1')}
                  min={moment().format('YYYY-MM-DDThh:mm')}
                  max={minBoostSendBy ? moment(minBoostSendBy).format('YYYY-MM-DDThh:mm') : ''}
                  value={moment(selectedTime || "").format('YYYY-MM-DDTHH:mm')}
                  placeholder=""
                  onChange={(e) => {
                    setSelectedTime(e.target.value);
                  }}
                  required
                />
                <InformationCircleIcon className="h-5 w-5 text-gray-400" />
              </div>
            )
          }
        </div>
      </AccordionCard>
  )
}

const ScheduleSettings = ({post, setSelectedTime, selectedTime, scheduleSettingsOpen, setScheduleSettingsOpen, onChange}: Props) => {
  const [postSent, setPostSent] = useState(false);
  const [iconColor, setIconColor] = useState("text-action-secondary-600");

  useEffect(() => {
    if (post.status === PostStatus.PUBLISHED || post.status === PostStatus.ARCHIVED) {
      setIconColor("text-feedback-success-500");
      setPostSent(true);
    } else if (post.status === PostStatus.DRAFT) {
      setIconColor("text-action-secondary-600");
    } else {
      setIconColor("text-feedback-info-500");
    }
  }, [post.status]);


  return (
    <div className="w-2/3 mx-auto">
      {
        !postSent &&
        <SchedulePost
          post={post}
          iconColor={iconColor}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          scheduleSettingsOpen={scheduleSettingsOpen}
          setScheduleSettingsOpen={setScheduleSettingsOpen}
          onChange={onChange}
        />
      }
      {postSent && <PostPublished post={post} iconColor={iconColor} />}
    </div>
  )
}

export default ScheduleSettings;
