import { CaretDown } from '@phosphor-icons/react';

import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/routes/website/_components/UI/DropdownMenu';

import { Button } from '../../../_components/UI/Button';
import { Text } from '../../../_components/UI/Text';

export const PublishDropDown = () => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <Button className="p-2.5" RightIcon={CaretDown}>
        <Text size="xs" weight="medium" variant="on-accent">
          Publish
        </Text>
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent
      sideOffset={2}
      align="end"
      className="flex flex-col gap-0 min-w-[260px] bg-wb-primary px-0 py-1.5"
    >
      <div className="h-12 flex items-center justify-center">
        <Text variant="secondary">Coming soon</Text>
      </div>

      {/* TODO: @putrikarunia determine what needs to go in here */}
    </DropdownMenuContent>
  </DropdownMenu>
);
