import { useState } from 'react';
import { AdjustmentsVerticalIcon } from '@heroicons/react/20/solid';
import cx from 'classnames';

import { DateSearch } from '@/components/Form';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import SearchInput from '@/components/SearchInput';
import TierSelect from '@/components/TierSelect';
import { SlideDown } from '@/components/Transitions';
import { SubscriberDateField } from '@/interfaces/dates';
import AdvancedSearchOptions, {
  AdvancedSearchOptionsProps,
  DefaultSearchOptionsProps,
  recomposeSearchOptions,
} from '@/models/advancedSearchOptions';
import { Button } from '@/ui/Button';
import { Dropdown } from '@/ui/Dropdown';

interface Props {
  search: string;
  status: string;
  tierId: string;
  shouldResetSearch: boolean;
  handleResetSearch: () => void;
  handleSearch: (search: string, advancedSearch: AdvancedSearchOptionsProps) => void;
  handleStatusChange: (value: string) => void;
  handleTierIdChange: (value: string | string[]) => void;
  isFetching: boolean;
  advancedSearchOptions: AdvancedSearchOptions;
}

const Search = (props: Props) => {
  const {
    search,
    status,
    tierId,
    shouldResetSearch,
    handleResetSearch,
    handleSearch,
    handleStatusChange,
    handleTierIdChange,
    isFetching,
    advancedSearchOptions,
  } = props;

  const [searchOptions, setSearchOptions] = useState(advancedSearchOptions.defaultSearchOptions());

  const forwardSearch = (query: string, options: DefaultSearchOptionsProps) => {
    const newSearchOptions = recomposeSearchOptions(options);

    handleSearch(query, newSearchOptions);
  };

  const handleShowAdvancedToggle = () => {
    const newSearchOptions = { ...searchOptions, showAdvanced: !searchOptions.showAdvanced };

    setSearchOptions(newSearchOptions);
    forwardSearch(search, newSearchOptions);
  };

  const handleDateOptionsChange = (key: string) => (values: object) => {
    const newSearchOptions = {
      ...searchOptions,
      [key]: values,
    };

    setSearchOptions(newSearchOptions);
    forwardSearch(search, newSearchOptions);
  };

  const handleSearchQuery = (val: string) => forwardSearch(val, searchOptions);

  const { dates, showAdvanced } = searchOptions;

  return (
    <div className="mb-8 pb-8 pt-4 border-b border-gray-200">
      <div className="flex flex-col gap-2">
        <div className="flex w-full justify-between flex-row items-end space-x-2 md:items-center">
          <div className="flex flex-col space-x-0 md:space-x-2 space-y-2 md:space-y-0 md:flex-row w-full">
            <div className="w-full md:basis-72 max-w-xs min-w-150">
              <SearchInput
                defaultValue={search || ''}
                shouldDebounce={false}
                shouldReset={shouldResetSearch}
                onClearSearch={handleResetSearch}
                onSearch={handleSearchQuery}
                placeholder="Search subscribers"
              />
            </div>
            <div className="w-full space-y-1 md:flex md:space-y-0 md:space-x-1">
              <Dropdown
                name="status"
                value={status || 'all'}
                onSelect={(_name: string, value: string | string[]) => handleStatusChange(String(value))}
                options={[
                  { label: 'All Subscribers', value: 'all' },
                  { label: 'Active', value: 'active' },
                  { label: 'Inactive', value: 'inactive' },
                  { label: 'Needs Approval', value: 'needs_attention' },
                  { label: 'Pending', value: 'pending' },
                ]}
              />
              <TierSelect
                name="tier"
                tierId={tierId || 'all'}
                includeAll
                includeFree
                onChange={(_name: string, value: string | string[]) => handleTierIdChange(value)}
              />
            </div>
          </div>

          <Button variant="primary-inverse" onClick={handleShowAdvancedToggle}>
            <AdjustmentsVerticalIcon className={cx('w-4 h-4', showAdvanced ? 'text-primary-600' : 'text-grey-600')} />
          </Button>
          {isFetching && search && (
            <div className="hidden md:block pl-2">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </div>

      <SlideDown show={showAdvanced} duration={0}>
        <div className="flex w-full space-x-2 items-center py-6">
          <span className="whitespace-nowrap text-xs font-semibold text-gray-400">Advanced filters</span>
          <div className="border-b border-gray-200 w-full" />
        </div>
        <DateSearch
          selectValue={SubscriberDateField.CREATED_AT}
          label="Show subscribers where"
          options={dates}
          selectOptions={[{ label: 'Signup Date', value: SubscriberDateField.CREATED_AT }]}
          handleOptionsChange={handleDateOptionsChange('dates')}
        />
      </SlideDown>
    </div>
  );
};

export default Search;
