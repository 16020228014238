import { PropsWithChildren } from 'react';

import SettingsSectionHeader from './SettingsSectionHeader';

type Props = PropsWithChildren<{
  title: string;
  Icon?: React.ElementType;
  description?: string;
}>;

const SettingsSection = ({ Icon, title, description, children }: Props) => {
  return (
    <div className="flex flex-col space-y-4">
      <SettingsSectionHeader title={title} description={description} Icon={Icon} />
      {children}
    </div>
  );
};

export default SettingsSection;
