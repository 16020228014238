import { useNavigate } from 'react-router-dom';
import { ArrowUpRightIcon } from '@heroicons/react/20/solid';
import { ProgressBar } from '@tremor/react';

import { useCurrentPublicationState } from '@/context/current-publication-context';
import useMetrics from '@/hooks/useAdNetwork/publisher/useMetrics';
import { usePublication } from '@/hooks/usePublications';
import { AdNetworkMetrics } from '@/interfaces/ad_network/publisher/metrics';
import { usePlanPrice } from '@/routes/settings/billing/_hooks';
import { Button } from '@/ui/Button';
import { MetricCard } from '@/ui/MetricCard';

import OpportunityCalendar from './_components/OpportunityCalendar';
import PendingOpportunities from './_components/PendingOpportunities/PendingOpportunities';
import { UpcomingOpportunities } from './_components/UpcomingOpportunities';

interface Props {
  metrics: AdNetworkMetrics;
  planPercentage: number;
  showPlanPercentage: boolean;
  planInterval: string;
}

const formatter = new Intl.NumberFormat('en-US');

interface PercentageProps {
  planPercentage: number;
  planPercentageColor: any;
  planPercentageLabel: string;
}

const PercentageComponent = ({ planPercentage, planPercentageColor, planPercentageLabel }: PercentageProps) => {
  return (
    <div className="flex flex-col space-y-2">
      <ProgressBar percentageValue={planPercentage} color={planPercentageColor} className="w-full pr-2" />
      <span className="text-xs">{planPercentageLabel}</span>
    </div>
  );
};

const MonetizeAdsOverview = ({ metrics, planPercentage, showPlanPercentage, planInterval }: Props) => {
  const navigate = useNavigate();
  const planPercentageColor = planPercentage >= 100 ? 'green' : 'red';
  const planPercentageLabel = `This covers ${planPercentage >= 100 ? "100" : Math.round(planPercentage)}% of your ${planInterval} subscription plan`;

  return (
    <div className="py-6">
      <div className="grid gap-6 grid-cols-3">
        <div className="col-span-3 lg:col-span-2">
          <div className="grid grid-cols-2 gap-6">
            <div className="col-span-2 xl:col-span-1">
              <MetricCard
                variant="primary"
                label="Earnings This Month"
                value={metrics.total_earnings_this_month}
                initialVisibleComplements={0}
                additionalComponent={
                  showPlanPercentage && (
                    <PercentageComponent
                      planPercentage={planPercentage}
                      planPercentageColor={planPercentageColor}
                      planPercentageLabel={planPercentageLabel}
                    />
                  )
                }
                bottomComponent={
                  <Button
                    size="xs"
                    Icon={ArrowUpRightIcon}
                    iconRight
                    variant="primary"
                    onClick={() => {
                      navigate('/wallet');
                    }}
                  >
                    See earnings in Wallet
                  </Button>
                }
                complements={[
                  {
                    label: 'Earned Last Month',
                    value: metrics.total_earnings_last_month,
                  },
                  {
                    label: 'Earned In Total',
                    value: metrics.total_earnings,
                  },
                  {
                    label: 'Realized',
                    value: metrics.total_realized_earnings,
                  },
                  {
                    label: 'Pending',
                    value: metrics.total_pending_earnings,
                  },
                ]}
              />
            </div>
            <div className="col-span-2 h-full xl:col-span-1">
              <MetricCard
                variant="primary"
                className="h-full"
                label="Clicks"
                value={formatter.format(metrics.total_unique_eligible_clicked)}
                complements={[
                  {
                    label: 'Total placements',
                    value: formatter.format(metrics.total_placements),
                  },
                  {
                    label: 'Avg. per placement',
                    value: formatter.format(metrics.average_clicks_per_placement),
                  },
                ]}
              />
            </div>
            <div className="col-span-2 space-y-6">
              <div className="border-b border-gray-200 pb-2">
                <p className="font-semibold text-gray-900">Available opportunities</p>
              </div>
              <PendingOpportunities />
            </div>
          </div>
        </div>

        <div className="col-span-3 lg:col-span-1 space-y-8">
          <OpportunityCalendar />
          <UpcomingOpportunities />
        </div>
      </div>
    </div>
  );
};

export default function Loader() {
  const [publicationId] = useCurrentPublicationState();

  const { data: metrics, isSuccess: metricsSuccess } = useMetrics();
  const { data: publication, isSuccess: publicationSuccess } = usePublication(publicationId);
  const { data: planPrice, isSuccess: planPriceSuccess } = usePlanPrice({ organizationId: publication?.organization_id || '' });

  if (!metricsSuccess || !publicationSuccess || !planPriceSuccess) return null;

  const planPriceValue = (planPrice && planPrice.unit_amount / 100) || 0;
  const earningsNumber = (metrics && parseFloat(metrics.total_earnings_this_billing_cycle.replace(/[$]/g, ''))) || 0;
  const planPercentage = (planPriceValue > 0 && (earningsNumber / planPriceValue) * 100) || 0;
  const planInterval = planPrice.interval === "month" ? "monthly" : "annual";

  return (
    <MonetizeAdsOverview metrics={metrics} showPlanPercentage={planPriceValue > 0} planInterval={planInterval} planPercentage={planPercentage} />
  );
}
