import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ActionModal from '@/components/ActionModal';
import EllipsisDropdown from '@/components/EllipsisDropdown';
import { ItemColumn, ItemRow } from '@/components/ResourceList';
import { AlignType } from '@/components/ResourceList/types';
import { Typography } from '@/components/Typography';
import useDeleteSubscriberPreference from '@/hooks/useSubscriberPreferences/useDeleteSubscriberPreference';
import { PublicationSubscriberPreference, SubscriberPreferenceVisibility } from '@/interfaces/subscribers_preferences';

import { ICON_BY_SUBSCRIBER_PREFERENCE_TYPE, LABEL_BY_SUBSCRIBER_PREFERENCE_TYPE } from '../constants';

import SettingsBadge from './SettingsBadge';

interface Props {
  item: PublicationSubscriberPreference;
}

const PreferencesListItem = ({ item }: Props) => {
  const navigate = useNavigate();
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const { mutateAsync: deleteSubscriberPreference, isLoading } = useDeleteSubscriberPreference();

  const handleClickDelete = () => {
    setShowConfirmDeleteModal(true);
  };

  const handleClickConfirmDelete = () => {
    deleteSubscriberPreference({ id: item.id });
  };

  const Icon = ICON_BY_SUBSCRIBER_PREFERENCE_TYPE[item.preference_type];

  return (
    <>
      <ActionModal
        modalMessageType="danger"
        buttonType="danger"
        resourceId="confirm-delete-subscriber-preference"
        isOpen={showConfirmDeleteModal}
        onClose={() => setShowConfirmDeleteModal(false)}
        headerText="Delete Preference"
        descriptionText="Are you sure you want to continue?"
        onProceed={handleClickConfirmDelete}
        isWorking={isLoading}
      />
      <ItemRow>
        <ItemColumn align={AlignType.LEFT}>
          <Typography token="font-medium/text/sm" colorWeight="700">
            {item.name}
          </Typography>
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          <div className="w-56 sm:w-80">
            <Typography token="font-normal/text/sm" colorWeight="700">
              {item.description}
            </Typography>
          </div>
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          <span className="inline-flex flex-row gap-x-2 items-center">
            <Icon className="w-5 h-5 text-surface-500" />
            <Typography token="font-normal/text/sm" colorWeight="700">
              {LABEL_BY_SUBSCRIBER_PREFERENCE_TYPE[item.preference_type]}
            </Typography>
          </span>
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          <Typography token="font-normal/text/sm" color="secondary" colorWeight="600" className="font-mono">
            {`{${item.custom_field?.name}}`}
          </Typography>
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          <SettingsBadge
            isVisible={item.visibility === SubscriberPreferenceVisibility.VISIBLE}
            isRequired={item.required}
          />
        </ItemColumn>
        <ItemColumn align={AlignType.LEFT}>
          <EllipsisDropdown
            options={[
              { label: 'Edit', onClick: () => navigate(`/subscribers/preferences/${item.id}/edit`) },
              { label: 'Delete', onClick: handleClickDelete },
            ]}
          />
        </ItemColumn>
      </ItemRow>
    </>
  );
};

export default PreferencesListItem;
