/* eslint-disable */
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

interface Props {
  listItem: any;
  children: React.ReactChild;
  onRemoveItem: (id: string) => void;
}

const PublicationItem = ({ listItem, children, onRemoveItem }: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: listItem.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <>
      <div className={cx('z-10 bg-white rounded hover:bg-gray-100')} ref={setNodeRef} style={style}>
        <div className="flex">
          <div className=" w-full">
            <div className="flex items-center justify-between">
              <div className="pl-4 pr-6 py-3 w-2/3 truncate" {...attributes} {...listeners}>
                {children}
              </div>

              <div className="pl-2 pr-4 py-3 transition transition-none">
                <div className="flex">
                  <button
                    type="button"
                    onClick={() => onRemoveItem(listItem.id)}
                    className={cx('ml-1 flex-shrink-0 flex rounded-full text-gray-300 p-0.5 hover:text-red-600')}
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicationItem;
