const BooleanTypeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <path
      fill="currentColor"
      d="M13.75 4.375h-7.5a5.625 5.625 0 0 0 0 11.25h7.5a5.625 5.625 0 1 0 0-11.25Zm0 10h-7.5a4.375 4.375 0 1 1 0-8.75h7.5a4.375 4.375 0 0 1 0 8.75Zm-7.5-7.5a3.125 3.125 0 1 0 0 6.25 3.125 3.125 0 0 0 0-6.25Zm0 5a1.875 1.875 0 1 1 0-3.75 1.875 1.875 0 0 1 0 3.75Z"
    />
  </svg>
);

export default BooleanTypeIcon;
