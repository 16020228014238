import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { ArrowSquareOut } from "@phosphor-icons/react";

import { useWebsiteContext } from "@/context/website-context";
import { useCurrentPublication } from "@/hooks";
import { useUpdateSiteVersion } from "@/hooks/useSiteVersion";

import { Switch } from "../../_components/UI/Switch";
import { Text } from "../../_components/UI/Text";
import { FBSettings } from "../_components/FBSettings";
import { MetadataSettings } from "../_components/MetadataSettings";
import SettingsItem from "../_components/SettingsItem";
import SettingsSection from "../_components/SettingsSection";
import { XSettings } from "../_components/XSettings";

const WebsiteSettingsSEO = () => {
  const { data: currentPublication } = useCurrentPublication();
  const { site } = useWebsiteContext();
  const updateSiteVersion = useUpdateSiteVersion({
    id: site?.draft_site_version?.id || '',
    publishOnUpdate: true,
    onSuccess: () => {
      toast.success('Saved!');
    },
  });

  return (
    <div>
      <SettingsSection>
        <SettingsItem
          title="Search Engine Indexing"
          description="By default, websites are made discoverable on the web. Turning this off will add a noindex meta tag in the header of your publication to prevent your website from showing up in Google Search."
        >
          <Switch
            id="noindex_enabled"
            labelText="Discoverable on the web"
            checked={!site?.draft_site_version?.metadata?.noindex_enabled}
            onCheckedChange={(value: boolean) => {
              updateSiteVersion.mutate({
                metadata: {
                  noindex_enabled: !value,
                },
              });
            }}
          />
        </SettingsItem>
      </SettingsSection>
      <SettingsSection>
        <MetadataSettings
          onUpdate={(key, value) => {
            updateSiteVersion.mutate({
              metadata: {
                [key]: value,
              },
            })
          }}
        />
      </SettingsSection>

      <SettingsSection>
        <XSettings
          onUpdate={(key, value) => {
            updateSiteVersion.mutate({
              metadata: {
                [key]: value,
              },
            })
          }}
        />
      </SettingsSection>

      <SettingsSection>
        <FBSettings
          onUpdate={(key, value) => {
            updateSiteVersion.mutate({
              metadata: {
                [key]: value,
              },
            })
          }}
        />
      </SettingsSection>
      <SettingsSection>
        <SettingsItem
          title="Site Map"
          description="Provide information about the pages on your site to allow search engines to crawl your site more efficiently."
        >
          <div className="flex flex-col gap-1.5 pt-4">
            <Text size="sm" variant="secondary">Auto-generated sitemap:</Text>
            <Link to={`https://${currentPublication?.hostname}/sitemap.xml`} target="_blank" className="flex items-center gap-2 px-3 py-2 rounded-lg bg-wb-secondary w-fit borderr border-wb-primary">
              <div className="w-2 h-2 rounded-full bg-wb-success" />
              <Text size="xs">{currentPublication?.hostname}/sitemap.xml</Text>
              <ArrowSquareOut size={16} className="text-wb-secondary" />
            </Link>
          </div>
        </SettingsItem>
      </SettingsSection>
      <SettingsSection>
        <SettingsItem
          title="Robots.txt"
          description="Tell search engine crawlers what they can crawl your site."
        >
          <div className="flex flex-col gap-1.5 pt-4">
            <Text size="sm" variant="secondary">Auto-generated robots.txt:</Text>
            <Link to="https://{currentPublication?.hostname}/robots.txt" target="_blank" className="flex items-center gap-2 px-3 py-2 rounded-lg bg-wb-secondary w-fit borderr border-wb-primary">
              <div className="w-2 h-2 rounded-full bg-wb-success" />
              <Text size="xs">{currentPublication?.hostname}/robots.txt</Text>
              <ArrowSquareOut size={16} className="text-wb-secondary" />
            </Link>
          </div>
        </SettingsItem>
      </SettingsSection>
    </div>
  );
};

export default WebsiteSettingsSEO;
