import { ChangeEvent, Dispatch, Fragment, SetStateAction, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import Tippy from '@tippyjs/react';
import { Instance } from 'tippy.js';

import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { Post } from '@/interfaces/post';

import SubtitleActionsMenu from '../SubtitleActionsMenu';
import SubtitleTooltipMenu from '../SubtitleTooltipMenu.tsx';

interface Props {
  onSubTitleTippyHide: () => void;
  onSubTitleTippyShow: () => void;
  post: Post;
  subTitleTippyMenusShown: boolean;
  setShowSubtitle: Dispatch<SetStateAction<boolean>>;
  updatePost: (data: Post) => void;
}

const Subtitle = ({
  onSubTitleTippyHide,
  onSubTitleTippyShow,
  post,
  setShowSubtitle,
  subTitleTippyMenusShown,
  updatePost,
}: Props) => {
  const tooltipMenuTippyInstance = useRef<Instance>();
  const updateRef = useRef<number>();
  const [showSubTitleActionMenus, setShowSubTitleActionMenus] = useState(false);

  const getSelectionText = () => {
    let text;

    if (window.getSelection) {
      text = window.getSelection()?.toString();
    }

    return text;
  };

  const onSubtitleTextMouseUp = () => {
    const selection = getSelectionText();
    if (selection && selection.length && tooltipMenuTippyInstance.current) {
      tooltipMenuTippyInstance.current.show();
    }
  };

  const onSubTitleMouseEnter = () => {
    setShowSubTitleActionMenus(true);
  };

  const updatePostSubTitle = (value: string | undefined) => {
    updatePost({ ...post, ...{ web_subtitle: value } });
  };

  const onSubTitleMouseLeave = () => {
    if (!subTitleTippyMenusShown) {
      setShowSubTitleActionMenus(false);
    }
  };

  const handlePostSubTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (updateRef.current) {
      clearTimeout(updateRef.current);
    }

    updateRef.current = window.setTimeout(() => {
      updatePostSubTitle(value);
    }, 300);
  };

  return (
    <div className="relative" onMouseEnter={onSubTitleMouseEnter} onMouseLeave={onSubTitleMouseLeave}>
      <Transition
        show={showSubTitleActionMenus}
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute -ml-10 mt-3 z-50">
          <Tippy
            offset={[0, 8]}
            placement="bottom-start"
            popperOptions={{
              modifiers: [{ name: 'flip' }],
            }}
            onShow={onSubTitleTippyShow}
            onHide={onSubTitleTippyHide}
            trigger="click"
            interactive
            content={
              <div className="absolute z-50">
                <SubtitleActionsMenu
                  post={post}
                  updatePost={updatePost}
                  setShowSubtitle={(update) => setShowSubtitle(update)}
                />
              </div>
            }
          >
            <div>
              <Button $leftSlot={<Icon name="DragMenu" />} $variant="tertiary" $size="small" $isIconButton />
            </div>
          </Tippy>
        </div>
      </Transition>
      <div className="relative">
        <Tippy
          onCreate={(instance) => {
            tooltipMenuTippyInstance.current = instance;
          }}
          offset={[0, 8]}
          placement="top-start"
          popperOptions={{
            modifiers: [{ name: 'flip' }],
          }}
          onShow={onSubTitleTippyShow}
          onHide={onSubTitleTippyHide}
          trigger="manual"
          interactive
          content={
            <div className="absolute z-50 mt-[-3rem]">
              <SubtitleTooltipMenu post={post} updatePost={updatePost} setShowSubtitle={setShowSubtitle} />
            </div>
          }
        >
          <input
            onMouseUp={onSubtitleTextMouseUp}
            className="selection:bg-pink-200 w-full placeholder-gray-300 focus:border-transparent focus:ring-0 box-0 p-0 m-0 border-0 outline-0 focus:outline-none focus:border-none active:outline-none active:border-none mt-4 text-gray-600"
            onChange={handlePostSubTitleChange}
            type="text"
            name="post-subtitle"
            placeholder="Add a subtitle"
            defaultValue={post.web_subtitle}
          />
        </Tippy>
      </div>
      {!post.display_subtitle_in_email && (
        <div className="absolute top-0 right-0 mt-3 z-50">
          <Tippy
            offset={[0, 8]}
            placement="top"
            popperOptions={{
              modifiers: [{ name: 'flip' }],
            }}
            trigger="mouseenter"
            interactive
            content={
              <div className="bg-white shadow-lg px-2 py-1 rounded-lg border border-gray-200 text-sm min-w-[8rem] text-center text-gray-500">
                Hidden in email
              </div>
            }
          >
            <div className="absolute w-20 h-20 text-gray-300">
              <Icon name="Hide" />
            </div>
          </Tippy>
        </div>
      )}
    </div>
  );
};

export default Subtitle;
