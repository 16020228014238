import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { TiptapCollabProvider } from '@hocuspocus/provider';
import * as Sentry from '@sentry/react';
import { Editor as CoreEditor, JSONContent } from '@tiptap/core';
import { CollabHistoryVersion, CollabOnUpdateProps } from '@tiptap-pro/extension-collaboration-history';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import { twMerge } from 'tailwind-merge';

import LoadingBox from '@/components/LoadingBox';
import { TiptapEditor } from '@/components/TiptapEditor';
import { RedoButton, UndoButton } from '@/components/TiptapEditor/components/buttons';
import { SearchAndReplaceMenu } from '@/components/TiptapEditor/components/menus/search';
import { Avatar } from '@/components/TiptapEditor/components/ui/Avatar';
import Button from '@/components/TiptapEditor/components/ui/Button';
import Icon from '@/components/TiptapEditor/components/ui/Icon';
import Tooltip from '@/components/TiptapEditor/components/ui/Tooltip';
import {
  AUDIO_NEWSLETTER_CHARACTER_LIMIT,
  FALL_BACK_CHARACTER_LIMIT,
} from '@/components/TiptapEditor/extensions/extension-kit';
import { PublicationProvider } from '@/components/TiptapEditor/lib/context/PublicationContext';
import { GlobalStyles } from '@/components/TiptapEditor/lib/GlobalStyles';
import { useProvider } from '@/components/TiptapEditor/lib/hooks/useProvider';
import useUpdatePostContent from '@/components/TiptapEditor/lib/hooks/useUpdatePostContent';
import { EditorUser } from '@/components/TiptapEditor/lib/types';
import { generateYdoc } from '@/components/TiptapEditor/lib/utils/generateYdoc';
import { useCurrentUser } from '@/context/current-user-context';
import { useSettings } from '@/context/settings-context';
import { useCurrentPublication, usePostInformation, usePostTextToSpeechConfig } from '@/hooks';
import { Post } from '@/interfaces/post';

import { useEditorContext } from '../EditorContext';
import { ThreadPopover } from '../ThreadPopover';
import AdsBanner from '../v2/AdsBanner';
import PostMeta from '../v2/Compose/PostMeta';
import SaveAsTemplateModal from '../v2/Compose/SaveAsTemplateModal';

import { HistoryModal } from './HistoryModal';
import StatusBadge from './StatusBadge';
import WarningNotifier from './WarningNotifier';

const MemoizedEditor = memo(TiptapEditor);

interface Props {
  post: Post;
  errors: { [key: string]: string };
  isV2?: boolean;
}

const Editor = ({ isV2, post, errors }: Props) => {
  const { settings } = useSettings();
  const { currentUser } = useCurrentUser();
  const postInfoQuery = usePostInformation({ id: post.id });
  const { data: currentPublication } = useCurrentPublication();
  const [lostConnection, setLostConnection] = useState(false);
  const [hitSizeThreshold, setHitSizeThreshold] = useState(false);
  const [hitAudioNewsletterThreshold, setHitAudioNewsletterThreshold] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [versions, setVersions] = useState<Array<CollabHistoryVersion> | null>(null);
  const [currentVersion, setCurrentVersion] = useState<number | null>(null);
  const [showSaveAsTemplateModal, setShowSaveAsTemplateModal] = useState(false);
  const [showAdsBanner, setShowAdsBanner] = useState(true);

  const navigate = useNavigate();
  const {
    editor,
    editorIsLoading,
    wordCount,
    setWordCount,
    users,
    setUsers,
    setUnsavedChanges,
    setIsSaving,
    threads,
    showSidebar,
    setShowSidebar,
    collaborationEnabled,
    showThreadsSidebar,
    setShowThreadsSidebar,
    activeThreadId,
    unselectThread,
  } = useEditorContext();

  const unresolvedThreadsCount = useMemo(() => {
    return threads?.filter((t) => !t.resolvedAt)?.length || 0;
  }, [threads]);

  const handleToggleThreadsSidebar = useCallback(() => {
    const shouldShowThreadsSidebar = !showThreadsSidebar;
    setShowThreadsSidebar(shouldShowThreadsSidebar);

    if (!showSidebar && shouldShowThreadsSidebar) {
      setShowSidebar(true);
    }
  }, [setShowThreadsSidebar, showThreadsSidebar, setShowSidebar, showSidebar]);

  const queryClient = useQueryClient();

  const formatter = new Intl.NumberFormat('en-US');
  const { warnings } = postInfoQuery.data || {};

  const refetchInformation = useMemo(
    () =>
      debounce(() => {
        postInfoQuery.refetch();
      }, 1_000),
    [postInfoQuery]
  );

  const saveContentMutation = useUpdatePostContent({
    postId: post.id,
    onSuccess: () => {
      setIsSaving(false);
      setUnsavedChanges(false);
    },
    onError: () => {
      toast.error('Something went wrong');
    },
  });

  // Cache users profile data for collaboration cursor.
  const user = useMemo(() => {
    const userFirstName = currentUser?.first_name;
    const userLastName = currentUser?.last_name;
    const userName = userFirstName || userLastName ? `${userFirstName} ${userLastName}`.trim() : 'Unknown user';

    const colors = ['#958DF1', '#F98181', '#FBBC88', '#FAF594', '#70CFF8', '#94FADB', '#B9F18D'];
    const userColor = colors[Math.floor(Math.random() * colors.length)];

    return {
      id: currentUser?.id,
      name: userName,
      color: userColor,
    };
  }, [currentUser]);

  // Sort collaboration users to put them in the appropriate order.
  const sortedUsers = useMemo(() => {
    if (!users) {
      return [];
    }

    return users.sort((a, b) => {
      if (!b.initials) {
        return -1;
      }

      if (!a.initials) {
        return 1;
      }

      if (a.initials < b.initials) {
        return -1;
      }

      if (a.initials > b.initials) {
        return 1;
      }

      if (a.clientId > b.clientId) {
        return -1;
      }

      return 0;
    });
  }, [users]);

  // Cache initial content and use it in case collaborative editing is not active.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialContent = useMemo(() => (!collaborationEnabled ? post.tiptap_state : undefined), []);

  // Check to see if the post needs a ydoc generated. If it has JSON content but is
  // marked as not having a ydoc, we need to generate it to be loaded into the editor.
  // This would be the case for imported posts, or posts created in the old editor
  const initialYdoc = useMemo(() => {
    const { has_ydoc: hasYdoc, tiptap_state: json } = post;
    const hasJsonContent = !!(json && Object.keys(json).length > 0);

    if (collaborationEnabled && hasJsonContent && !hasYdoc) {
      return generateYdoc({ json, extensionKitConfig: { allowAds: true, allowPolls: true } });
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initialise collaboration functionality.
  const { provider } = useProvider({
    postId: post.id,
    publicationId: post.publication_id,
    initialDocument: initialYdoc,
  });

  const isDisconnected = (collaborationEnabled && !!provider && provider.status !== 'connected') || lostConnection;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveContent = useCallback(
    debounce(async (json: JSONContent) => {
      setIsSaving(true);
      await saveContentMutation.mutate(json);
    }, 500),
    []
  );

  const { data: textToSpeechConfig } = usePostTextToSpeechConfig(post.id);

  const handleCharacterLimitWarnings = useCallback(
    ({ editor: editorInstance }: { editor: CoreEditor }) => {
      setWordCount(editorInstance.storage.characterCount.words());
      const characterCount = editorInstance.storage.characterCount.characters();
      const characterLimit = settings?.max_tip_tap_character_limit || FALL_BACK_CHARACTER_LIMIT;
      setHitSizeThreshold(characterCount >= characterLimit);

      const audioNewsletterCharacterLimit = AUDIO_NEWSLETTER_CHARACTER_LIMIT;
      setHitAudioNewsletterThreshold(characterCount >= audioNewsletterCharacterLimit);
    },
    [settings?.max_tip_tap_character_limit, setHitAudioNewsletterThreshold, setWordCount]
  );

  const handleUpdate = useCallback(
    async ({ editor: editorInstance }: { editor: CoreEditor }) => {
      handleCharacterLimitWarnings({ editor: editorInstance });

      if (!collaborationEnabled) {
        setUnsavedChanges(true);
        saveContent(editorInstance.getJSON());
      }
    },
    [collaborationEnabled, saveContent, setUnsavedChanges, handleCharacterLimitWarnings]
  );

  const handleCreate = useCallback(
    ({ editor: editorInstance }: { editor: CoreEditor }) => {
      handleCharacterLimitWarnings({ editor: editorInstance });
    },
    [handleCharacterLimitWarnings]
  );

  // In case the state of collaborative users is changing …
  const handleUsersUpdate = useCallback(
    (updatedUsers: EditorUser[]) => {
      setUsers(updatedUsers);
    },
    [setUsers]
  );

  const handleVersionUpdate = useCallback((payload: CollabOnUpdateProps) => {
    setVersions(payload.versions);
    setCurrentVersion(payload.currentVersion);
  }, []);

  const handleRevert = useCallback(
    (version: number) => {
      editor?.commands.revertToVersion(version);
    },
    [editor?.commands]
  );

  const timeoutId = useRef<NodeJS.Timeout>();

  // We have seen situations where the editor seems to be connected but changes don't actually get synced.
  // This works as a last resort to fight against people losing content. It's a fairly naive approach but
  // in practice - if you are connected to the internet - the editor should basically never be behind more
  // than a couple changes when syncing.
  useEffect(() => {
    provider?.on('unsyncedChanges', (changeCount: number) => {
      if (changeCount === 0) {
        clearTimeout(timeoutId.current);
        return;
      }

      clearTimeout(timeoutId.current);

      timeoutId.current = setTimeout(() => {
        if (provider.unsyncedChanges > 0) {
          // eslint-disable-next-line no-console
          console.warn('Large number of unsynced editor changes');
          setLostConnection(true);
        }
      }, 5000);
    });
  }, [provider]);

  useEffect(() => {
    if (isDisconnected) {
      Sentry.captureMessage('Post editor disconnected');
    }
  }, [currentUser?.id, isDisconnected, post.id]);

  const handleExit = () => {
    provider?.destroy();

    navigate(`/posts/${post.id}`);

    queryClient.invalidateQueries(['posts', post.id], { exact: true });
    queryClient.invalidateQueries(['posts', post.id, 'preview'], { exact: true });
  };

  if (!settings || !currentPublication) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{`Editing "${post.web_title}"`}</title>
      </Helmet>
      {activeThreadId !== null ? <ThreadPopover id={activeThreadId} onClose={unselectThread} /> : null}
      <div className="flex flex-col h-full m-0" style={{ borderTopRightRadius: 'inherit' }}>
        <div className="sticky top-0 z-10 flex-none md:block" style={{ borderTopRightRadius: 'inherit' }}>
          <div
            className={cx(
              { 'z-10 flex items-center justify-between w-full h-12 px-2 md:px-6 bg-white': isV2 },
              { 'z-10 flex items-center justify-between w-full h-12 px-6 bg-white': !isV2 }
            )}
            style={{ borderTopRightRadius: 'inherit' }}
          >
            <div
              className={cx('z-10 flex items-center justify-start w-full', { 'space-x-3': !isV2 })}
              style={{ borderTopRightRadius: 'inherit' }}
            >
              <button
                type="button"
                className={cx(
                  {
                    'hidden md:flex items-center text-sm font-semibold text-gray-500 cursor-pointer hover:text-gray-800 mr-4':
                      isV2,
                  },
                  { 'flex items-center text-sm font-semibold text-gray-500 cursor-pointer hover:text-gray-800': !isV2 }
                )}
                onClick={handleExit}
              >
                <ChevronLeftIcon className="w-3 h-3 mr-1" />
                Exit
              </button>
              <StatusBadge
                post={post}
                errors={hitSizeThreshold ? { base: 'Post size limit reached' } : errors}
                warnings={
                  textToSpeechConfig?.enabled && hitAudioNewsletterThreshold
                    ? { base: 'Audio Newsletter character limit reached' }
                    : {}
                }
              />
              <WarningNotifier warnings={warnings} />
            </div>
            {sortedUsers.length > 0 && (
              <div className="flex items-center space-x-1">
                {sortedUsers.map((sortedUser) => (
                  <Avatar key={sortedUser.clientId} $color={sortedUser.color} tooltipTitle={sortedUser.name}>
                    {sortedUser.initials}
                  </Avatar>
                ))}
              </div>
            )}
            <div
              className={cx('hidden md:block text-xs text-gray-500 whitespace-nowrap ml-6', showSidebar ? '' : 'mr-6')}
            >
              {wordCount ? `${formatter.format(wordCount)} ${wordCount === 1 ? 'word' : 'words'}` : <>&nbsp;</>}
            </div>
            {!!editor && (
              <div className="flex items-center ml-2 space-x-1">
                <UndoButton $variant="tertiary" $size="small" editor={editor} $isIconButton $showTooltip />
                <RedoButton $variant="tertiary" $size="small" editor={editor} $isIconButton $showTooltip />
                <SearchAndReplaceMenu editor={editor} />
              </div>
            )}

            <div className={twMerge('flex mx-1 gap-1', showSidebar ? 'mr-1' : 'mr-8')}>
              {settings?.editor_threads && settings?.collaborative_editing && (
                <div className="ml-4">
                  <Tooltip title={`Comments ${unresolvedThreadsCount ? `- ${unresolvedThreadsCount} open` : ''}`}>
                    <Button
                      $leftSlot={
                        <div className="relative w-4 h-4">
                          <Icon name="Comment" />
                          {unresolvedThreadsCount ? (
                            <span className="absolute -top-2 -right-2 w-2 h-2 bg-red-500 rounded-full" />
                          ) : null}
                        </div>
                      }
                      $isIconButton
                      $isToggleButton
                      $variant="tertiary"
                      $size="small"
                      $active={showThreadsSidebar && showSidebar}
                      onClick={handleToggleThreadsSidebar}
                    />
                  </Tooltip>
                </div>
              )}
              {collaborationEnabled ? (
                <Tooltip title="Version history">
                  <Button
                    $leftSlot={<Icon name="History" />}
                    $isIconButton
                    $isToggleButton
                    $variant="tertiary"
                    $size="small"
                    $active={showHistoryModal}
                    onClick={() => setShowHistoryModal(true)}
                  />
                </Tooltip>
              ) : null}
              {isV2 && (
                <Tooltip title="Save as template">
                  <Button
                    $leftSlot={<Icon name="Template" />}
                    $isIconButton
                    $isToggleButton
                    $variant="tertiary"
                    $size="small"
                    onClick={() => setShowSaveAsTemplateModal(true)}
                  />
                </Tooltip>
              )}
              <Link to="/post_themes/edit?settings=true" target="_blank">
                <Tooltip title="Newsletter Builder">
                  <Button
                    $leftSlot={<Icon name="Settings" />}
                    $isIconButton
                    $isToggleButton
                    $variant="tertiary"
                    $size="small"
                  />
                </Tooltip>
              </Link>
              <a href="https://support.beehiiv.com/hc/en-us" target="_blank" rel="noreferrer">
                <Tooltip title="Help center">
                  <Button
                    $leftSlot={<Icon name="Help" />}
                    $isIconButton
                    $isToggleButton
                    $variant="tertiary"
                    $size="small"
                  />
                </Tooltip>
              </a>
            </div>

            {/* commenting since there was only one icon left top after moving history & theme setting out of 3 dots menu */}
            {/* <div className="ml-1 md:mr-5">
              <MoreMenu showHistory={collaborationEnabled} onHistory={() => setShowHistoryModal(true)} />
            </div> */}
          </div>
        </div>
        <div className="flex-auto overflow-auto">
          {isV2 && editor && (
            <div className="relative mt-14 z-50">
              <PostMeta post={post} setIsSaving={setIsSaving} editor={editor} />
            </div>
          )}
          {isV2 && showAdsBanner && (
            <div className="relative mt-14 z-50">
              <AdsBanner setShowAdsBanner={setShowAdsBanner} />
            </div>
          )}
          <div className="relative z-0 px-2">
            <PublicationProvider id={post.publication_id}>
              <GlobalStyles colors={post.color_palette} />
              <LoadingBox
                isLoading={(collaborationEnabled && !provider) || editorIsLoading}
                height="150px"
                isError={isDisconnected}
                backgroundClassName="bg-transparent"
                errorText="Connection was lost. Try refreshing the page to reconnect."
              >
                <MemoizedEditor
                  publicationId={post.publication_id}
                  settings={settings}
                  onUpdate={handleUpdate}
                  onCreate={handleCreate}
                  onBlur={refetchInformation}
                  provider={provider}
                  onUsersUpdate={handleUsersUpdate}
                  userId={user.id}
                  userName={user.name}
                  userColor={user.color}
                  className="pt-12 pb-[25vh]"
                  content={initialContent}
                  usesCollaboration={collaborationEnabled}
                  allowPolls
                  allowAds
                  useCollabHistory={collaborationEnabled}
                  onVersionUpdate={handleVersionUpdate}
                  threadsSidebarOpen={showThreadsSidebar}
                />
              </LoadingBox>
            </PublicationProvider>
          </div>
        </div>
      </div>
      {isV2 && (
        <SaveAsTemplateModal
          post={post}
          isOpen={showSaveAsTemplateModal}
          onClose={() => setShowSaveAsTemplateModal(false)}
        />
      )}
      {collaborationEnabled && provider && (
        <HistoryModal
          open={showHistoryModal}
          onClose={() => setShowHistoryModal(false)}
          provider={provider as TiptapCollabProvider}
          onRevert={handleRevert}
          publicationId={post.publication_id}
          versions={versions}
          currentVersion={currentVersion}
          settings={settings}
        />
      )}
    </>
  );
};

export default Editor;
