import { ChangeEvent, Dispatch, Fragment, SetStateAction, useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import Tippy from '@tippyjs/react';
import { Instance } from 'tippy.js';

import { Button } from '@/components/TiptapEditor/components/ui/Button';
import { Icon } from '@/components/TiptapEditor/components/ui/Icon';
import { Author } from '@/interfaces/author';
import { ContentTag } from '@/interfaces/content_tag';
import { Post } from '@/interfaces/post';

import TitleActionsMenu from '../TitleActionsMenu';
import TitleContentMenu from '../TitleContentMenu';
import TitleTooltipMenu from '../TitleTooltipMenu';

interface Props {
  guestAuthors?: {
    guest_authors: Author[];
  };
  onSubTitleTippyHide: () => void;
  onSubTitleTippyShow: () => void;
  post: Post;
  setShowCreateGuestAuthorModal: (value: boolean) => void;
  setShowImageLibrary: (value: boolean) => void;
  setShowSubtitle: Dispatch<SetStateAction<boolean>>;
  showSubtitle: boolean;
  thumbnailFileRef: React.RefObject<HTMLInputElement>;
  updateDisplayDate: (date: Date | null) => void;
  updatePost: (data: Post) => void;
  users?: Author[];
  setShowCreateContentTagModal: (value: boolean) => void;
  contentTags?: ContentTag[];
  refetchContentTags: () => void;
}

const Title = ({
  guestAuthors,
  onSubTitleTippyHide,
  onSubTitleTippyShow,
  post,
  setShowCreateGuestAuthorModal,
  setShowImageLibrary,
  setShowSubtitle,
  showSubtitle,
  thumbnailFileRef,
  updateDisplayDate,
  updatePost,
  users,
  setShowCreateContentTagModal,
  contentTags,
  refetchContentTags,
}: Props) => {
  const tooltipMenuTippyInstance = useRef<Instance>();
  const updateRef = useRef<number>();
  const [showTitleActionMenus, setShowTitleActionMenus] = useState(false);
  const [titleTippyMenusShown, setTitleTippyMenusShown] = useState(false);
  const postAuthors = users?.filter((user) => post.user_ids.includes(user.id));
  const postGuestAuthors = guestAuthors?.guest_authors.filter((guestUser) =>
    post.guest_author_ids.includes(guestUser.id)
  );
  const showAuthorsButton = postAuthors?.length === 0 && postGuestAuthors?.length === 0;

  const getSelectionText = () => {
    let text;

    if (window.getSelection) {
      text = window.getSelection()?.toString();
    }

    return text;
  };

  const onTitleTextMouseUp = () => {
    const selection = getSelectionText();
    if (selection && selection.length && tooltipMenuTippyInstance.current) {
      tooltipMenuTippyInstance.current.show();
    }
  };

  const onTitleMouseEnter = () => {
    setShowTitleActionMenus(true);
  };

  const onTitleMouseLeave = () => {
    if (!titleTippyMenusShown) {
      setShowTitleActionMenus(false);
    }
  };

  const onTitleTippyShow = () => {
    setTitleTippyMenusShown(true);
  };

  const onTitleTippyHide = () => {
    setTitleTippyMenusShown(false);
  };

  const updatePostTitle = (value: string) => {
    updatePost({ ...post, ...{ web_title: value } });
  };

  const toggleSubtitle = () => {
    if (showSubtitle) {
      updatePost({ ...post, ...{ web_subtitle: undefined, display_subtitle_in_email: true } });
    }

    setShowSubtitle((prev: boolean) => !prev);
  };

  const handlePostTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (updateRef.current) {
      clearTimeout(updateRef.current);
    }

    updateRef.current = window.setTimeout(() => {
      const derivedValue = !value.trim().length ? 'New Post' : value;

      updatePostTitle(derivedValue);
    }, 300);
  };

  return (
    <div className="relative" onMouseEnter={onTitleMouseEnter} onMouseLeave={onTitleMouseLeave}>
      <TitleContentMenu
        users={users || []}
        guestAuthors={guestAuthors?.guest_authors || []}
        post={post}
        show={showTitleActionMenus}
        updateDisplayDate={updateDisplayDate}
        toggleSubtitle={toggleSubtitle}
        showAuthorsButton={showAuthorsButton}
        updatePost={updatePost}
        setShowCreateGuestAuthorModal={setShowCreateGuestAuthorModal}
        fileRef={thumbnailFileRef}
        setShowImageLibrary={setShowImageLibrary}
        setShowCreateContentTagModal={setShowCreateContentTagModal}
        contentTags={contentTags}
        refetchContentTags={refetchContentTags}
      />
      <Transition
        show={showTitleActionMenus}
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute -ml-10 z-50">
          <Tippy
            offset={[0, 8]}
            placement="bottom-start"
            popperOptions={{
              modifiers: [{ name: 'flip' }],
            }}
            onShow={onTitleTippyShow}
            onHide={onTitleTippyHide}
            trigger="click"
            interactive
            content={
              <div className="absolute z-50">
                <TitleActionsMenu post={post} updatePost={updatePost} />
              </div>
            }
          >
            <div>
              <Button $leftSlot={<Icon name="DragMenu" />} $variant="tertiary" $size="small" $isIconButton />
            </div>
          </Tippy>
        </div>
      </Transition>
      <div className="relative">
        <Tippy
          offset={[0, 8]}
          onCreate={(instance) => {
            tooltipMenuTippyInstance.current = instance;
          }}
          placement="top-start"
          popperOptions={{
            modifiers: [{ name: 'flip' }],
          }}
          onShow={onSubTitleTippyShow}
          onHide={onSubTitleTippyHide}
          trigger="manual"
          interactive
          content={
            <div className="mb-20 absolute z-50 mt-[-3rem]">
              <TitleTooltipMenu />
            </div>
          }
        >
          <input
            onMouseUp={onTitleTextMouseUp}
            className="selection:bg-pink-200 w-full placeholder-gray-300 focus:border-transparent focus:ring-0 box-0 p-0 m-0 border-0 outline-0 focus:outline-none focus:border-none active:outline-none active:border-none font-semibold text-4xl"
            onChange={handlePostTitleChange}
            type="text"
            name="post-title"
            placeholder="New Post"
            defaultValue={post.web_title}
          />
        </Tippy>
        {!post.display_title_in_email && (
          <div className="absolute top-0 right-0 z-50 mt-3">
            <Tippy
              offset={[0, 8]}
              placement="top"
              popperOptions={{
                modifiers: [{ name: 'flip' }],
              }}
              trigger="mouseenter"
              interactive
              content={
                <div className="bg-white shadow-lg px-2 py-1 rounded-lg border border-gray-200 text-sm min-w-[8rem] text-center text-gray-600">
                  Hidden in email
                </div>
              }
            >
              <div className="absolute w-20 h-20 text-gray-300">
                <Icon name="Hide" />
              </div>
            </Tippy>
          </div>
        )}
      </div>
    </div>
  );
};

export default Title;
