import { ElementType, useMemo } from 'react';
import { BoltIcon, SparklesIcon } from '@heroicons/react/20/solid';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import cx from 'classnames';

import { PlanTag } from '@/components/Plan';
import { Typography } from '@/components/Typography';

interface Props {
  title: string;
  Icon?: ElementType;
  planType?: 'trial' | 'launch' | 'grow' | any;
  isActive?: boolean;
  isExternal?: boolean;
  notIncludedInTrial?: boolean;
  includedInGrow?: boolean;
}

const COLOR_MAP: any = Object.freeze({
  primary: {
    colorWeight: '500',
    iconTextColor: 'text-action-primary-500',
  },
  secondary: {
    colorWeight: '900',
    iconTextColor: 'text-action-secondary-900',
  },
  tertiary: {
    colorWeight: '900',
    iconTextColor: 'text-action-tertiary-900',
  },
});

const getVariant = (planType: string, isActive: boolean, notIncludedInTrial: boolean) => {
  if (!isActive) return 'primary';
  if (planType === 'trial' && notIncludedInTrial) return 'secondary';
  if (planType === 'trial') return 'tertiary';
  if (planType === 'launch') return 'secondary';
  if (planType === 'grow') return 'secondary';
  if (isActive) return 'secondary';
  return 'primary';
};

const NavItem = ({
  title,
  Icon,
  planType,
  isActive = false,
  isExternal = false,
  notIncludedInTrial = false,
  includedInGrow = false,
}: Props) => {
  const isTrial = planType === 'trial';
  const isLaunch = planType === 'launch';
  const isGrow = planType === 'grow';
  const isScale = planType === 'scale';
  const isMax = planType === 'max';
  const isCustom = planType === 'custom';
  const isCreator = planType === 'creator';
  const hideGrowItems = isGrow && includedInGrow;
  const hidePill = isScale || isMax || isCustom || isCreator || hideGrowItems;
  const isUpgrade = isLaunch || isGrow || notIncludedInTrial;

  const variant = useMemo(() => {
    return getVariant(planType || '', isActive, notIncludedInTrial);
  }, [planType, isActive, notIncludedInTrial]);

  return (
    <div className="flex space-x-1.5 items-center justify-between py-1 px-2 -mt-0.5 w-full">
      <div className="flex space-x-1.5 items-center justiy-between w-full">
        {Icon && <Icon className={cx('w-4 h-4', COLOR_MAP[variant].iconTextColor)} />}
        <div className="flex space-x-1.5 items-center">
          <Typography weight="semibold" size="sm" color={variant} colorWeight={COLOR_MAP[variant].colorWeight}>
            {title}
          </Typography>
          {isExternal && <ArrowTopRightOnSquareIcon className="h-4 w-4 text-action-primary-400 -mt-0.5" />}
        </div>
      </div>

      {!hidePill && (
        <>
          {isUpgrade && <PlanTag text="Upgrade" variant="secondary" Icon={BoltIcon} />}
          {isTrial && !notIncludedInTrial && <PlanTag text="Trial" variant="tertiary" Icon={SparklesIcon} />}
        </>
      )}
    </div>
  );
};

export default NavItem;
