import ActionModal from '@/components/ActionModal';
import { Typography } from '@/components/Typography';
import UrlCopy from '@/components/UrlCopy';
import useUpdateBoostInvite from '@/hooks/boosts/monetize/useUpdateBoostInvite';
import BoostInviteStatus from '@/interfaces/boosts/boost_invite_status';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  boostInviteId: string;
  directLinkUrl: string;
}

const AcceptBoostInviteModal = ({ isOpen, onClose, boostInviteId, directLinkUrl }: Props) => {
  const { mutateAsync: updateBoostInvite, isLoading: isUpdatingBoostInvite } = useUpdateBoostInvite({});

  const handleCancel = () => onClose();

  const handleConfirm = async () => {
    try {
      await updateBoostInvite({ id: boostInviteId, transition_to: BoostInviteStatus.ACCEPTED });
      onClose();
    } catch (error: any) {
      // do something
    }
  };

  return (
    <ActionModal
      headerText="Accept Boost Invite"
      actionText="I Understand & Accept Invite"
      isOpen={isOpen}
      onClose={handleCancel}
      resourceId="confirm-automation"
      onProceed={handleConfirm}
      isWorking={isUpdatingBoostInvite}
    >
      <Typography>
        By accepting this Boost Invite, you understand that the Boost will become active immediately and no further
        action is needed.{' '}
      </Typography>
      <UrlCopy
        labelText="Direct Link"
        helperText="Paste this link in an email or social media post to start promoting this Boost."
        url={directLinkUrl}
      />
    </ActionModal>
  );
};

export default AcceptBoostInviteModal;
