import toast from 'react-hot-toast';

import { useWebsiteContext } from '@/context/website-context';
import { useUpdateSiteVersion } from '@/hooks/useSiteVersion';

import SettingsInput from '../_components/SettingsInput';
import SettingsItem from '../_components/SettingsItem';
import SettingsSection from '../_components/SettingsSection';

const analytics = [
  {
    name: 'meta_pixel_id',
    title: 'Meta Pixel ID',
    description: 'Specify a Meta pixel to track conversion from a Meta ad campaign.',
    placeholder: '1234567890',
    divider: false,
  },
  {
    name: 'x_pixel_id',
    title: 'X (Twitter) Pixel ID',
    description: 'Specify a Twitter pixel to track conversion from a Twitter ad campaign.',
    placeholder: 'abcdef',
    divider: false,
  },
  {
    name: 'tiktok_pixel_id',
    title: 'TikTok Pixel ID',
    description: 'Specify a TikTok pixel to track conversion from a TikTok ad campaign.',
    placeholder: 'ABCDEFG123456789',
    divider: true,
  },
  {
    name: 'ga4_pixel_id',
    title: 'Google Analytics (GA4) Pixel ID',
    description: 'Specify a Google Analytics 4 pixel to utilize their suite of tracking tools.',
    placeholder: 'G-123456789',
    divider: false,
  },
  {
    name: 'gtm_pixel_id',
    title: 'Google Tag Manager Pixel ID',
    description: 'Specify a Google Tag Manager account to manage all of your pixels in a single location.',
    placeholder: 'GTM-123456789',
    divider: false,
  },
  {
    name: 'gsc_verification_code',
    title: 'Google Search Console Verification Code',
    description: 'Specify a Google Search Console verification content token.',
    placeholder: 'abcdef',
    divider: false,
  },
];

const WebsiteSettingsPixels = () => {
  const { site } = useWebsiteContext();
  const createSiteVersion = useUpdateSiteVersion({
    id: site?.draft_site_version?.id || '',
    publishOnUpdate: true,
    onSuccess: () => {
      toast.success('Saved!');
    },
  });

  return (
    <div>
      {site &&
        analytics.map((item) => {
          const handleSave = (value: string) => {
            createSiteVersion.mutate({
              settings: {
                [item.name]: value,
              },
            });
          };

          const settings: any = site?.draft_site_version?.settings;
          const initialValue = settings?.[item.name] || ('' as string);

          return (
            <SettingsSection key={item.name} hasDivider={item.divider}>
              <SettingsItem title={item.title} description={item.description}>
                <SettingsInput
                  className="max-w-xs"
                  name={item.name}
                  placeholder={item.placeholder}
                  value={initialValue || ''}
                  onSave={(value: string) => {
                    if (value === initialValue) return;
                    handleSave(value);
                  }}
                />
              </SettingsItem>
            </SettingsSection>
          );
        })}
    </div>
  );
};

export default WebsiteSettingsPixels;
