import { useCallback, useMemo, useRef, useState } from 'react';
import debounce from 'lodash.debounce';

import { Post } from '@/interfaces/post';
import api from '@/services/swarm';

import Audience from './Audience';
import { EditorContext, EditorContextContent } from './EditorContext';

interface Props {
  post: Post;
}

const AudienceView = ({ post }: Props) => {
  const [formData, setFormData] = useState<Post>(post);

  const abortControllerRef = useRef<AbortController | null>(null);

  const save = useMemo(
    () =>
      debounce(async (data: Post, callback?: () => void) => {
        abortControllerRef.current = new AbortController();
        setFormData(data);

        return api
          .patch(
            `/posts/${data.id}`,
            { post: data },
            {
              signal: abortControllerRef.current.signal,
            }
          )
          .then((resp) => {
            if (resp.status >= 200 && resp.status < 300) {
              if (typeof callback === 'function') {
                callback();
              }
            }

            if (resp.data.errors) {
              console.log(resp.data.errors);
            }
          })
          .catch((err) => {
            if (err.response?.data) {
              console.log(err.response.data);
            }
          })
          .finally(() => {
            abortControllerRef.current = null;
          });
      }, 450),
    []
  );

  const cancelSave = useCallback(() => {
    abortControllerRef.current?.abort();
  }, []);

  const onChange = useMemo(
    () => (data: any, callback?: () => void) => {
      cancelSave();

      setFormData((prev) => {
        const newData = {
          ...prev,
          ...data,
        };

        save(newData, callback);

        return newData;
      });
    },
    [cancelSave, save]
  );

  const providerValue = useMemo<EditorContextContent>(() => {
    return {
      editor: null,
      setEditor: () => {},
      editorIsLoading: false,
      setEditorIsLoading: () => {},
      isSaving: false,
      setIsSaving: () => {},
      unsavedChanges: false,
      setUnsavedChanges: () => {},
      wordCount: 0,
      setWordCount: () => {},
      provider: undefined,
      setProvider: () => {},
      formData,
      users: [],
      setUsers: () => {},
      showSidebar: true,
      setShowSidebar: () => {},
      showSearchAndReplaceMenu: false,
      setShowSearchAndReplaceMenu: () => {},
      collaborationEnabled: false,
      showThreadsSidebar: false,
      setShowThreadsSidebar: () => {},
      loadingNodes: {},
      setLoadingNodes: () => {},
      activeThreadId: null,
      setActiveThreadId: () => {},
      selectThread: () => {},
      unselectThread: () => {},
      threads: null,
      createThread: () => {},
      highlightThread: () => {},
      removeHighlightThread: () => {},
      onChange,
    };
  }, [formData, onChange]);
  return (
    <EditorContext.Provider value={providerValue}>
      <Audience />
    </EditorContext.Provider>
  );
};

export default AudienceView;
