import cx from 'classnames';

import { CardDensity, CardProps } from './Card.types';

const BORDER_CLASSNAMES = {
  primary: 'border-surface-200',
  secondary: 'border-surface-200',
  success: 'border-feedback-success-200',
};

const Card = ({
  variant = 'primary',
  density = CardDensity.default,
  children,
  className,
  id,
  backgroundColor = 'bg-white',
}: CardProps) => {
  const densityClass = {
    default: 'p-6',
    compact: 'p-4',
    wide: 'p-10',
    none: '',
  }[density];

  return (
    <div
      id={id}
      className={cx('border rounded-md', BORDER_CLASSNAMES[variant], backgroundColor, densityClass, className)}
    >
      {children}
    </div>
  );
};

export default Card;
