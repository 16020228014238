import { CalendarIcon, RectangleStackIcon } from '@heroicons/react/24/outline';
import moment from 'moment-mini';

import { Typography } from '@/components/Typography';
import { AdNetworkOpportunity, AdNetworkOpportunityAdvertisement } from '@/interfaces/ad_network/publisher/opportunity';
import { Badge, BadgeSize, BadgeType } from '@/ui/Badge';
import { Button } from '@/ui/Button';
import { Card } from '@/ui/Card';

import useAddOpportunityToPost from '../../_hooks/useAddOpportunityToPost';
import ScaledPreviews from '../ScaledPreviews';

interface Props {
  opportunity: AdNetworkOpportunity;
  advertisements: AdNetworkOpportunityAdvertisement[];
  setEditMode: (editMode: boolean) => void;
}

const OpportunityFormCompleted = ({ opportunity, advertisements, setEditMode }: Props) => {
  const advertisement = advertisements.find((ad) => ad.id === opportunity.advertisement_id);
  const handleAddToPost = useAddOpportunityToPost({ opportunity });

  return (
    <>
      <Card density="none" className="overflow-clip">
        <div className="flex flex-col">
          <div className="p-6 space-y-6 border-b border-surface-200">
            <div className="space-y-2">
              <div className="flex space-x-3 items-center">
                <div className="bg-surface-100 p-1 rounded">
                  <CalendarIcon className="w-4 h-4 text-gray-900" />
                </div>
                <Typography as="p" token="font-medium/text/base" colorWeight="900">
                  <Typography as="span" token="font-medium/text/base" colorWeight="500">
                    Send on
                  </Typography>{' '}
                  {moment(opportunity.selected_date || new Date()).format('MMM Do')}
                </Typography>
              </div>
              <div>
                <Typography as="p" token="font-normal/text/sm" colorWeight="500">
                  Any unique clicks that occur within{' '}
                  <Badge text="72 hours" size={BadgeSize.SM} type={BadgeType.DEFAULT_FILLED} /> of the selected date
                  will be counted.
                </Typography>
              </div>
            </div>
          </div>
          <div className="p-6 space-y-6 max-h-[calc(50vh)] overflow-auto">
            <div className="space-y-2">
              <div className="flex space-x-3 items-center">
                <div className="bg-surface-100 p-1 rounded">
                  <RectangleStackIcon className="w-4 h-4 text-gray-900" />
                </div>
                <Typography as="p" token="font-medium/text/base" colorWeight="900">
                  <Typography as="span" token="font-medium/text/base" colorWeight="500">
                    Creative version
                  </Typography>{' '}
                  {advertisement?.name}
                </Typography>
              </div>
              <div className="max-w-sm mx-auto py-6">
                <ScaledPreviews advertisements={advertisement ? [advertisement] : []} />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-surface-50 p-3 flex justify-end space-x-3 sticky bottom-0">
          <Button type="button" variant="primary-inverse" onClick={() => setEditMode(true)}>
            Edit
          </Button>
          <Button type="button" variant="primary" onClick={() => handleAddToPost()}>
            Add campaign to new post
          </Button>
        </div>
      </Card>
      <div className="text-center py-6">
        <Typography as="p" token="font-normal/text/xs" colorWeight="500">
          You can also type <Badge text="/ads" size={BadgeSize.SM} /> in any post to select this advertisement.{' '}
          <a
            href="https://support.beehiiv.com/hc/en-us/articles/17507491038231-Ad-Network-FAQ"
            target="_blank"
            rel="noreferrer noopener"
            className="text-action-secondary-600 underline"
          >
            Learn More
          </a>
        </Typography>
      </div>
    </>
  );
};

export default OpportunityFormCompleted;
